import axios from "axios";
import Cookies from "universal-cookie";
import instance from "../../axios/axiosInstance";

const SET_WUSERS_DATA = 'SET_WUSERS_DATA';
const SET_WUSERS_LIST = 'SET_WUSERS_LIST';
const SET_CURRENT_WUSER_ID = 'SET_CURRENT_WUSER_ID';
const UPDATE_USER_DATA_RELATION = 'UPDATE_USER_DATA_RELATION';
const SET_USER_ID = 'SET_USER_ID';
const SET_TEAM_TAB_INDEX = 'SET_TEAM_TAB_INDEX';

let initialState = {
    data: [],
    usersDat: [],
    currentUserId: '',
    teamTabIndex: 0,
}

const usersReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_WUSERS_DATA: {
            return {
                ...state, data: action.data.data
            }
        }

        case SET_CURRENT_WUSER_ID: {
            return {
                ...state, currentUserId: action.currentId
            }
        }

        case SET_WUSERS_LIST: {
            return {
                ...state, usersDat: action.data.data
                // .map(index => { return index.user })
            }
        }
        case UPDATE_USER_DATA_RELATION: {
            return {
              ...state,
              data: state.usersDat.map((item, index) => {
                if (item.user.id !== action.data.user.id) {
                  return item
                }
                return {
                  ...item,
                  relation: action.userRelationList
                    }
                  })
                }
            };
        
        case SET_USER_ID: {
            return {
                ...state, userId: action.data
            }
        };
        case SET_TEAM_TAB_INDEX: {
            return {
                ...state, teamTabIndex: action.index
            }
        }
        default: {
            return state

        }
    }
}
export default usersReducer;

export const setUsersData = (data) => ({ type: SET_WUSERS_DATA, data });
export const setUsersList = (data) => ({ type: SET_WUSERS_LIST, data });
export const setCurrentUserId = (data) => ({ type: SET_CURRENT_WUSER_ID, data });
export const updateUserRelationList = (userRelationList, data) => ({ type: UPDATE_USER_DATA_RELATION, userRelationList, data});
export const setUserId = (data) => ({ type: SET_USER_ID, data });

export const setTeamTabIndex = (index) => ({ type: SET_TEAM_TAB_INDEX, index });

export const getUsersListByMaster = (name, specialization, country, city, sortCriteria,sortDirection ) => (dispatch, getState) => {
  const currentRelationId = getState().curRelationId.currentId;

     //список моих работников
    instance.get(`relation/${currentRelationId}/listByMaster`, {
      params: {
        name: name,
        specialization: specialization,
        country: country,
        city: city,
        sortCriteria: sortCriteria,
        sortDirection: sortDirection,
      }
    }
    )
        .then(result => {
            console.log(result);
            dispatch(setUsersData({ data: result.data }))
            dispatch(setUsersList({ data: result.data }))
        })
        .catch(err => alert(err))
}

export const updateRelation = (currentRelationId, data, mainUserStatus) => (dispatch, getState) => {

    instance.put(`relation/${currentRelationId}`,
      {
        id: `${data.relation.id}`,
        mainUserId: `${data.relation.mainUser.id}`,
        mainUserRelationStatus: mainUserStatus,
        masterUserId: `${data.relation.mainUser.id}`,
        relationType: "PERMANENT",
        roleId: `${data.relation.roleId}`,
        userId: `${data.user.id}`,
        userRelationStatus: `${data.relation.userRelationStatus}`
      })
      .then(res => {
    dispatch(updateUserRelationList(res.data, data))
    dispatch(getUsersListByMaster())
    })
    .catch(err => alert(err))
  }






  // уволить
export const dismissUserRelation = ( data ) => (dispatch, getState) => {

    const currentRelationId = getState().curRelationId.currentId;

    instance.put(`relation/${currentRelationId}`,
      {...data.relation,
        mainUserRelationStatus: 'FIRED',
      })
      .then(res => {
        !res.data.code ?
          dispatch(getUsersListByMaster()) :
          console.log(res.data.message)
      })
      // .catch(err => console.log(err))
  }
  // сменить role
export const setRoleToUserRelation = ( data, roleId) => (dispatch, getState) => {

    const currentRelationId = getState().curRelationId.currentId;

    instance.put(`relation/${currentRelationId}`,
      {...data.relation,
        roleId: roleId,
      })
      .then(res => {
        !res.data.code ?
          dispatch(updateUserRelationList(res.data, data)) :
          console.log(res.data.message)
      })
      // .catch(err => console.log(err))
  }

export const sendSupportMessage = (email, phone, message) => (dispatch, getState) => {
  instance.post('/user/support', {
    "email": email,
    "phone": phone,
    "message": message,
  })
    .then(res => console.log(res));
}