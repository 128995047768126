import axios from "axios";
import instance from "../../axios/axiosInstance";

const SET_WORKS = 'SET_WORKS';
const SET_CURRENT_WORK_ID = 'SET_CURRENT_WORK_ID';
const RESET_VALUES_FORM = 'RESET_VALUES_FORM';
const TOGLE_IS_FETCHING = 'TOGLE_IS_FETCHING';
const SET_CUR_WORKS = 'SET_CUR_WORKS';
const CLEAR_CUR_WORKS = 'CLEAR_CUR_WORKS';
const SET_WORKS_TAB_INDEX = 'SET_WORKS_TAB_INDEX';
const SET_DRAGGABLE_WORKS = 'SET_DRAGGABLE_WORKS';
const DEL_CUR_WORKS = 'DEL_CUR_WORKS';


let initialState = {
    data: [],
    currentId: '',
    isFetching: false,
    selectedWorks: [],
    tabIndex: 0,
};
const worksReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_WORKS: {
            console.log(action.data);
            return {
                ...state, data: action.data
            }
        }
        case SET_CURRENT_WORK_ID: {
            return {
                ...state, currentId: action.currentId
            }
        }
        case RESET_VALUES_FORM: {
            return {
                ...state
            }
        }
        case TOGLE_IS_FETCHING: {
            return {
                ...state, isFetching: action.data
            }
        }

        case SET_CUR_WORKS: {
            return {
                ...state, 
                selectedWorks: [...state.selectedWorks, action.data]
            }
        }

        case SET_DRAGGABLE_WORKS: {
            return {
                ...state,
                selectedWorks: action.data,
            }
        }

        case CLEAR_CUR_WORKS: {
            return {
                ...state, selectedWorks: []
            }
        }

        case SET_WORKS_TAB_INDEX: {
            return {
                ...state, tabIndex: action.index
            }
        }

        case DEL_CUR_WORKS: {
            let selectedWorksCopy = [...state.selectedWorks];
            selectedWorksCopy.splice(action.index, 1);
            return {
                ...state,
                selectedWorks: selectedWorksCopy,
            }
        }

        default: {
            return state
        }
    }
}
export default worksReducer;

export const setCurWorks = (data) => ({ type: SET_CUR_WORKS, data });
export const clearCurWorks = (data) => ({ type: CLEAR_CUR_WORKS, data });
export const setWorks = (data) => ({ type: SET_WORKS, data });
export const setCurrenWorktId = (currentId) => ({ type: SET_CURRENT_WORK_ID, currentId });
export const resetValuesForm = () => ({ type: RESET_VALUES_FORM, });
export const togleIsFetching = (data) => ({ type: TOGLE_IS_FETCHING, data });
export const setWorksTabIndex = (index) => ({ type: SET_WORKS_TAB_INDEX, index });
export const setDraggableWorks = (data) => ({ type: SET_DRAGGABLE_WORKS, data });
export const delCurWorks = (index) => ({ type: DEL_CUR_WORKS, index });

export const getWorksByRelationIdWithPagination = (currentRelationId) => (dispatch, getState) => {
    dispatch(togleIsFetching(true))
    instance.get(`relation/${currentRelationId}/work/listByRelationId?&page=0&perPage=999`)
        .then((res) => {
            dispatch(togleIsFetching(false));
            !res.data.code ?
                dispatch(setWorks({ data: res.data })) :
                alert(`Error Code:${res.data.code} - ${res.data.message}`);
        }).catch((err)=>{dispatch(togleIsFetching(false)); console.log(err)})
}

export const createWork = (currentRelationId, data) => (dispatch, getState) => {
    const regulations = getState().acts.regulationsByWorkId

    dispatch(togleIsFetching(true))
    instance.post(`relation/${currentRelationId}/work`,
        {
            currencyCode: data.currSelect.value ? data.currSelect.value : data.currSelect,
            measurementUnit: data.typeSelect.value,
            unitPrice: data.unitPrice,
            workName: data.name,
            relationId: currentRelationId,
            description: data.description,
        })
        .then((res) => {
            regulations && regulations.length > 0 &&
                regulations.map((regulation, i, arr) => {
                    dispatch(addRegulation(currentRelationId, res.data.id, regulation.id))
                })
            dispatch(togleIsFetching(false));
            !res.data.code ?
                console.log(res.data) :
                alert(`Error Code:${res.data.code} - ${res.data.message}`);
        }).then(() => {
            dispatch(getWorksByRelationIdWithPagination(currentRelationId))
        }).catch((err)=>{dispatch(togleIsFetching(false)); console.log(err)})
}

export const updateWork = (id, data) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId?.currentId;
    const regulations = getState().acts.regulationsByWorkId
    console.log(data)
    dispatch(togleIsFetching(true))
    instance.put(`relation/${currentRelationId}/work`,
        {
            id: id,
            currencyCode: data.currSelect.value ? data.currSelect.value : data.currSelect,
            measurementUnit: data.typeSelect.value,
            unitPrice: data.unitPrice,
            workName: data.name,
            relationId: currentRelationId,
            description: data.description,
        })
        .then((res) => {
            regulations && regulations.length > 0 &&
                regulations.map((regulation, i, arr) => {
                    dispatch(addRegulation(currentRelationId, res.data.id, regulation.id))
                })
            dispatch(togleIsFetching(false));
            !res.data.code ?
                console.log(res.data) :
                alert(`Error Code:${res.data.code} - ${res.data.message}`);
        })
        .then(() => { dispatch(getWorksByRelationIdWithPagination(currentRelationId)) })
        .catch((err) => { dispatch(togleIsFetching(false)); console.log(err) })
}

export const deleteWork = (currentRelationId, workId) => (dispatch, getState) => {
    dispatch(togleIsFetching(true))
    instance.delete(`relation/${currentRelationId}/work/${workId}`)
        .then((res) => {
            dispatch(togleIsFetching(false));
            !res.data.code ?
                console.log(res.data):
                alert(`Error Code:${res.data.code} - ${res.data.message}`);
        }).then(() => {
            dispatch(getWorksByRelationIdWithPagination(currentRelationId))
        }).catch((err)=>{dispatch(togleIsFetching(false)); console.log(err)})
}

export const removeRegulation = (currentRelationId, workId, regulationId) => (dispatch, getState) => {
    // dispatch(togleIsFetching(true))
    instance.delete(`relation/${currentRelationId}/work/${workId}/removeRegulation/${regulationId}`)
        .then((res) => {
            // dispatch(togleIsFetching(false));
            !res.data.code ?
                console.log(res.data):
                alert(`Error Code:${res.data.code} - ${res.data.message}`);
        }).then(() => {
            dispatch(getWorksByRelationIdWithPagination(currentRelationId))
        }).catch((err)=>{dispatch(togleIsFetching(false)); console.log(err)})
}

export const addRegulation = (currentRelationId, workId, regulationId) => (dispatch, getState) => {

    instance.put(`relation/${currentRelationId}/work/${workId}/addRegulation/${regulationId}`)
        .then((res) => {
            !res.data.code ?
                console.log(res.data) :
                alert(`Error Code:${res.data.code} - ${res.data.message}`)
        })
        .catch((err) => { dispatch(togleIsFetching(false)); console.log(err) })
}

export const updateWorksPosition = () => (dispatch, getState) => {
    const works = getState().works?.data?.data;
    const updatedWorksList = {};
    works && works.forEach((item, index) => {
        updatedWorksList[index] = item.id;
      });
      console.log('reducer', updatedWorksList);
    const currentRelationId = getState().curRelationId?.currentId;
    if (currentRelationId) {
        instance.put(`/relation/${currentRelationId}/work/positions`,
        updatedWorksList 
    )
        .then(res => console.log(res))
    }

}