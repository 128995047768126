import React, { Component, createContext } from 'react';
import { generateKey } from '../utils.js';

export const AlertContext = createContext();

export class AlertProvider extends Component {
    state = {
        alerts: [],
        boundaryErrors: false,
    }

    addNewAlert = (statusType, title, time=3000, undoCallback) => {
        this.setState((prevState, props) => {

            let allAlerts = [...prevState.alerts];

            let alert = {
                key: generateKey(),
                title,
                type: statusType,
            };

            allAlerts.push(alert);

            if (!undoCallback) {
                window.setTimeout(() => this.dismissAlert(alert.key), time)
            }
                    
            return { alerts: allAlerts };
        });
    }

    dismissAlert = (key) => {
        let alertToDismiss;
        if (this.state.alerts.find((alert, i) => { 
            alertToDismiss = i;
            return alert.key === key; 
        })) {
            let tmpAlerts = [...this.state.alerts];
            tmpAlerts.splice(alertToDismiss, 1)

            this.setState({ alerts: tmpAlerts });
        }
    }

    boundaryErrorsHandler = (isBoundaryError) => {
        this.setState({ boundaryErrors: isBoundaryError })
    }

    render () {
        return (
            <AlertContext.Provider 
                value={{
                    alerts: this.state.alerts,
                    boundaryErrors: this.state.boundaryErrors,
                    addNewAlert: this.addNewAlert,
                    dismissAlert: this.dismissAlert,
                    boundaryErrorsHandler: this.boundaryErrorsHandler,
                }}
            >
              {this.props.children}
            </AlertContext.Provider>
          );
    }
}

export const AlertConsumer = AlertContext.Consumer;

export function wrapConsumer(Component) {
    return (props) => {
        return (
            <AlertConsumer>
                { context => <Component {...props} {...context} /> }
            </AlertConsumer>
        );
    };
} 