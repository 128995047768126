import axios from "axios";
import Cookies from "universal-cookie";
import instance from "../../axios/axiosInstance";
import { setCurrentMainUserId } from "./relationReducer";
import { getProfileData } from "./profileReducer";
import { receiveMessageForObjectInvitation } from './objectsReducer';
import { setErrors } from './errorsReducer';
import { setInviteLinkInfo } from './preloaderReducer.js';

const SET_USER = 'SET_USER';
const SET_CURRENT_ID = 'SET_CURRENT_ID';
const SET_IS_AUTH = 'SET_IS_AUTH';
const SET_RESET_STEP = 'SET_RESET_STEP';
const SET_CHANGE_STEP = 'SET_CHANGE_STEP';
const SET_CHANGE_PASS_ERROR = 'SET_CHANGE_PASS_ERROR';
const SET_CHANGED_PASS = 'SET_CHANGED_PASS';
const SET_RESET_PASS_ERROR = 'SET_RESET_PASS_ERROR';
const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
const UPDATE_USER = 'UPDATE_USER';
const UPDATE_COMPANY = 'UPDATE_COMPANY';
const SET_CUR_SECTION = 'SET_CUR_SECTION';

let initialState = {
    data: {},
    currentId: '',
    authState: false,
    resetStep: 1,
    changeStep: 1,
    changePassError: null,
    resetPassError: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_USER: {
            return {
                ...state, data: action.data
            }
        }
        case UPDATE_USER: {
            return {
                ...state, 
                data: {
                    ...state.data,
                     data:{
                         ...state.data.data, 
                         defaultRelation: {
                             ...state.data.data.defaultRelation, 
                             mainUser: action.user
                            }
                        }
                    }
            }
        }
        case UPDATE_COMPANY: {
            return {
                ...state, 
                data: {
                    ...state.data,
                     data:{
                         ...state.data.data, 
                         relationSet: state.data.data.relationSet.map(rel=>
                             rel.id === action.curRelId ?
                             {...rel, mainUser: action.user} :
                             rel
                             
                         )
                          
                        }
                    }
            }
        }
        case SET_CURRENT_ID: {
            return {
                ...state, currentId: action.currentId
            }
        }
        case SET_IS_AUTH: {
            return {
                ...state, authState: action.authState
            }
        }
        case SET_RESET_STEP: {
            return {
                ...state, resetStep: action.step
            }
        }
        case SET_CHANGE_STEP: {
            return {
                ...state, changeStep: action.step
            }
        }
        case SET_CHANGE_PASS_ERROR: {
            return {
                ...state, changePassError: action.error
            }
        }
        case SET_RESET_PASS_ERROR: {
            return {
                ...state, resetPassError: action.error
            }
        }
        case SET_CHANGED_PASS: {
            return {
                ...state, changedPassword: action.password
            }
        }
        case SET_LOGIN_STATUS: {
            return {
                ...state, loginStatus: action.status
            }
        }
        case SET_CUR_SECTION: {
            return {
                ...state, curSection: action.name
            }
        }
        
        default: {
            return state
        }
    }
}
export default userReducer;

export const setUser = (data) => ({ type: SET_USER, data });
export const setCurrentId = (currentId) => ({ type: SET_CURRENT_ID, currentId });
export const isAuth = (authState) => ({ type: SET_IS_AUTH, authState });

export const setResetStep = (step) => ({ type: SET_RESET_STEP, step });
export const setChangeStep = (step) => ({ type: SET_CHANGE_STEP, step });
export const setChangePassError = (error) => ({ type: SET_CHANGE_PASS_ERROR, error });
export const setResetPassError = (error) => ({ type: SET_RESET_PASS_ERROR, error });
export const setChangedPassword = (password) => ({ type: SET_CHANGED_PASS, password });
export const setLoginStatus = (status) => ({ type: SET_LOGIN_STATUS, status });
export const setCurSection = (name) => ({ type: SET_CUR_SECTION, name });

export const updateDefaultRelationUser = (user) => ({ type: UPDATE_USER, user });
export const updateCompanyUser = (user, curRelId) => ({ type: UPDATE_COMPANY, user, curRelId });


export const userLogin = (encode, objUrl = {}) => (dispatch, getState) => {
    const cookies = new Cookies();
    const { objectId, newUser, invitorEmail } = objUrl;
    if (objectId && newUser && invitorEmail) {
        dispatch(setInviteLinkInfo(objUrl));
    }

    const objIdToSend = objectId || getState().preloader.inviteLinkInfo?.objectId;
    const newUserToSend = newUser || getState().preloader.inviteLinkInfo?.newUser;
    const invitorEmailToSend = invitorEmail || getState().preloader.inviteLinkInfo?.invitorEmail;

    const cookiesRelation = cookies.get('currentRelation');
    const cookiesMainUserRelation = cookies.get('currentMainUserRelation');
    const encodedData = encode || cookies.get('UserId');
    const error = (logStatus) => {
        switch (logStatus) {
            case "USER_NOT_FOUND":
                return (dispatch(setLoginStatus('Неверный e-mail')));
            case "WRONG_PASSWORD":
                return (dispatch(setLoginStatus('Неверный пароль')));
        }
    };

    instance.get(`user/login/${encodedData}?objectId=${objIdToSend}&newUser=${newUserToSend}&invitorEmail=${invitorEmailToSend}`)
        .then(async (res) => {
            if (res.data?.loginStatus === 'OK') {
                const { defaultRelation: { id }, loginExtraParam: { newUser, objectId, invitorEmail } } = res.data;
                if (newUser && newUser !== 'undefined') {
                    await dispatch(receiveMessageForObjectInvitation(id, objectId, invitorEmail, encodedData))
                        .then(res => console.log(res));
                }
            }
            res.data?.loginStatus !== 'OK' ? error(res.data.loginStatus) :
            dispatch(setUser(res));
            if (cookiesRelation && cookiesRelation !== 'undefined') {
                dispatch(setCurrentId(cookiesRelation));
                dispatch(setCurrentMainUserId(cookiesMainUserRelation));
            } else {
                dispatch(setCurrentId(res.data.defaultRelation.id));
                dispatch(setCurrentMainUserId(res.data.defaultRelation.mainUser.id));
                cookies.set('currentRelation', res.data.defaultRelation.id, { path: '/' });
                cookies.set('currentMainUserRelation', res.data.defaultRelation.mainUser.id, { path: '/' });
                cookies.set('isUser', 'user',  { path: '/' });
            }
            cookies.set('UserId', encodedData, { path: '/' });
            dispatch(isAuth(true));
        })
        .catch(res =>  {console.log(res)
    })
};


export const resetPassword = (loginEmail, changePasswordLink) => (dispatch, getState) => {
    const success = () => {
        dispatch(setResetPassError(null));
        dispatch(setResetStep(2))
    }

    instance.post(`user/password/reset/email/${loginEmail}?changePasswordLink=${changePasswordLink}`)
        .then(res => {
            res.data.code ?
                dispatch(setResetPassError({ code: res.data.code, message: res.data.message })) :
                success()
        })
        .catch(err => console.log(err))
};

export const changePassword = (data, token) => (dispatch, getState) => {
    const success = () => {
        dispatch(setChangePassError(null));
        dispatch(setChangedPassword(data.newPassword));
        dispatch(setChangeStep(2));
    }

    instance.post(`user/password/change/token/${token}`, data)
        .then(res => {
            res.data.code ?
                dispatch(setChangePassError({ code: res.data.code, message: res.data.message })) :
                success()
        })
        .catch(err => console.log(err))
};

export const uploadAvatar = (userId, data) => (dispatch, getState) => {
    
    const currentRelationId = getState().curRelationId.currentId;
    const formData = new FormData()
    formData.append("file", data)
    if(data?.size < 10485760){
    instance.post(`user/${currentRelationId}/avatar/${userId}`,
        formData,
        { headers: { 'content-type': 'multipart/form-data' } }
    )
        .then(res => {
            res.data.code ?
                console.log(res.data.message) :
                console.log(res.data)
                dispatch(getProfileData(userId))
                // dispatch(updateDefaultRelationUser(res.data))
                // dispatch(updateCompanyUser(res.data, currentRelationId))

        })
        .catch(err => console.log(err));
    } else {alert('Размер фото не должен превышать 10 Mb.')} 

};

export const deleteAvatar = ( userId ) => (dispatch, getState) => {

    instance.delete(`user/${userId}/avatar`)
        .then(res => {
            res.data.code ?
                console.log(res.data.message) :
                dispatch(getProfileData(userId))
        })
        .catch(err => console.log(err))
};

export const changePassWithoutToken = (newPass, repeatPass) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    return instance.post(`user/${currentRelationId}/password/change`,
    {
        "newPassword": newPass,
        "repeatNewPassword": repeatPass
    })
}


