import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Rater from 'react-rater';
import clsx from 'clsx';
import { useEffect } from 'react';

import { ReactComponent as XIcon } from '../images/XIcon.svg';
import { ReactComponent as PersonInTheRoundIcon } from '../images/PersonInTheRoundIcon.svg';
import { ReactComponent as EmailFillIcon } from '../images/EmailFillIcon.svg';
import { ReactComponent as PhoneIcon } from '../images/PhoneIcon.svg';
import { ReactComponent as ChatIcon } from '../images/ChatIcon.svg';
import { ReactComponent as ReviewIcon } from '../images/ReviewIcon.svg';
import { ReactComponent as LocationIcon } from '../images/LocationIcon.svg';
import AvatarPhoto from '../components/avatar/AvatarPhoto';
import instance from '../axios/axiosInstance';


const styles = theme => createStyles({





  // feedback styles
  feedbacksTitle: {
    padding: '24px 24px 2px 24px',
    color: '#333333'
  },
  feedbacksList: {
    padding: '0 24px 49px 24px',
    maxHeight: '406px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      // marginTop: '6px',
      // marginBottom: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E3E7ED',
      borderRadius: '10px',
    },
  },
  feedbackCard: {
    paddingTop: '24px',
    paddingBottom: '32px',
    borderBottom: '1px solid #EBEFF2',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
    },
  },

  reviewerAvatar: {
    height: '40px',
    width: '40px',
    marginRight: '16px',
  },
  mobileReviewerAvatar: {
    [theme.breakpoints.down('sm')]: {
      width: '20%',
      maxWidth: '20%',
      flexWrap: 'nowrap',
    },
  },
  reviewerName: {
    lineHeight: '19px',
    color: '#373A44',
  },
  reviewerCompany: {
    fontWeight: '500',
    lineHeight: '19px',
    color: '#8F919B',
  },
  rateWrap: {
    padding: '0 0 4px 0'
  },
  rateDate: {
    width: '83px',
    lineHeight: '19px',
    color: '#8F919B',
  },
  rating: {
    display: 'flex',
    marginLeft: 'auto',
    '& .react-rater-star': { // color of star not rated 
      color: '#8FABBD',
    },
    '& .is-active': {  // color of star rated
      color: '#FBBF00',
    },
    '& .will-be-active': {  // color of star on hover 
      color: '#FBBF00',
    },
  },
  ratingDescription: {
    color: '#373A44',
    lineHeight: '19px',
  },
})



export default withStyles(styles)(function FeedBackCard(props) {
  const dispatch = useDispatch();
  const { classes, feedback } = props;

  // useEffect(()=>{
  //   dispatch(getProfileData(userId))
  //   getRatingsByUser( '',userId)
  // }, [userId])

  const profile = useSelector(state => state.profile?.data);

// получение user для отрисовки avatar
  const [creatorProfile, setCreatorProfile] = useState();
  useEffect(() => {
      instance.get(`user/${feedback.estimatorId}`)
          .then(res => res.data.code ?
              console.log(res.data.error) :
              setCreatorProfile(res.data))
          .catch(err => console.log(err))
  }, [feedback.estimatorId])

  const createDate = (value) => {  // date formated to 01.02.19
    let date = new Date(value)
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yy = date.getFullYear() //.toString().substring(2) - cut two first numbers
    return (
      date = dd + '.' + mm + '.' + yy
    )
  }

  return (

    <Grid item xs={12} container
      className={classes.feedbackCard}
      key={feedback.id}>
      <Grid item xs={1}
        container
        direction="row"
        justify="flex-start"
        className={classes.mobileReviewerAvatar}
      >
        {/* <PersonInTheRoundIcon className={classes.reviewerAvatar} /> */}

        < AvatarPhoto
          profile={creatorProfile}
          className={classes.reviewerAvatar}
        />
      </Grid>
      <Grid item xs={11} >
        <Grid className={classes.reviewerName}>
          {feedback.estimatorName}
        </Grid>
        <Grid container className={classes.rateWrap}>

          <span className={classes.rateDate}>
            {createDate(feedback.createdTimestamp)}
          </span>
          <span>
            <Rater
              interactive={false}  //to create a read-only rater
              // onRate={onRate}   //onRate: function({ rating }). Callback to retrieve rating value. Called after rated.
              rating={feedback.score}
              total={5}
              className={classes.rating} />
          </span>
        </Grid>
        <Grid className={classes.ratingDescription}>
          {feedback.description}
        </Grid>
      </Grid>
    </Grid>


  );
}
);