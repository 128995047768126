
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { getAllSpecializations } from '../../redux/reducers/specialisationsReducer';
import ButtonWithoutBorder from '../buttons/ButtonWithoutBorder';

import { ReactComponent as XIcon } from '../../images/XIcon.svg';
import { createMyCompany } from '../../redux/reducers/profileReducer';
import { getAllCountries, getCitiesByCountry } from '../../redux/reducers/localeReducer';

const defaultTheme = createMuiTheme({});

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        [defaultTheme.breakpoints.down('sm')]: {
          margin: '10px',
        },
      } 
    },
    MuiDialogTitle: {
      root: {
        [defaultTheme.breakpoints.down('sm')]: {
          padding: '0px',
        },
      }
    },
    MuiDialogContent: {
      root: {
        [defaultTheme.breakpoints.down('sm')]: {
          paddingTop: '0px',
        },
        '&:first-child': {
          [defaultTheme.breakpoints.down('sm')]: {
            paddingTop: '0px',
          },
        }
      }
    }
  }
});

const styles = theme => createStyles({

  cardWrap: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      // marginTop: '6px',
      // marginBottom: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A9C1D1',
      borderRadius: '10px',
    },
  },
  createMyCompanyWrapper: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
  },
  },
  title: {
    padding: 0,
    borderTop: '10px solid #0D9CFC',
    width: '100%',
  },
  xIcon: {
    padding: ' 21px 29px 0 0 ',
    display: 'flex',
    marginLeft: 'auto',
    '& path': {
      fill: '#A9C1D1',
    },
    '& :hover ': {
      cursor: 'pointer',
    }
  },
  titleText: {
    padding: ' 0 0 0 42px',
    '& h2': {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '24px',
      color: '#0D9CFC',

    },
  },
  content: {
    fontSize: '14px',
    lineHeight: '19px',
    padding: '0 48px 0 42px ',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      // marginTop: '6px',
      // marginBottom: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#A9C1D1',
      borderRadius: '10px',
    },
  },
  buttonWrap: {
    margin: 0,
    padding: '16px 42px 24px ',
    background: '#F5F6F8',
  },
  button: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    borderRadius: '4px',
    color: '#FFFFFF',
    background: '#004EE5',
    textTransform: 'initial',
    margin: 0,
    '&:hover': {
      background: '#0D9CFC',
    },
    '&:disabled': {
      color: '#FFFFFF',
      background: '#B3C7F0',
    },
  },
  disable: {
    pointerEvents: 'none',
    '& path': {
      fill: '#A9C1D1',
    },
  },

  inputDescWrap: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',

  },
  label: {
    color: '#373A44',
  },
  input: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#373A44',
    boxSizing: 'border-box',
    '&:focus': {
      outline: 'none'
    }
  },
  descInput: {
    border: '1px solid #CDD4DC',
    borderRadius: '4px',
    width: '100%',
    height: '42px',
    padding: '12px 16px 11px 16px',
    marginTop: '8px',
  },
  paddingAddress: {
    paddingBottom: '47px',
  },
  reactSelect: {
    width: '100%',
    minHeight: '42px',
    marginTop: '8px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    textTransform: 'capitalize',
    '& .css-g1d714-ValueContainer': {
        padding: '6px 16px ',
    }, 
    '& . css-1wa3eu0-placeholder': {
      textTransform: 'none'
    },
    '& .css-1pahdxg-control': {
      height: '100%',
      width: '100%'
    },
    '& .css-yk16xz-control': {
      height: '100%',
      width: '100%'
    },
    '& .css-1rhbuit-multiValue': {
      height: '25px',
      display: 'flex',
      alignItems: 'center',
      background: '#F4F6F7',
      border: '1px solid #F4F6F7',
      boxSizing: 'border-box',
      borderRadius: '20px',
      marginRight: '8px',
      padding: '3px',
      '& .css-xb97g8': {
        height: '20px',
        borderRadius: '20px',
      }
    },
    '& .css-1okebmr-indicatorSeparator': {
      display: 'none',
    },
  },
  description: {
    '& textarea': {
      fontFamily: 'Lato',
      fontSize: '14px',
      lineHeight: '19px',
      color: '#373A44',
      border: '1px solid #CDD4DC',
      borderRadius: '4px',
      padding: '11px 16px',
      margiinBottom: '69px',
      width: 'inherit',
      height: '55px',
      marginTop: '8px',
      resize: 'none',
      overflow: 'hidden',
      '&:focus': {
        outline: 'none'
      }
    }
  },
  errorMessage: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#FF0809',
  },
  error: {
    color: '#FF0809',
    borderColor: '#FF0809',
    '& .css-yk16xz-control': {
      borderColor: '#FF0809',
    }
  },

  disableInput: {
    display: 'none',
    '& label, & div, ': {
      color: '#8F919B',
    },
    '& input': {
      pointerEvents: 'none',
      '&::placeholder': {
        color: '#8F919B',
      },
    }
  },

})


function CompanyCreatePopup(props) {
  const dispatch = useDispatch();

  const userData = useSelector(state => state.userData?.data?.data?.user);
  const specializations = useSelector(state => state.specializations?.specializations);
  const countries = useSelector(state => state.locale?.countries);
  const cities = useSelector(state => state.locale?.cities);

  const { register, handleSubmit, setValue, watch, errors, reset, control } = useForm({
    defaultValues: {
      email: userData.loginEmail || userData.contactEmail,
      phone: userData.mobilePhone,
      country: {
        label: userData?.country,
        value: userData?.country,
      },
      city: {
        label: userData?.city,
        value: userData?.city,
      },
    }
  });
  const {classes,children} = props;


  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    dispatch(getAllSpecializations())
    dispatch(getAllCountries())
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // list of specializations for reactSelect
  const specs = specializations?.map((spec) => {
    return ({ value: spec.specializationName, label: spec.specializationName })
  });

  // set countries to select format
  const selectCountry = countries?.map((c) => {
    return ({ value: c.countryCode, label: c.countryName })
  });

  // set cities by selected country
  const watchShowCountry = watch("country");
  useEffect(() => { 
    setValue( "city", "")
    dispatch(getCitiesByCountry(watchShowCountry?.label)) 
  },
  [watchShowCountry?.label]);

  // set cities to select format
  const selectCity = cities?.map((c) => {
    return ({ value: c.id, label: c.cityName })
  });

    const onSubmit = data => {
      data.country = data.country.label;
      data.city = data.city.label;
      dispatch(createMyCompany(data));
      handleClose()
    };

  return (
    <>
      <Grid 
        className={classes.createMyCompanyWrapper}
        onClick={handleClickOpen}>
        {children}
      </Grid>
      <MuiThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title" className={classes.title}>
            <XIcon onClick={handleClose} className={classes.xIcon} />
          </DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="stretch"
            className={clsx(classes.cardWrap)}
          >
            <Grid item >
              <DialogTitle id="form-dialog-title" className={classes.titleText}>
                Моя компания
              </DialogTitle>

              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <DialogContent className={classes.content}>

                  <Grid className={classes.inputDescWrap}>
                    <label htmlFor="companyName"
                      className={clsx(classes.label, errors.companyName && classes.error)}
                    >
                      Название компании
                      <span style={{ color: '#0D9CFC' }}> *</span>
                    </label>
                    <input
                      name="companyName"
                      placeholder='Ведите название'
                      autoComplete='off'
                      className={clsx(classes.input, classes.descInput, errors.companyName && classes.error)}
                      ref={register({ required: true })} />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                  >
                    <Grid item xs className={classes.inputDescWrap}>
                      <label htmlFor="email"
                        className={clsx(classes.label, errors.email && classes.error)}
                      >
                        Рабочая почта
                        <span style={{ color: '#0D9CFC' }}> *</span>
                      </label>
                      <input
                        name="email"
                        placeholder='Введите рабочий e-mail'
                        autoComplete='off'
                        className={clsx(classes.input, classes.descInput, errors.email && classes.error)}
                        ref={register({ required: true })} />
                    </Grid>

                    <Grid style={{ width: '3%' }}></Grid>

                    <Grid item xs className={classes.inputDescWrap}>
                      <label htmlFor="phone" className={clsx(classes.label, errors.phone && classes.error)}>
                        Рабочий телефон
                        <span style={{ color: '#0D9CFC' }}> *</span>
                      </label>
                      <input
                        name="phone"
                        placeholder='+375 (XX) XXX XX XX'
                        autoComplete='off'
                        className={clsx(classes.input, classes.descInput, errors.phone && classes.error)}
                        ref={register({ required: true, min: 7, max: 24 })} />
                    </Grid>

                  </Grid>

                  <Grid
                    container
                    direction="row"
                  >
                    <Grid item xs className={clsx(classes.inputDescWrap, classes.inputPaddingBottom)}>
                      <label
                        htmlFor="country"
                        className={clsx(
                          classes.label,
                          errors.country?.type === "required" && classes.error
                        )}>
                        Страна
                      <span style={{ color: '#0D9CFC' }}> *</span>
                      </label>
                      <Controller
                        name="country"
                        as={Select}
                        className={clsx(classes.reactSelect,
                          errors.country?.type === "required" && classes.error)}
                        placeholder='Начните ввод'
                        options={selectCountry}
                        control={control}
                        rules={{ required: true }}
                      />
                      {errors.country?.type === "required" && (
                        <p className={classes.errorMessage}> обязательное поле </p>
                      )}
                    </Grid>
                    <Grid style={{ width: '3%' }}></Grid>

                    <Grid item xs className={clsx(classes.inputDescWrap, classes.inputPaddingBottom)}>
                      <label
                        htmlFor="city"
                        className={clsx(
                          classes.label,
                          errors.city?.type === "required" && classes.error
                        )}>
                        Город
                      <span style={{ color: '#0D9CFC' }}> *</span>
                      </label>
                      <Controller
                        name="city"
                        as={Select}
                        className={clsx(classes.reactSelect,
                          errors.city?.type === "required" && classes.error)}
                        placeholder='Начните ввод'
                        options={selectCity}
                        control={control}
                        rules={{ required: true }}
                      />
                      {errors.country?.type === "required" && (
                        <p className={classes.errorMessage}> обязательное поле </p>
                      )}
                    </Grid>
                  </Grid>

                  <Grid className={clsx(classes.inputDescWrap, classes.paddingAddress)}>
                    <label htmlFor="street" className={classes.label}>
                      Адрес
                    </label>
                    <input
                      name="street"
                      placeholder='Укажите адрес компании'
                      autoComplete='off'
                      className={clsx(classes.input, classes.descInput)}
                      ref={register()} />
                  </Grid>

                  <Grid className={classes.inputDescWrap}>
                    <label htmlFor="unp" className={classes.label}>
                      УНП / ИП</label>
                    <input
                      name="unp"
                      placeholder='Укажите ваши реквизиты'
                      autoComplete='off'
                      className={clsx(classes.input, classes.descInput)}
                      ref={register()} />
                  </Grid>
                  <Grid className={classes.inputDescWrap}>
                    <label htmlFor="specializations" className={classes.label}>
                      Ключевые навыки</label>
                    <Controller
                      name="specializations"
                      as={Select}
                      className={clsx(classes.reactSelect)}
                      placeholder='Выберите из списка'
                      options={specs}
                      control={control}
                      isMulti
                      closeMenuOnSelect={false}
                    />
                  </Grid>
                  <Grid className={clsx(classes.inputDescWrap, classes.description)}>
                    <label htmlFor="description" className={classes.label}>
                      О компании</label>
                    <textarea
                      name="description"
                      cols="40"
                      rows="5"
                      placeholder='Напишите пару слов о вашей компании'
                      defaultValue=''
                      ref={register()} />
                  </Grid>



                </DialogContent>

                <DialogActions className={classes.buttonWrap}>
                  <ButtonWithoutBorder
                    title={'Отменить'}
                    type='reset'
                    style={{ marginRight: '6px', background: '#F5F6F8' }}
                    onClick={() => handleClose()}
                  />

                  <Button
                    className={classes.button}
                    type='submit'
                    disabled={false}
                  >
                    Создать компанию
                  </Button>
                </DialogActions>

              </form>
            </Grid>
          </Grid>
        </Dialog>
      </MuiThemeProvider>
    </>
  )
}
export default withStyles(styles)(CompanyCreatePopup);