import instance from "../../axios/axiosInstance";

const SET_PUBLICATIONS_LIST_DATA = 'SET_PUBLICATIONS_LIST_DATA';
const SET_MY_OFFERS_LIST = 'SET_MY_OFFERS_LIST';
const SET_OBJECT_DATA = 'SET_OBJECT_DATA';
const SET_RELATION_USER_DATA = 'SET_RELATION_USER_DATA';
const TOGLE_IS_FETCHING = 'TOGLE_IS_FETCHING';
const SET_CURRENT_PUBLICATION = 'SET_CURRENT_PUBLICATION';
const SET_UPLOADED_FILES = 'SET_UPLOADED_FILES';
const SET_RESET_UPLOADED_FILES = 'SET_RESET_UPLOADED_FILES';
const SET_CURRENT_ATTACHMENT = 'SET_CURRENT_ATTACHMENT';
const UPDATE_CURRENT_ATTACHMENT = 'UPDATE_CURRENT_ATTACHMENT';
const SET_OFFERS_BY_OBJECT = 'SET_OFFERS_BY_OBJECT';
const UPDATE_OFFERS_STATUS = 'UPDATE_OFFERS_STATUS';
const SET_CURRENT_PUBLICATION_FOR_OFFERS = 'SET_CURRENT_PUBLICATION_FOR_OFFERS';
const SET_UPDATED_PUBLICATIONS = 'SET_UPDATED_PUBLICATIONS';

let initialState = {
    publications: [],
    myOffers: [],
    relationUser: {},
    objectData: {},
    isFetching: true,
    currentPublication: null,
    currentAttachments: [],
    uploadedFiles: [],
    publicationsByObject: [],
};


const offersListReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_PUBLICATIONS_LIST_DATA:
            return {
                ...state, publications: action.data
            }
        case SET_MY_OFFERS_LIST:
            return {
                ...state, myOffers: action.data
            }
        case SET_OBJECT_DATA:
            return {
                ...state, objectData: action.data
            }
        case SET_RELATION_USER_DATA:
            return {
                ...state,
                ...state.publications, relationUser: action.data
            }
        case TOGLE_IS_FETCHING: {
            return {
                ...state, isFetching: action.data
            }
        }
        case SET_CURRENT_PUBLICATION: {
            return {
                ...state, currentPublication: action.currentPublication
            }
        }
        case SET_UPLOADED_FILES: {
            return {
                ...state, uploadedFiles: [...state.uploadedFiles, action.file]
            }
        }
        case SET_RESET_UPLOADED_FILES: {
            return {
                ...state, uploadedFiles: []
            }
        }
        case SET_CURRENT_ATTACHMENT: {
            return {
                ...state, currentAttachments: action.attachments
            }
        }
        case UPDATE_CURRENT_ATTACHMENT: {
            return {
                ...state, currentAttachments: [...state.currentAttachments, action.attachment]
            }
        }
        case SET_OFFERS_BY_OBJECT: {
            return {
                ...state, publicationsByObject: action.publicationsByObject
            }
        }
        case UPDATE_OFFERS_STATUS: {
            return { 
                ...state,                  //глубокое копирование
                publicationsByObject: state.publicationsByObject.map((publication) =>
                    publication.id === action.publicationId ?
                        {
                            ...publication,
                            offers: publication.offers.map((offer) =>
                                offer.id === action.offerId ?
                                    {
                                        ...offer,
                                        status: action.status
                                    } : offer)

                        } : publication)
            }
        }
        case SET_UPDATED_PUBLICATIONS: {
            const updatedPublications = state.publications.map(publication => {
               return publication?.offers[0]?.id === action.publicationId ? {...publication, offers: []} : publication
            }) 
            return {
                ...state,
                publications: updatedPublications,
            }
        }
        default:
            return state
    }
}
export default offersListReducer;

export const setOffersListData = data => ({ type: SET_PUBLICATIONS_LIST_DATA, data });
export const setMyOffersList = data => ({ type: SET_MY_OFFERS_LIST, data });
export const setObjectData = data => ({ type: SET_OBJECT_DATA, data });
export const setRelationUserData = data => ({ type: SET_RELATION_USER_DATA, data });
export const togleIsFetching = (data) => ({ type: TOGLE_IS_FETCHING, data });
export const setCurrentPublication = (currentPublication) => ({ type: SET_CURRENT_PUBLICATION, currentPublication });
export const setUploadedFiles = (file) => ({ type: SET_UPLOADED_FILES, file });
export const resetUploadedFiles = () => ({ type: SET_RESET_UPLOADED_FILES });
export const setCurrentAttachment = (attachments) => ({ type: SET_CURRENT_ATTACHMENT, attachments });
export const updateCurrentAttachment = (attachment) => ({ type: UPDATE_CURRENT_ATTACHMENT, attachment });
export const setOffersListByObjectId = (publicationsByObject) => ({ type: SET_OFFERS_BY_OBJECT, publicationsByObject });
export const updateReduxCurrentOffer = (publicationId, offerId, status) => ({ type: UPDATE_OFFERS_STATUS, publicationId, offerId, status });
export const setUpdatedPublications = publicationId => ({ type: SET_UPDATED_PUBLICATIONS, publicationId });

export const getAllPublications = (name, country, city, specialization, hasMyOffer, sortCriteria, order) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId?.currentId;
    dispatch(togleIsFetching(true))
    instance.get(`relation/${currentRelationId}/publication/list`,{
        params: {
            city: city,
            country: country,
            specialization: specialization,
            name: name,
            sortCriteria: sortCriteria,
            sortDirection: order,
            hasMyOffer: hasMyOffer
        }
    })

        .then((result) => {
            let filteredByRelIdData = result.data.filter(data => data.object.ownerRelationId !== currentRelationId)
            dispatch(setOffersListData(filteredByRelIdData))
            dispatch(togleIsFetching(false))
        })
        .catch(err => {
            console.log(err)
            dispatch(togleIsFetching(false))
        })
}

export const getPublicationsListByObjectId = ( objectId, name, specialization, sortCriteria, order ) => (dispatch, getState) => {
    // dispatch(togleIsFetching(true))
    const currentRelationId = getState().curRelationId?.currentId;
    instance.get(`relation/${currentRelationId}/publication/listByObjectId/${objectId}`, {
        params: {
            specialization: specialization,
            name: name,
            sortCriteria: sortCriteria,
            sortDirection: order
        }
    })
        .then((result) => {
            // dispatch(togleIsFetching(false))

            result.data.code && console.log(result.data.message)
            dispatch(setOffersListByObjectId(result.data))
        })
}

export const createOffer = ( publicationId, description, files) => (dispatch, getState) => {
    // dispatch(togleIsFetching(true))

    const currentRelationId = getState().curRelationId.currentId;
    return instance.post(`relation/${currentRelationId}/offer`,
        {
            "contractorRelationId": `${currentRelationId}`,
            "publicationId": `${publicationId}`,
            "status": `REVIEW`,
            "description": `${description}`,
        }
    )
}

export const uploadAttachment = (currentRelationId, offerId, formData, attachmentName) => (dispatch, getState) => {
    dispatch(togleIsFetching(true))
    const currentRelationId = getState().curRelationId.currentId;

    instance.post(`relation/${currentRelationId}/offerAttachment/uploadByOfferId/${offerId}?attachmentName="${attachmentName}"`,
        formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    )
        .then(result => {
            dispatch(getAllPublications())
            dispatch(updateCurrentAttachment(result.data))
            dispatch(togleIsFetching(false))
        })
        .catch(err => {
            dispatch(togleIsFetching(false))
            console.log(err)
        })
}

export const deleteAttachment = (currentOrderAndRelationId, attachmentId) => (dispatch, getState) => {
    const state = getState().offers
    dispatch(togleIsFetching(true))

    instance.delete(`orderAndRelation/${currentOrderAndRelationId}/offerAttachment/${attachmentId}`)
        .then(result => {
            let attachments = state.currentAttachments.filter(f => f.id !== attachmentId)
            dispatch(setCurrentAttachment(attachments))
            dispatch(getAllPublications())

            dispatch(togleIsFetching(false))
        })
        .catch(err => {
            dispatch(togleIsFetching(false))
            console.log(err)
        })
}

export const deleteOffer = ( offerId) => (dispatch, getState) => {
    // dispatch(togleIsFetching(true))
    const currentRelationId = getState().curRelationId.currentId;
    return instance.delete(`relation/${currentRelationId}/offer/${offerId}`)
        // .then(dispatch(togleIsFetching(false)))
        // .then(dispatch(getAllPublications()))
        .catch(err => console.log(err))
}

export const getRelationsByCurrentRelationId = (currentRelationId) => (dispatch, getState) => {
    dispatch(togleIsFetching(true))
    instance.get(`relation/${currentRelationId}/listByMaster`)
        .then(result => {
            dispatch(togleIsFetching(false));
            dispatch(setRelationUserData(result.data));
        })
}


export const updateOffer = ( publicationId, offerId, description) => (dispatch, getState) => {
    // dispatch(togleIsFetching(true))
    const currentRelationId = getState().curRelationId.currentId;

    return instance.put(`relation/${currentRelationId}/offer`,
        {
            "contractorRelationId": `${currentRelationId}`,
            "publicationId": `${publicationId}`,
            "status": `REVIEW`,
            "description": `${description}`,
            "id": `${offerId}`,
        }
    )
}


export const getOffersListByMaster = (currentRelationId) => (dispatch, getState) => {
    dispatch(togleIsFetching(true))
    instance.get(`relation/${currentRelationId}/offer/listByMaster`)
        .then((result) => {
            dispatch(togleIsFetching(false))
            dispatch(setMyOffersList(result.data))
        })
}


export const updateOfferAnswer = (currentRelationId, publicationId, offerId, status) => (dispatch, getState) => {

    // {REVIEW,ACCEPTED,DENIED}
    // dispatch(togleIsFetching(true))
    const currentRelationId = getState().curRelationId.currentId;
    dispatch(updateReduxCurrentOffer(publicationId, offerId, status));

    const publication = getState().offers.publicationsByObject.filter(publication=>
    publication.id === publicationId);
    const updatedOffer = publication[0].offers.filter(offer => offer.id === offerId);

    return instance.put(`relation/${currentRelationId}/offer`, updatedOffer[0])
        .catch(err => console.log(err))
}
