
import { togleIsFetching } from "./preloaderReducer";
import instance from "../../axios/axiosInstance";
import { deleteParticipant, getObjectStateByContractorId } from "./progressReducer";
import { getUsersListByMaster } from "./usersReducer";

// const SET_CONTRACTORS_LIST = "SET_CONTRACTORS_LIST";

let initialState = {
};

const orderAndRelationReducer = (state = initialState, action) => {
    switch (action.type) {

        // case SET_CONTRACTORS_LIST: {
        //     return {
        //         ...state, contractorsList: action.list.list
        //     }
        // } 

        default: {
            return state
        }
    }
}
export default orderAndRelationReducer;

// export const updateReduxTasksDates = (eventId, start, end) => ({ type: UPDATE_TASCS_DATES, eventId, start, end });


export const createOrderAndRelation = (relationId) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    const orderId = getState().objects.data.orderAndRelation.orderId;
    // console.log(currentRelationId);
    // console.log(orderId);
    // console.log(relationId);

    instance.post(`relation/${currentRelationId}/orderAndRelation`,
        {
            "orderAndRelationType": 'EMPLOYEE',
            "orderId": `${orderId}`, //id объекта
            "relationId": `${relationId}`, //relation работника
            // "roleId": "string",
        }
    )
        .then((res) => {
            res.data.code ?
                console.log(res.data.message) :
                console.log(res.data)
        }).then((res) => { dispatch(getObjectStateByContractorId(orderId, true)) })
        .catch(err => console.log(err))
}

export const createOrderAndRelationFromMyTeam = (orderId,relationId,roleId) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    console.log(orderId, relationId, roleId)
    instance.post(`relation/${currentRelationId}/orderAndRelation`,
        {
            "orderAndRelationType": 'EMPLOYEE',
            "orderId": `${orderId}`, //id ордера на объект
            "relationId": `${relationId}`, //relation работника
            "roleId": `${roleId}`, // roleId выбранная при добавлении
        }
    )
        .then((res) => {
            res.data.code ?
                console.log(res.data.message) :
                console.log(res.data)
        }).then((res) => { dispatch(getUsersListByMaster()) })
        .catch(err => console.log(err))
}

// export const updateOrderAndRelation = (relationId, roleId) => (dispatch, getState) => {

//     const currentRelationId = getState().curRelationId.currentId;
//     const orderId = getState().objects.data.orderAndRelation.orderId;

//     instance.put(`relation/${currentRelationId}/orderAndRelation`,
//     {
//         "id": id,
//         "orderAndRelationType": 'EMPLOYEE' ,
//         "orderId": {orderId}, //id заказа
//         "relationId": {relationId}, //relation работника
//         "roleId": {roleId},
//       }
//     )
//         .then((res) => {
//             res.data.code ?
//             console.log(res.data.message):
//             console.log(res.data)
//         })
//         .catch(err => alert(err))
// }

export const deleteOrderAndRelation = (orderAndRelationId) => (dispatch, getState) => {

    const currentRelationId = getState().curRelationId.currentId;
    dispatch(deleteParticipant(orderAndRelationId));
    instance.delete(`relation/${currentRelationId}/orderAndRelation/${orderAndRelationId}`)
        .then((res) => {
            res.data.code ?
                console.log(res.data.message) :
                console.log(res.data)
        })
        .catch(err => alert(err))
}

