import instance from "../../axios/axiosInstance";
import Cookies from "universal-cookie";
import { tarifId } from '../../constants/constants';

const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';
const SET_CITIES_LIST = 'SET_CITIES_LIST';
const SET_TARIFFS = 'SET_TARIFFS';

let initialState = {
    countries: [],
    cities: [],

};

const localeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTRIES_LIST: {
            return {
                ...state, countries: action.countries
            }
        }
        case SET_CITIES_LIST: {
            return {
                ...state, cities: action.cities
            }
        }
        case SET_TARIFFS: {
            const trasformedTarrifs = action?.tariffs.map(tarrif => {
                const id = tarifId[tarrif.objectType][tarrif.paymentKind].tarifId;
                tarrif.tarrifId = id
                return tarrif;
            })
            return {
                ...state, tariffs: trasformedTarrifs
            }
        }
        default: {
            return state

        }
    }
}
export default localeReducer;

export const setCountries = (countries) => ({ type: SET_COUNTRIES_LIST, countries });
export const setCities = (cities) => ({ type: SET_CITIES_LIST, cities });
export const setTariffs = (tariffs) => ({ type: SET_TARIFFS, tariffs });



export const getAllCountries = () => (dispatch, getState) => {

    instance.get(`country/list`,{
        params: {
            order: "ASC",
            page: 0,
            perPage: 1000,
            sort: "countryName"
        }
    })
        .then((res) => {
            !res.data.code ?
            dispatch(setCountries(res.data)) :
            console.log(`Error Code:${res.data.code} - ${res.data.message}`);
        })
};

export const getCitiesByCountry = (countryName) => (dispatch, getState) => {
    instance.get(`country/${countryName}/city/list`,{
        params: {
            order: "ASC",
            page: 0,
            perPage: 1000,
            sort: "cityName"
        }
    })
        .then((res) => {
            !res.data.code ?
                dispatch(setCities(res.data)) :
                console.log(`Error Code:${res.data.code} - ${res.data.message}`);

        })
};


export const getTariffListByMaster = () => (dispatch, getState) => {
    const cookies = new Cookies();
    const currentRelationId = getState().curRelationId?.currentId || cookies.get('currentRelation');

    instance.get(`relation/${currentRelationId}/tariff/list`)
        .then((res) => {
            !res.data.code ?
                dispatch(setTariffs(res.data)) :
                console.log(`Error Code:${res.data.code} - ${res.data.message}`);
        })
        .catch(err => console.log(err))
};


