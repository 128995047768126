import React from 'react';
import instance from "../../axios/axiosInstance";
import {getObjectStateByContractorId} from './progressReducer';
import { Redirect } from 'react-router-dom';


const SET_ROLES_DATA = 'SET_ROLES_DATA';
const SET_PRIVILEGES = 'SET_PRIVILEGES';
const TOGLE_IS_FETCHING = 'TOGLE_IS_FETCHING';
const SET_CHECKED_LIST = 'SET_CHECKED_LIST';
const SET_CUR_RELATION_PRIVILEGES = 'SET_CUR_RELATION_PRIVILEGES';
const SET_ROLE_ID = 'SET_ROLE_ID';

let initialState = {
    roles: [],
    privileges: [],
    checked: [],
    isFetching: false,
    currentRelationPriveleges:[],
};

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROLES_DATA: {
            
            return {
                ...state, roles: action.data.data
            }
        }
        case SET_PRIVILEGES: {
            return {
                ...state, privileges: action.data.data
            }
        }
        case SET_CUR_RELATION_PRIVILEGES: {
            return {
                ...state, currentRelationPriveleges: action.data.data
            }
        }
        case TOGLE_IS_FETCHING: {
            return {
                ...state, isFetching: action.data
            }
        }
        case SET_CHECKED_LIST: {
            return {
                ...state, checked: action.checked
            }
        }

        case SET_ROLE_ID: {
            return {
                ...state, curRoleId: action.data
            }
        }

        default: {
            return state
        }
    }
}
export default rolesReducer;

export const setRolesList = (data) => ({ type: SET_ROLES_DATA, data });
export const setPrivIleges = (data) => ({ type: SET_PRIVILEGES, data });
export const setCurRelationPrivIleges = (data) => ({ type: SET_CUR_RELATION_PRIVILEGES, data });
export const togleIsFetching = (data) => ({ type: TOGLE_IS_FETCHING, data });
export const setCheckedList = (checked) => ({ type: SET_CHECKED_LIST, checked });
export const curRoleId = (data) => ({ type: SET_ROLE_ID, data });

export const getAllPossibleRoles = (currentRelationId) => (dispatch, getState) => {
    // dispatch( togleIsFetching(true) )
    const currentRelationId = getState().curRelationId.currentId;
    instance.get(`relation/${currentRelationId}/role/list`)
    .then((res)=> {
        console.log(res);
        !res.data.code?
        dispatch( setRolesList({ data: res.data})) :
        console.log(`Error Code:${res.data.code} - ${res.data.message}`) ;
    })
    .catch(err => alert(err))
}

export const setRoleToUser = (orderAndRelId, roleId) => (dispatch, getState) => {

    const currentRelationId = getState().curRelationId.currentId;
    const orderId = getState().objects.data.orderAndRelation.orderId;
    instance.put(`/relation/${currentRelationId}/orderAndRelation`, {
        "id": orderAndRelId,
        "roleId": roleId
    })
        .then((res) => {
            res.data.code && console.log(res.data.message) 
        })
        .then((res) => { dispatch(getObjectStateByContractorId(orderId, true)) })
        .catch(err => console.log(err))
}

export const getAllPossiblePrivilegesWithPagination = (currentRelationId) => (dispatch, getState) => {
    instance.get(`relation/${currentRelationId}/role/allPossiblePrivileges?order=ASC&page=0&perPage=1000`)
        .then(res =>  dispatch( setPrivIleges({ data: res.data }) ) )
        .catch(err => { alert(err) })
}

export const getCurrentRelationPriveleges = (currentRelationId, roleId) => (dispatch, getState) => {
    
    if(currentRelationId && roleId){
        instance.get(`relation/${currentRelationId}/role/${roleId}/privileges`)
        .then(res => {  return (dispatch( setCurRelationPrivIleges({ data: res.data }) )) })
    }
}

export const createRole = (currentRelationId, privileges, roleName, roleDescription) => (dispatch, getState) => {
    dispatch(togleIsFetching(true))
    instance.post(`relation/${currentRelationId}/role`,{
        "privileges": privileges,
        "roleName": roleName,
        "roleDescription": roleDescription,
      })
        .then(res => dispatch( togleIsFetching(false), console.log(res)) )
        .catch(err => { alert(err) })
        .then(()=>dispatch(setCheckedList([])))
        .then (()=>dispatch(getAllPossibleRoles(currentRelationId)))
}

export const updateRole = (currentRelationId, privileges, roleName, roleId, roleDescription) => (dispatch, getState) => {
    dispatch(togleIsFetching(true))
    instance.put(`relation/${currentRelationId}/role`, {
        "id": roleId,
        "privileges": privileges,
        "roleName": roleName,
        "roleDescription": roleDescription,
    })
        .then(res => dispatch( togleIsFetching(false),console.log(res)) )
        .catch(err => { alert(err) })
        .then (()=>dispatch(getAllPossibleRoles(currentRelationId)))
        .then (()=>dispatch(getCurrentRelationPriveleges( currentRelationId, roleId )))
}

export const deleteRole = (currentRelationId, roleId, replacementRoleId) => (dispatch, getState) => {
    dispatch(togleIsFetching(true))
    instance.delete(`relation/${currentRelationId}/role/${roleId}?replacementRoleId=${replacementRoleId}`)
        .then(res => dispatch( togleIsFetching(false), console.log(res)) )
        .catch(err => { alert(err) })
        .then (()=>dispatch(getAllPossibleRoles(currentRelationId)))
}


export const roleCheck = (data, currentRelationPriveleges, roleType, urlCheck) => {

    let parts = window.location.pathname.split('/').filter(function (el) {
      return el.length !== 0
    });
      if (currentRelationPriveleges) {
        if (currentRelationPriveleges.length > 0) {
          let roleCheck = currentRelationPriveleges.indexOf(roleType);
          if (roleCheck >= 0) {
            return data
          }
          else {
            let type = parts[0];
            console.log(type)
            if (type == urlCheck) {
              return (<Redirect to="" />)
            }
            return ''
          }
        }
      }
    }
    // usage:
    // roleCheck(
    //     {children},
    //     curRelationRoles, "RELATION_WRITE"
    //   )