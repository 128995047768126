import React from 'react';

import { withStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme)=> ({

  descInput: {
    borderRadius: '4px',
    width: '100%',
    maxWidth: '470px',
    height: '42px',
  },
  input: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#373A44',
    border: '1px solid #CDD4DC',
    borderRadius: '4px',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF !important',
    boxSizing: 'border-box',
    padding: '11px 16px',
    '&:disabled': {
      background: '#DCDAD1 !important'
    },
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: '#8F919B',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  label: {
    fontSize: '14px',
    lineHeight: '17px',
    color: '#373A44',
    marginBottom: '0px',
    borderRadius: '4px',
    width: '100%',
    maxWidth: '200px',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '21px',
      color: '#8F919B',
      marginBottom: '10px',
    },
  },
  inputDescWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '41px',
    },
  },
  errorMessage: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#FF0809',
    marginBottom: '10px',
  },
  error: {
    color: '#FF0809',
    borderColor: '#FF0809',
  },

});

const EmailInput = props => {
  const {
      classes,
      className,
      register,
      placeholder,
      label,
      name,
      errors,
      required,
      type,
      value,
      clearErrors,
    } = props;

    console.log(errors)
  return (
    <Grid className={clsx(classes.inputDescWrap, className)}>
      <label
        for={name}
        className={clsx(classes.label, errors[name] && classes.error)}>
            {label}
        {required &&
          <span style={{ color: '#0D9CFC' }}> *</span>
        }
      </label>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.descInput}
      >
        <input
            autocomplete="off"
            name={name}
            type={type}
            placeholder={placeholder}
            className={clsx(classes.input, errors[name] && classes.error)}
            ref={register}
            autocomplete="none"
            onChange={() => clearErrors(["email", "tel"])}
        />
        {errors[name]?.type === "required" && (
          <p className={classes.errorMessage}> обязательное поле </p>
        )}
        {errors[name]?.type === "pattern" && (
          <p className={classes.errorMessage}> неккоретный e-mail </p>
        )}
        {errors?.email && <p className={classes.errorMessage}>{errors.email?.types?.message}</p>}
      </Grid>
    </Grid>
  )
};

export default withStyles(styles)(EmailInput)