import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import ButtonWithoutBorder from '../components/buttons/ButtonWithoutBorder';

import { ReactComponent as XIcon } from '../images/XIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileData } from '../redux/reducers/profileReducer';

import { getUsers } from '../redux/reducers/usersListReducer';
import { getUsersListByMaster } from '../redux/reducers/usersReducer';
import { createOrderAndRelation } from '../redux/reducers/orderAndRelationReducer';
import Rater from 'react-rater';
import { useForm } from 'react-hook-form';
import { addRating, addRatingProfilePopup, getRatingsByUser, updateRatingProfilePopup } from '../redux/reducers/ratingReducer';
import { wrapConsumer } from '../hoc/AlertProvider';


const styles = theme => createStyles({

  title: {
    padding: 0,
    borderTop: '10px solid #0D9CFC',
  },
  xIcon: {
    padding: ' 21px 29px 0 0 ',
    display: 'flex',
    marginLeft: 'auto',
    '& path': {
      fill: '#A9C1D1',
    },
    '& :hover ': {
      cursor: 'pointer',
    }
  },
  titleText: {
    padding: ' 0 66px 0 42px',
    '& h2': {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '24px',
      color: '#008CE1',

    },
  },
  content: {
    paddingTop: 0,
    padding: '0 42px',
  },
  cardText: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#373A44',
    paddingTop: '8px',

  },
  buttonWrap: {
    margin: 0,
    padding: '16px 42px 24px ',
    background: '#F5F6F8',
  },
  button: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    borderRadius: '4px',
    color: '#FFFFFF',
    background: '#004EE5',
    textTransform: 'initial',
    margin: 0,
    '&:hover': {
      background: '#0D9CFC',
    },
    '&:disabled': {
      color: '#FFFFFF',
      background: '#B3C7F0',
    },
  },
  cancelBtn: {
    marginRight: '6px',
    background: '#F5F6F8'
  },
  rating: {
    display: 'flex',
    marginLeft: 'auto',
    fontSize: '25px',
    cursor: 'pointer',
    '& .react-rater-star': { // color of star not rated 
      color: '#A9C1D1',
    },
    '& .is-active': {  // color of star rated
      color: '#F1CA00',
    },
    '& .will-be-active': {  // color of star on hover 
      color: '#F1CA00',
    },

  },
  inputsWrap: {
      display: 'flex',
      flexDirection: 'column',
      padding: ' 10px 0  36px',
      '& label': {
          color: '#373A44',
          paddingBottom: '8px',
      },
  },
  description: {
      '& textarea': {
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '19px',
          color: '#8F919B',
          padding: '12px 16px',

          border: '1px solid #A9C1D1',
          borderRadius: '4px',
          minWidth: '364px',
          resize: 'none',
          overflow: 'hidden',
          '&:focus':{
            outline: 'none',
          },
          
      }
  },

})


function AddFeedback(props) {
  const dispatch = useDispatch();

  const { classes, relationId, orderId, userName, children, userId } = props;

  const { register, handleSubmit, setValue, watch, errors, reset, control } = useForm()

  const isOwner = useSelector(state => state.objects.objecttabOwner);
  const refreshableRating = useSelector(state => state.rating.refreshableRating);
  const currentRelationId = useSelector(state => state.curRelationId.currentId)

  const [open, setOpen] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const [description, setDescription] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(getRatingsByUser('', userId))
  };


  useEffect(()=>{
    setRating(refreshableRating?.score);
  }, [refreshableRating, userId]);
  useEffect(()=>{
    setDescription(refreshableRating?.description || '');
  }, [refreshableRating, userId]);

  const success = () => {
    console.log('success');
    dispatch(getProfileData(userId));
    dispatch(getRatingsByUser('', userId));
    dispatch(getUsers(currentRelationId, '', '', ''));
};

  
  const handleClose = () => {
    setOpen(false);
    // reset();
  };

  const onRate = (e)=> {
    setRating(e.rating)
  };

  const onSubmit = data => {
    refreshableRating ?
      dispatch(updateRatingProfilePopup( refreshableRating, rating, data.description, userId ))
        .then(res=>{res.data.code ? 
          props.addNewAlert('error', 'Ошибка, попробуйте снова') :
          success();
        })
        :
      dispatch(addRatingProfilePopup(rating, data.description, orderId, relationId, userId))
        .then(res => {
          res.data.code ?
            // console.log(res.data.message)
            props.addNewAlert('error', 'Вы не сотрудничали, извините') :
            success();
        })
    handleClose();
  }

  const placeholder = isOwner ?
   'Поделитесь впечатлениями о совместной работе':
   'Только владелец объекта может оставлять отзывы'

  return (
    <>
      <Grid item xs container
        onClick={handleClickOpen}>
        {children}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title" className={classes.title}>
          <XIcon onClick={handleClose} className={classes.xIcon} />
        </DialogTitle>

        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="stretch"
        >

          <Grid item className={clsx(classes.cardWrap)}>
            <DialogTitle id="form-dialog-title" className={classes.titleText}>
              Добавить отзыв
              <div className={classes.cardText}>
               на {userName}
              </div>
            </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
            <DialogContent className={classes.content}>

              {/* <DialogContentText className={classes.cardText}>
               на {userName}
              </DialogContentText> */}

              <Rater
                className={classes.rating}
                total={5}
                rating={rating}
                onRate={onRate}
              />
              
              {/* <CustomTextField
              placeholder={'Поделитесь впечатлениями о совместной работе'}>

              </CustomTextField> */}

            <div className={clsx(classes.inputsWrap, classes.description)}>
                <label htmlFor="description" className={classes.label}>Отзыв</label>
                <textarea
                    name="description"
                    cols="40"
                    rows="5"
                    placeholder={placeholder}
                    value={description}
                    onChange={(e)=>setDescription(e.value)}
                    ref={register({required:true})} 
                />
            </div>
            </DialogContent>

            <DialogActions className={classes.buttonWrap}>
              <ButtonWithoutBorder title={'Отменить'}
                style={{ marginRight: '6px', background: '#F5F6F8' }}
                onClick={() => handleClose()} />

              <Button
                className={classes.button} 
                type='submit'
                disabled={!isOwner}
                >
                Добавить отзыв
              </Button>
            </DialogActions>

            </form>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
export default wrapConsumer(withStyles(styles)(AddFeedback));


// //example of use

// <AddFeedback
//   userName={userName}
//   userId={user.id}
//   relationId={user.relationId}
//   orderId={worker.orderId}>
//   'Оценить исполнителя'
// </AddFeedback>