import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk';
import profileReducer from './reducers/profileReducer';
import materialsReducer from './reducers/materialsReducer';
import workListReducer from './reducers/workListReducer';
import workGroupReducer from './reducers/workGroupReducer';
import userDataReducer from './reducers/userDataReducer';
import actDataReducer from './reducers/actReducer';
import relationReducer from './reducers/relationReducer';
import usersListReducer from './reducers/usersListReducer';
import registrationReducer from './reducers/registrationReducer';
import objectsReducer from './reducers/objectsReducer';
import usersReducer from './reducers/usersReducer';
import employersListReducer from './reducers/employersListReducer';
import offersListReducer from './reducers/offersListReducer';
import headerReducer from './reducers/appBarrHeaderReducer';
import rolesReducer from './reducers/rolesReducer'
import preloaderReducer from './reducers/preloaderReducer';
import progressReducer from './reducers/progressReducer';
import specializationsReducer from './reducers/specialisationsReducer';
import tasksReducer from './reducers/tasksReducer';
import ordersReducer from './reducers/ordersReducer';
import ratingReducer from './reducers/ratingReducer';
import orderAndRelationReducer from './reducers/orderAndRelationReducer';
import paymentAttachmentsReducer from './reducers/paymentAttachmentsReducer';
import vacancyReducer from './reducers/vacancyReducer';
import paymentReducer from './reducers/paymentReducer';
import chatsReducer from './reducers/chatsReducer';
import localeReducer from './reducers/localeReducer';
import errorsReducer from './reducers/errorsReducer';

const rootReducer = combineReducers({
  locale: localeReducer,
  objects: objectsReducer,
  roles: rolesReducer,
  users: usersReducer,
  usersList: usersListReducer,
  acts: actDataReducer,
  works: workListReducer,
  workGroup: workGroupReducer,
  materials: materialsReducer,
  profile: profileReducer,
  // dataProvider: Reducer,
  userData: userDataReducer,
  curRelationId: relationReducer,
  registration: registrationReducer,
  employers: employersListReducer,
  offers: offersListReducer,
  header: headerReducer,
  preloader: preloaderReducer,
  progress: progressReducer,
  payment: paymentReducer,
  paymentAttachments: paymentAttachmentsReducer,
  tasks: tasksReducer,
  specializations: specializationsReducer,
  order: ordersReducer,
  rating: ratingReducer,
  orderAndRelation: orderAndRelationReducer,
  vacancy: vacancyReducer,
  chats: chatsReducer,
  errors: errorsReducer,
  form: formReducer,
})

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__  ? window.__REDUX_DEVTOOLS_EXTENSION__ () : f => f
  
  )
)


window.store = store;

