import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { setObjectTabIndex } from '../redux/reducers/objectsReducer';
import { withStyles } from '@material-ui/core';

const AntTabs = withStyles({

  indicator: {
    height: 2,
    backgroundColor: '#0D9CFC',
  },
})(Tabs);

const AntTab = withStyles(() => ({
  root: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    minWidth: 94,
    height: 64,
    color: '#668BA3',
    '&:hover': {
      color: '#0D9CFC',
      opacity: 1,
    },
    '&$selected': {
      color: '#0D9CFC',
    },
    '&:focus': {
      color: '#0D9CFC',
    },
    '& span': {
      paddingLeft: '7px',
      paddingRight: '7px',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const styles = () => ({

});

export default function ObjectTabs(props) {
  const classes = styles();
  const dispatch = useDispatch()

  const tabIndex = useSelector(state => state.objects.tabIndex);
  const isOwner = useSelector(state => state.objects.data.meOwner);
  const expiredDate = useSelector(state => state.objects.data?.objectModel?.expiredAt);
  const objectId = useSelector(state => state.objects.data?.objectModel?.id);
  const isExpiredProject = new Date(expiredDate) - new Date() < 0;

  const cookies = new Cookies();
  let activeCookieTab = cookies.get('activeObjectTab');
  const handleChange = (event, newValue) => {
    cookies.set('activeObjectTab', newValue, { path: '/' });
    activeCookieTab = cookies.get('activeObjectTab');
    if (activeCookieTab) {
      dispatch(setObjectTabIndex(+activeCookieTab)); 
    } else {
      dispatch(setObjectTabIndex(newValue));
    }

    if (props.path.includes('/element/') || props.path.includes('/task/')) {
      const currentHost = window.location.origin;
      window.location.assign(`${currentHost}/object/${objectId}`);  
    }
  };
  let currentTabValue = activeCookieTab ? +activeCookieTab : tabIndex;
  if (props.path.includes('/element/') || props.path.includes('/task/')) {
    currentTabValue = -1; // display no active tab id on element or task page
  }
  
  return (
    <AntTabs
      value={currentTabValue}
      onChange={handleChange}
      aria-label="object tabs"

    >
      <AntTab label="ПРОЕКТ" />
      <AntTab disabled={isExpiredProject} label="УПРАВЛЕНИЕ" />
      { isOwner &&
      <AntTab disabled={isExpiredProject} label="ЗАКАЗЫ" />}
    </AntTabs>
  );
}





// documentation  https://material-ui.com/ru/components/tabs/#customized-tabs