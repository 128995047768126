import React, { Component, Fragment, lazy, Suspense } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Route, Redirect, BrowserRouter  } from "react-router-dom";
import JssProvider from 'react-jss/lib/JssProvider';
import russianMessages from './i18n/ru';
import dataProviderFactory from './dataProvider';
import MenuClear from './layout/MenuClear';
import AppBar from './layout/AppBarClear';
import { connect, ReactReduxContext } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { setUser, isAuth, userLogin } from './redux/reducers/userDataReducer';
import { setItemName, setSectionName } from './redux/reducers/appBarrHeaderReducer';
import { 
    setInviteLink, 
    setIsNewUser,
} from './redux/reducers/preloaderReducer.js';
import { setCurrentId, setCurrentMainUserId, findProposalsBetweenTwoUsers } from './redux/reducers/relationReducer';
import { 
    clearUnreadMessagesArray,
    setChatType,
    setSelectedChat,
    resetUnreadMessagesCount,
    setUserLastView,
    setSelectedChatEmail,
} from './redux/reducers/chatsReducer';
import ErrorBoundary from './errors/ErrorBoundary';
import { ReactComponent as UserIcon } from './images/chatUser.svg';
import { CancelScheduleSendSharp } from '@material-ui/icons';
import { jssPreset, createGenerateClassName } from '@material-ui/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';

const ElementGridClear = lazy(() => import('./objects/element/ElementGridClear'));
const ObjectMainScreen = lazy(() => import('./objects/ObjectMainScreen'));
const TaskGridClear = lazy(() => import('./objects/task/TaskGridClear'));
const UserInfo = lazy(() => import('./usersList/UserInfo'));
const RatingList = lazy(() => import('./rating/RatingsList'));
const ProfilePage = lazy(() => import('./profile/ProfilePage'));
const WorkListSort = lazy(() => import('./works/WorkMainScreen'));
const ActList = lazy(() => import('./acts/ActListClear'));
const ObjectListClear = lazy(() => import('./objects/ObjectListClear'));
const WorkListClear = lazy(() => import('./works/WorkListClear'));
const TeamMainScreen = lazy(() => import('./myTeam/TeamMainScreen'));
const VacanciesPage = lazy(() => import('./vacancies/VacanciesPage '));
const Publications = lazy(() => import('./offers/Publications'));
const JobSeekersPage = lazy(() => import('./jobSeekers/JobSeekersPage'));
const Chats = lazy(() => import('./messenger/Chats.js'));
const PayForTheProject = lazy(() => import('./payForTheProject/PayForTheProject'));
const EmailSettings = lazy(() => import('./email/EmailSettings'));
const ErrorsPage = lazy(() => import('./errors/ErrorsPage'));
const LandingPage = lazy(() => import('./auth/LandingPage'));
const Registration = lazy(() => import('./auth/Registration'));
const Authorisation = lazy(() => import('./auth/Authorisation'));
const ResetPassword = lazy(() => import('./auth/ResetPassword'));
const ChangePassword = lazy(() => import('./auth/ChangePassword'));
const PrivacyPage = lazy(() => import('./auth/PrivacyPage'));
const GroupWorkSort = lazy(() => import('./groupsWorks/WorkGroupList'));


const DEFAULT_LANGUAGE = 'ru';

const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: true,
    productionPrefix: 'muicustom',
  });

const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
  });

const asyncMessages = {
    en: () => import('./i18n/en').then(messages => messages.default),
};

const i18nProvider = locale => {
    if (locale === DEFAULT_LANGUAGE) {
        return russianMessages;
    }

    return asyncMessages[locale]();
};

const cookies = new Cookies();

const styles = theme => createStyles({
    wrap: {
        background: '#F5F6F8',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '69px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px',
        },
    },
    globalWrap: {
        minHeight: '100vh',
    },
    globalWrapMessages: {
        height: '100vh',
    },
    unreadedMsgWrapper: {
        position: 'fixed',
        right: '0%',
        bottom: '0%',
        width: '350px',
        zIndex: '99999',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    hideMessage: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#0D9CFC',
        height: '40px',
        background: '#FFF',
        boxShadow: '1px 0px 2px 2px #CCC',
        marginBottom: '7px',
        '&:hover': {
            background: '#F1F1F1',
            color: '#004EE5',
            cursor: 'pointer',
        }
    },
    unreadedMeassage: {
        boxShadow: '1px 1px 2px 1px #CCC',
        marginBottom: '7px',
        padding: '5px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        zIndex: '99999',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        background: '#FFF',
    },
    senderIcon: {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%',
        background: '#85C1E9',
        marginRight: '15px',
        fontWeight: 'bold',
    },
    senderMessage: {
        width: '260px',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
})

const mobileScreenWidth = 500;
class App extends Component {
    state = {
        dataProvider: null,
        curID: this.props.counter,
        objects: [],
        changedRel: false,
        margin: 0,
        isRedirectToMessages: false,
        isMobile: false, 
    };

    unreadedMessagesRef = React.createRef(null);

    async UNSAFE_componentWillMount() {
        const dataProvider = await dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER
        );

        this.setState({ dataProvider });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.curId && this.props.curId && nextProps.curId !== this.props.curId) {
            let parts = window.location.pathname.split('/').filter(function (el) {
                return el.length !== 0
            });
            let elemType = parts[parts.length - 2];
            if (elemType == "task") {
                this.setState({ changedRel: true })
            }
            else {
                this.setState({ changedRel: false })
            }
        }
    }

    cookiesId = cookies.get('UserId');
    parts = window.location.pathname.split('/').filter(function (el) {
        return el.length !== 0
    });
    
    componentDidMount() {
        this.setState({ changedRel: false })
        if (this.cookiesId) {
            this.props.isAuth(true);
            this.props.userLogin();
        }
        window.addEventListener('resize', this.calcSizeAndMargin);
        this.calcWindowSize();
        this.defineAppWidth();
        if (window.location.href.includes('fast_join=true')) {
            const link = this.linkConverter(window.location.href);
            this.props.setInviteLink(link);
        }
        if (window.location.href.includes('newUser=false')) {
            this.props.setIsNewUser(false);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.unreadedMessages?.length > prevProps.unreadedMessages.length) {
            setTimeout(() => this.props.clearUnreadMessagesArray(), 10000)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calcSizeAndMargin);
    }

    changeRedirectMessageFlag = () => {
        this.setState({ isRedirectToMessages: false });
    }

    calcSizeAndMargin = () => {
        this.calcMargin();
        this.calcWindowSize();
    }

    calcMargin = () => {
        const margin = (window.innerWidth - document.body.clientWidth) / 2;
        this.setState({ margin });
    }

    calcWindowSize = () => {
        if (document.body.clientWidth < mobileScreenWidth) {
            this.setState({ isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }
    }

    defineAppWidth = () => {
        const windowWidth = window.innerWidth;
        const appWidth = document.body.clientWidth;
        const margin = Math.round((windowWidth - appWidth) / 2);
        this.setState({ margin });
    }

    redirectToCurrentChathandler = (chat) => {
        const { 
            currentEmail, 
            setChatType,
            setSelectedChat,
            resetUnreadMessagesCount,
            setUserLastView,
            findProposalsBetweenTwoUsers,
            setSelectedChatEmail,
        } = this.props;
        const chatEmailToSet = currentEmail === chat.user1 ? chat.user2 : chat.user1;
        let chatType = '';
        if (chat.id) chatType = 'user';
        if (chat.orderId) chatType = 'order';
        if (chat.companyId) chatType = 'company';

        setChatType(chatType);
        setSelectedChat(chat?.id);
        resetUnreadMessagesCount({ chatSelected: chat.id, chatType });
        setUserLastView(chat?.id);
        findProposalsBetweenTwoUsers(chatEmailToSet);
        setSelectedChatEmail(chatEmailToSet);

        this.setState({ isRedirectToMessages: true });
        this.props.clearUnreadMessagesArray();
    } 

    linkConverter(url) {
        return url.split('?')[1];
    }

    render() {
        const { dataProvider, objects, isMobile } = this.state;

        const { classes, setSectionName, selectedChat } = this.props;

        // if (!dataProvider) {
        //     return (
        //         <div className="loader-container">
        //             <div className="loader">Loading...</div>
        //         </div>
        //     );
        // }
        
        if (this.cookiesId || this.props.data.authState !== false) {
            const logCheck = window.location.href.indexOf('login') + 1
            const regCheck = window.location.href.indexOf('registration') + 1
            const chengePassCheck = window.location.href.indexOf('changePassword') + 1

            if (logCheck > 0 || regCheck > 0 || chengePassCheck > 0) {
                window.location.href = '/objects';
            }

            let parts = window.location.pathname.split('/').filter(function (el) {
                return el.length !== 0
            });
            setSectionName(parts[0])
            let type = parts[0];
            let id = parts[1];
            let elemId = parts[parts.length - 1];
            let elemType = parts[parts.length - 2];
            var routesGenerator = () => {
                if (type && id) {
                    if (type == "element") {
                        return (
                            <Route key={id} path={`/${type}/${id}`} render={() => <ElementGridClear />} />
                        );
                    }
                    else if (type == "object") {
                        return (
                            <Route key={id} path={`/${type}/${id}`} render={() => <ObjectMainScreen objId={id} />} />
                        );
                    }
                    else if (elemType == "task" && elemId) {
                        return (
                            <Route key={id} path={`/relation/${this.props.curId}/${elemType}/${elemId}`} render={() => <TaskGridClear />} />
                        );
                    }
                    else if (type == "user") {
                        return (
                            <Route path={`/user/${id}`} render={() => <UserInfo />} />
                        );
                    }
                    else if (type == "rating") {
                        return (
                            <Route path={`/rating/${id}`} render={() => <RatingList />} />
                        );
                    }
                }
            }

            const messagesPage = window.location.pathname.split('/')[1] == 'messages'

            var generRoute = routesGenerator();

            const isRelChanged = () => {
                if (this.state.changedRel == true) {
                    return (<Redirect to="/objects" />)
                }
                if (this.state.isRedirectToMessages) {
                    return (<Redirect to="/messages" />)
                }
            }

            return (
                <Fragment>
                    <ErrorBoundary>
                        {isRelChanged()}
                        <JssProvider jss={jss} generateClassName={generateClassName}>
                            <Grid
                                className={
                                    messagesPage ? 
                                    classes.globalWrapMessages :
                                    classes.globalWrap
                                }
                                container
                                direction="row"
                                wrap="nowrap"
                            >
                                {/* <Grid item xs={2}> */}
                                <div className={classes.unreadedMsgWrapper} ref={this.unreadedMessagesRef} style={{ marginRight: this.state.margin }}>
                                    {
                                        !!this.props.unreadedMessages?.length &&  
                                        <div className={classes.hideMessage} onClick={this.props.clearUnreadMessagesArray}>
                                            <span>Спрятать</span>
                                        </div>
                                    }
                                    {!!this.props.unreadedMessages && this.props.unreadedMessages.map(data => {
                                        console.log(this.props.unreadedMessages, data);
                                        const senderIcon = data.msg.senderName ? data.msg.senderName.split(' ').map(n => n[0]).join("") : <UserIcon />;
                                        const senderMessage = data.msg.message;
                                        const senderName = data.msg.senderName;
                                        return (
                                            <div key={data.msg.id} className={classes.unreadedMeassage} id={data.msg.id} onClick={() => this.redirectToCurrentChathandler(data.elToUpdate)}>
                                                <div className={classes.senderIcon}>{senderIcon}</div>
                                                <div className={classes.senderMsgAndName}>
                                                    <div className={classes.senderName}>{senderName}</div> 
                                                    <div className={classes.senderMessage}>{senderMessage}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                                <MenuClear setItemName={this.props.setItemName} />
                                {/* </Grid> */}
                                <Suspense fallback={''}>
                                    <Grid className={classes.wrap} item xs={12}>
                                        <AppBar />
                                            <Route path='/' exact render={() => <ObjectListClear />} />
                                            <Route path={`/profile`} render={() => <ProfilePage />} />
                                            <Route path='/work/list' render={() => <WorkListSort />} />
                                            <Route path='/workGroup/list' render={() => <GroupWorkSort />} />
                                            <Route path='/acts' render={() => <ActList />} />
                                            <Route path='/objects' render={() => <ObjectListClear />} />
                                            <Route path='/work/listold' render={() => <WorkListClear />} />
                                            <Route path='/team-page' render={() => <TeamMainScreen />} />
                                            <Route path='/vacancies' render={() => <VacanciesPage />} />
                                            <Route path='/offers' render={() => <Publications />} />
                                            {generRoute}
                                            <Route path='/jobSeekers' render={() => <JobSeekersPage />} />
                                            <Route path='/messages' render={() => <Chats changeRedirectMessageFlag={this.changeRedirectMessageFlag}/>} />
                                            <Route path='/pay-for-the-project' render={() => <PayForTheProject />} />
                                            <Route path='/email' render = {() => <EmailSettings />} />
                                            <Route path='/error' render = {() => <ErrorsPage />} />
                                    </Grid>
                                </Suspense>
                            </Grid>
                        </JssProvider>
                    </ErrorBoundary>
                </Fragment>
            );
        }
        else {
            return (
                <Suspense fallback={''}>
                    <Grid>
                        <Route exact path='/' render={() => <LandingPage />} />
                        <Route path='/registration' render={() => <Registration />} />
                        <Route path='/login' render={() => <Authorisation />} />
                        <Route path='/resetPassword' render={() => <ResetPassword />} />
                        <Route path='/changePassword' render={() => <ChangePassword />} />
                        <Route path='/privacy' render={() => <PrivacyPage />} />
                    </Grid>
                </Suspense>
            )
        }
    }
}
const mapStateToProps = (state) => {
    return {
        curId: state.curRelationId.currentId,
        data: state.userData,
        curElem: state.header.sectionName,
        unreadedMessages: state.chats.unreadedMessages,
        currentEmail: state.userData?.data?.data?.user?.loginEmail,
        selectedChat: state.chats?.selectedChat,
    };
};


const ConnectedApp = withRouter(connect(mapStateToProps,
    {
        setUser,
        setCurrentId,
        isAuth,
        setItemName,
        userLogin,
        setCurrentMainUserId,
        setSectionName,
        clearUnreadMessagesArray,
        setChatType,
        setSelectedChat,
        resetUnreadMessagesCount,
        setUserLastView,
        findProposalsBetweenTwoUsers,
        setSelectedChatEmail,
        setInviteLink,
        setIsNewUser,
    })(withStyles(styles)(App)));


export { ConnectedApp };
