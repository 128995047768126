import React, { useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { red, green, blue } from '@material-ui/core/colors';
import clsx from 'clsx';

const styles = theme => createStyles({
  alert: {
    minHeight: '36px',
    border: 'none',
    padding: '6px',
    color: '#4a4f55',
    borderRadius: '0',
    boxSizing: 'border-box',
    boxShadow: '2px 5px 5px rgba(218, 216, 215, 0.6)',
    minWidth: '500px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      minWidth: '320px',
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
  },
  icon: {
    display: 'flex',
    paddingRight: '10px',
  },
  error: {
    background: '#f1cecc',
  },
  success: {
    background: '#ddf6ce',
  },
  info: {
    background: '#64b5f6',
  }
});

const AlertCustom = (props) => {
    const { classes, status, title } = props;
    const [open, setOpen] = useState(true);
    let iconType;

    const defineIconType = () => {
      switch(status) {
        case 'error':
          iconType = <ErrorIcon style={{ color: red[800] }}/>
          break;
        case 'success':
          iconType = <CheckCircleIcon style={{ color: green[800] }}/>
          break;
        case 'info':
          iconType = <InfoIcon style={{ color: blue[800] }} />
          break;
      }
      return iconType;
    }

    return (
      <div className={clsx(classes.alert, classes[status])}>
        <div className={classes.titleWrapper}>
          <span className={classes.icon}>
            {defineIconType()}
          </span>
          {title}
        </div>

      </div>
    )
}

export default  withStyles(styles)(AlertCustom);