import axios from "axios";
import { data } from "jquery";
import instance from "../../axios/axiosInstance";

const SET_WORKS_GROUP = 'SET_WORKS_GROUP';
const SET_CURRENT_WORK_ID = 'SET_CURRENT_WORK_ID';
const SET_WORKS = 'SET_WORKS';
const SET_CUR_WORKS = 'SET_CUR_WORKS';
const SET_SORT_WORKS_GROUP = 'SET_SORT_WORKS_GROUP';

let initialState = {
    data: [],
    currentId:'',
    curWorks: [],
    selectedWorks: []
};

const worksReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case SET_WORKS_GROUP: { 
            return {
                ...state, data: action.data
            }
        }
        case SET_CURRENT_WORK_ID: {
            return {
                ...state, currentId: action.currentId
            }
        }

        case SET_WORKS: {
            return {
                ...state, curWorks: action.data
            }
        }

        case SET_CUR_WORKS: {
            return {
                ...state, selectedWorks: action.data
            }
        }

        case SET_SORT_WORKS_GROUP: {
            const dataCopy = [...state.data.data];
            const el = dataCopy.findIndex(item => item.id = action.id);
            console.log(dataCopy);
            if (el !== -1) {
                dataCopy[el].works = action.arr;
            }
            console.log(action.arr, dataCopy[el].works)
            return {
                ...state,
                data: { data: dataCopy },
            }
        }

        default: {
            return state
        }
    }
}
export default worksReducer;


export const setCurWorks = (data) => ({ type: SET_CUR_WORKS, data });
export const setGroupWorks = (data) => ({ type: SET_WORKS_GROUP, data });
export const setWorks = (data) => ({ type: SET_WORKS, data });
export const setCurrentWorkId = (currentId) => ({ type: SET_CURRENT_WORK_ID, currentId });
export const setSortWorksGroup = (id, arr) => ({ type: SET_SORT_WORKS_GROUP, id, arr })

export const createWorkGroup = (currentRelationId, works, data) => (dispatch, getState) => {

    return instance.post(`relation/${currentRelationId}/workGroup`,
        {
            orderWorks: works,
            workGroupName: data.name
        })
}

export const updateWorkGroup = (works, data, name) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId?.currentId;
    instance.put(`relation/${currentRelationId}/workGroup`,
        {
            id: data.id,
            orderWorks: works,
            workGroupName: name
        })
        .then((res) => {
            !res.data.code?
            alert("Группа успешно отредактирована!") :
            alert(`Error Code:${res.data.code} - ${res.data.message}`);
        })
        .then(() => {
            dispatch(getGroupWorks(currentRelationId));
        })
}




export const deleteWorkGroup = (currentRelationId, workGroupId) => (dispatch, getState) => {
    return instance.delete(`relation/${currentRelationId}/workGroup/${workGroupId}`)
}

export const getGroupWorks = (currentRelationId) => (dispatch, getState) => {
    if(currentRelationId){
    instance.get(`relation/${currentRelationId}/workGroup/listByRelationId?perPage=${999}&page=${0}`)
        .then((result) => {
            dispatch(setGroupWorks(result)); 
        })
        .catch(err => console.log(err))
    }
}