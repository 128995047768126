import 'react-app-polyfill/ie11';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import {ConnectedApp} from './App';
import './index.css';
import { Provider } from 'react-redux';
import { AlertProvider } from './hoc/AlertProvider.js';
import AlertContainer  from './components/alert/AlertContainer';
import { store } from './redux/combineReducer';
import ErrorBoundary from './errors/ErrorBoundary';
import WebSocket from './messenger/WebSocket.js';

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <div className={'notificationsWrapper'}>
                <AlertProvider>
                    <div className={'notificationsContainer'}>
                        <AlertContainer />
                    </div>
                        <ConnectedApp/>
                        <WebSocket />
                </AlertProvider>
            </div>
        </Provider>
    </BrowserRouter>
    , document.getElementById('root')
);