
import { togleIsFetching } from "./preloaderReducer";
import instance from "../../axios/axiosInstance";
import axios from 'axios';
import { getAllSpecializations } from "./specialisationsReducer";
import { setErrors } from './errorsReducer';
import { getUnauthInstance } from '../../axios/axiosInstance';
import { environments } from '../../constants/constants';
import { data } from "jquery";
import Cookies from "universal-cookie";

const SET_OBJECTS = 'SET_OBJECTS';
const SET_OBJECT_DATA = "SET_OBJECT_DATA";
const SET_OBJECT_ID = "SET_OBJECT_ID";
const SET_CURRENT_OBJECT_ID = "SET_CURRENT_OBJECT_ID";
const SET_CURRENT_OBJECT_ROLES = "SET_CURRENT_OBJECT_ROLES";
const SET_OBJECT_OWNER_FLAG = "SET_OBJECT_OWNER_FLAG";
const SET_ELEMENT_DATA = "SET_ELEMENT_DATA";
const SET_OBJECT_TAB_INDEX = 'SET_OBJECT_TAB_INDEX';
const SET_ATTACHMENT_DATA = 'SET_ATTACHMENT_DATA';
const SET_OPEN_PUBLICATION_FORM = 'SET_OPEN_PUBLICATION_FORM';
const SET_ELEMENT_ID = 'SET_ELEMENT_ID';
const SET_CURRENT_ELEMENT_ID = 'SET_CURRENT_ELEMENT_ID';
const SET_MODAL_ACTIVE_FLAG = 'SET_MODAL_ACTIVE_FLAG';
const SET_ELEMENT_ATTACHMENT_DATA = 'SET_ELEMENT_ATTACHMENT_DATA';
const SET_OBJECT_TYPES = 'SET_OBJECT_TYPES';
const SET_UPLOAD_AVATAR = 'SET_UPLOAD_AVATAR';
const SET_REFRESHED_OBJECTS_STORE = 'SET_REFRESHED_OBJECTS_STORE';
const SET_OBJECT_AVATAR = 'SET_OBJECT_AVATAR';
const SET_OBJECTS_LOADING_ATTACHMENTS = 'SET_OBJECTS_LOADING_ATTACHMENTS';
const SET_OBJECTS_DELETED_ATTACHMENTS = 'SET_OBJECTS_DELETED_ATTACHMENTS';
const SET_OBJECT_ATTACHMENT_URL = 'SET_OBJECT_ATTACHMENT_URL';
const SET_ELEMENT_ATTACHMENT_URL = 'SET_ELEMENT_ATTACHMENT_URL';
const SET_PREVENTED_REQUEST_LIST = 'SET_PREVENTED_REQUEST_LIST';
const SET_PREVENTED_REQUEST_ELEMENT_LIST = 'SET_PREVENTED_REQUEST_ELEMENT_LIST';
const SET_ELEMENTS_LOADING_ATTACHMENTS = 'SET_ELEMENTS_LOADING_ATTACHMENTS';
const SET_ELEMENTS_DELETED_ATTACHMENTS = 'SET_ELEMENTS_DELETED_ATTACHMENTS';
const SET_CURRENT_ORDER_AND_RELATIOND_ID = 'SET_CURRENT_ORDER_AND_RELATIOND_ID';
const SET_OBJECT_EXPIRED_STATUS = 'SET_OBJECT_EXPIRED_STATUS';
const SET_CANCELED_OBJECT_STATUS = 'SET_CANCELED_OBJECT_STATUS';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

let initialState = {
    objects: {},
    data: {},
    objectId: null,
    currentOrderAndRelationId: null,
    currentObjectId: {},
    currentObjectRoles: {},
    objectOwner: {},
    elementData: {},
    tabIndex: 0,
    formFlag: false,
    objectTypes: [],
};

const objectsReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_OBJECTS: {
            const { data } = action.data;
            const envInstance = process.env.REACT_APP_ENVIRONMENT === environments.production ? 'https://bilor.pro:' : 'https://bilor.cloud:';
            const objectsListWithAvatars = data.map(obj => {
                const objectId = obj.id;
                obj.avatar ? obj.imageSrc = `${envInstance}8765/object/${objectId}/avatar` : obj.imageSrc = null;
                return obj;
            })

            return {
                ...state, 
                objects: action.data,
                objects: {
                    data: objectsListWithAvatars,
                }
            }
        }

        case SET_OBJECT_DATA: {
            let id = '';
            if (action.data?.objectModel) {
                id = action.data?.objectModel.id;
            }
            
            return {
                ...state, 
                data: {
                    ...state.data,
                    ...action.data,
                    objectModel: {
                        ...action.data?.objectModel,
                        imageSrc: `https://bilor.pro:8765/object/${id}/avatar`,
                    }
                },
            }
        }

        case SET_ELEMENT_DATA: {
            const { objectId } = action.data?.data?.elementModel;
            return {
                ...state, objectId, elementData: action.data
            }
        }

        case SET_ELEMENT_ID: {
            return state.elementId && state.elementId !== action.data ? {
                ...state, 
                elementId: action.data,
                elementAttachments: []
            } : {...state}
        }

        case SET_ATTACHMENT_DATA: {
            let existAttachments;
            let attachmentsCopy;
            if (action.data?.objectId !== state.objectId) {
                return {...state};
            }
            if (state.attachments?.data) {
                existAttachments = [...state.attachments.data];
                attachmentsCopy = action.data?.data;
                attachmentsCopy.forEach(attachment => {
                    const sameAttachment = existAttachments.find(existAttachment => existAttachment.id === attachment.id);
                    if (sameAttachment && sameAttachment.attachmentSrc) {
                        attachment.attachmentSrc = sameAttachment.attachmentSrc;
                    }
                })
            }
            const equals = state?.attachments?.data?.length - action.data?.data?.length;
            if (!equals || equals <= 0) {
                return {
                    ...state, attachments: action.data
                }
            } else {
                const loadedAttachments = attachmentsCopy.concat(Array(equals).fill({ isAttachmentLoading: true }));
                return {
                    ...state,
                    attachments: {
                        ...state.attachments,
                        data: loadedAttachments,
                    }
                }
            }
        }

        case SET_ELEMENT_ATTACHMENT_DATA: {
            let existAttachments;
            let attachmentsCopy;
            if (state.elementAttachments?.data) {
                existAttachments = [...state.elementAttachments.data];
                attachmentsCopy = action.data?.data;
                const isSameAttachments = existAttachments.some(existAttachment => {
                    return attachmentsCopy.find(attachment => {
                        return existAttachment.id === attachment.id
                    })
                });
                if (!isSameAttachments) {
                    return {
                        ...state,
                        elementAttachments: action.data,
                    }
                }
                attachmentsCopy.forEach(attachment => {
                    const sameAttachment = existAttachments.find(existAttachment => existAttachment.id === attachment.id);
                    if (sameAttachment && sameAttachment.attachmentSrc) {
                        attachment.attachmentSrc = sameAttachment.attachmentSrc;
                    }
                });
            }
            const equals = state?.elementAttachments?.data?.length - action.data?.data?.length;
            if (!equals || equals <= 0) {
                return {
                    ...state, elementAttachments: action.data
                }
            } else {
                const loadedAttachments = attachmentsCopy.concat(Array(equals).fill({ isAttachmentLoading: true }));
                return {
                    ...state,
                    elementAttachments: {
                        ...state.elementAttachments,
                        data: loadedAttachments,
                    }
                }
            }
        }

        case SET_OBJECT_ID: {
            return state.objectId !== action.data ? {
                ...state, 
                objectId: action.data,
                attachments: []
            } : {...state}
        }

        case SET_CURRENT_OBJECT_ID: {
            return {
                ...state, currentObjectId: action.data
            }
        }

        case SET_CURRENT_OBJECT_ROLES: {
            return {
                ...state, currentObjectRoles: action.data
            }
        }

        case SET_OBJECT_OWNER_FLAG: {
            return {
                ...state, objecttabOwner: action.data
            }
        }

        case SET_OBJECT_TAB_INDEX: {
            return {
                ...state, tabIndex: action.index
            }
        }
        
        case SET_OPEN_PUBLICATION_FORM: {
            return {
                ...state, formFlag: action.formFlag
            }
        }

        case SET_MODAL_ACTIVE_FLAG: {
            return {
                ...state, modalActive: action.data
            }
        }

        case SET_OBJECT_TYPES: {
            return {
                ...state, objectTypes: action.objectTypes
            }
        }

        case SET_UPLOAD_AVATAR: {
            return {
                ...state, uploadAvatarFlag: action.data
            }
        }

        case SET_REFRESHED_OBJECTS_STORE: {
            return initialState
        }

        case SET_OBJECTS_LOADING_ATTACHMENTS: {
            let attachmentsData = [];
            const attachmentsLoading = [action.data];
            if (state.attachments?.data) {
                attachmentsData = [...state.attachments.data].concat(attachmentsLoading);
            }

            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    data: attachmentsData,
                }
            }
        }

        case SET_ELEMENTS_LOADING_ATTACHMENTS: {
            const attachmentsLoading = [action.data];
            const attachmentsData = state.elementAttachments ? [...state.elementAttachments.data].concat(attachmentsLoading) : [action.data];

            return {
                ...state,
                elementAttachments: {
                    ...state.elementAttachments,
                    data: attachmentsData,
                }
            }
        }

        case SET_OBJECTS_DELETED_ATTACHMENTS: {
            const attachmentsCopy = [...state.attachments.data];
            const indexToDelete = attachmentsCopy.findIndex(attachment => attachment.id === action.data);
            if (indexToDelete !== -1) {
                attachmentsCopy.splice(indexToDelete, 1);
            }

            // let countLoadedAttachments = attachmentsCopy.filter(attachment => attachment.isAttachmentLoading);

            // const attachmentsWithLoading = attachmentsCopy.concat(Array(countLoadedAttachments.length).fill({ isAttachmentLoading: true }));
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    data: attachmentsCopy,
                }
            }
        }

        case SET_ELEMENTS_DELETED_ATTACHMENTS: {
            const attachmentsCopy = [...state.elementAttachments.data];
            const indexToDelete = attachmentsCopy.findIndex(attachment => attachment.id === action.data);
            if (indexToDelete !== -1) {
                attachmentsCopy.splice(indexToDelete, 1);
            }
            return {
                ...state,
                elementAttachments: {
                    ...state.elementAttachments,
                    data: attachmentsCopy,
                }
            }
        }

        case SET_OBJECT_ATTACHMENT_URL: {
            const { data, id } = action;
            let attachmentsCopy;
            let attachmentPositionToSet;
            if (state.attachments?.data?.length) {
                attachmentsCopy = [...state.attachments.data];
                attachmentPositionToSet = attachmentsCopy.findIndex(attachment => attachment.id === id);
            }
            if (attachmentPositionToSet !== -1 && attachmentsCopy) {
                attachmentsCopy[attachmentPositionToSet].attachmentSrc = data;
            }

            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    data: attachmentsCopy,
                },
            }
        }

        case SET_ELEMENT_ATTACHMENT_URL: {
            const { data, id } = action;
            let attachmentsCopy;
            let attachmentPositionToSet;
            if (state.elementAttachments?.data?.length) {
                attachmentsCopy = [...state.elementAttachments.data];
                attachmentPositionToSet = attachmentsCopy.findIndex(attachment => attachment.id === id);
            }
            if (attachmentPositionToSet !== -1 && attachmentsCopy) {
                attachmentsCopy[attachmentPositionToSet].attachmentSrc = data;
            }
            return  {
                ...state,
                elementAttachments: {
                    ...state.elementAttachments,
                    data: attachmentsCopy,
                }
            }
        }

        case SET_PREVENTED_REQUEST_LIST: {
            const attachmentsCopy = state.attachments.data;
            attachmentsCopy.splice(action.index, 1);
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    data: attachmentsCopy,
                }
            }
        }

        case SET_PREVENTED_REQUEST_ELEMENT_LIST: {
            const attachmentsCopy = [...state.elementAttachments.data];
            if (attachmentsCopy) {
                attachmentsCopy.splice(action.index, 1);
            }
            return {
                ...state,
                elementAttachments: {
                    ...state.elementAttachments,
                    data: attachmentsCopy,
                }
            }
        }

        case SET_CURRENT_ELEMENT_ID: {
            return {
                ...state,
                data: {
                    ...state.data,
                    currentElementId: action.data,
                }
            }
        }

        case SET_CURRENT_ORDER_AND_RELATIOND_ID: {
            return {
                ...state,
                currentOrderAndRelationId: action.data,
            }
        }

        case SET_OBJECT_EXPIRED_STATUS: {
            return {
                ...state,
                isExpired: action.data ? true : false,
            }
        }

        case SET_CANCELED_OBJECT_STATUS: {
            const objectsArrCopy = [...state.objects.data];
            const currentObj = objectsArrCopy.find(obj => obj.id === action.data);
            currentObj.subscriptionStatus = 'CANCELED';
            return {
                ...state,
                objects: {
                    data: objectsArrCopy,
                }
            }
        }

        default: {
            return state
        }
    }
}
export default objectsReducer;



export const setObjects = (data) => ({ type: SET_OBJECTS, data });

export const setObjectData = (data) => ({ type: SET_OBJECT_DATA, data });

export const setElementData = (data) => ({ type: SET_ELEMENT_DATA, data });

export const setElementId = (data) => ({ type: SET_ELEMENT_ID, data });

export const setCurrentElementId = (data) => ({ type: SET_CURRENT_ELEMENT_ID, data });

export const setAttachmentData = (data) => ({ type: SET_ATTACHMENT_DATA, data });

export const setElementAttachmentData = (data) => ({ type: SET_ELEMENT_ATTACHMENT_DATA, data });

export const setObjectId = (data) => ({ type: SET_OBJECT_ID, data });

export const setCurrentObjectId = (data) => ({ type: SET_CURRENT_OBJECT_ID, data });

export const setCurrentObjectRoles = (data) => ({ type: SET_CURRENT_OBJECT_ROLES, data });

export const setObjecOwnerFlag = (data) => ({ type: SET_OBJECT_OWNER_FLAG, data });

export const setObjectTabIndex = (index) => ({ type: SET_OBJECT_TAB_INDEX, index });

export const setModalActiveFlag = (data) => ({ type: SET_MODAL_ACTIVE_FLAG, data });

export const setObjecTypes = (objectTypes) => ({ type: SET_OBJECT_TYPES, objectTypes });

export const setUploadAvatarFlag = (data) => ({ type: SET_UPLOAD_AVATAR, data });

export const setRefreshedObjectsStore = (data) => ({ type: SET_REFRESHED_OBJECTS_STORE, data });

export const setObjectsLoadingAttachments = (data) => ({ type: SET_OBJECTS_LOADING_ATTACHMENTS, data });

export const setElementsLoadingAttachments = (data) => ({ type: SET_ELEMENTS_LOADING_ATTACHMENTS, data });

export const setObjectsDeletedAttachments = (data) => ({ type: SET_OBJECTS_DELETED_ATTACHMENTS, data });

export const setElementsDeletedAttachments = (data) => ({ type: SET_ELEMENTS_DELETED_ATTACHMENTS, data });

export const setObjectAttachmentURL = (data, id) => ({ type: SET_OBJECT_ATTACHMENT_URL, data, id });

export const setElementAttachmentURL =(data, id) => ({ type: SET_ELEMENT_ATTACHMENT_URL, data, id })

export const setPreventRequestLoading = (index) => ({ type: SET_PREVENTED_REQUEST_LIST, index });

export const setPreventRequestElementLoading = (index) => ({ type: SET_PREVENTED_REQUEST_ELEMENT_LIST, index });

export const setCurrentOrderAndRelationId = (data) => ({ type: SET_CURRENT_ORDER_AND_RELATIOND_ID, data });

export const setObjectExpiredStatus = (data) => ({ type: SET_OBJECT_EXPIRED_STATUS, data });

export const setObjectCancelStatus = (data) => ({ type: SET_CANCELED_OBJECT_STATUS, data });

// export const setObjectAvatar = (data) => ({ type: SET_OBJECT_AVATAR, data });


export const findObjectById = (currentOrderAndRelationId, objectId) => (dispatch, getState) => {
    console.log(objectId);
    dispatch(togleIsFetching(true))
    instance.get(`orderAndRelation/${currentOrderAndRelationId}/object/${objectId}`)
        .then((result) => {
            dispatch(togleIsFetching(false));
            result.data.objectModel &&
                dispatch(setObjectId(result.data.objectModel.id));
            dispatch(setObjectData(result.data));
            dispatch(setObjecOwnerFlag(result.data.meOwner));
            dispatch(setCurrentObjectRoles(result.data.orderAndRelation.role));
        })
        .catch(err => {
            dispatch(togleIsFetching(false));
            // dispatch(setErrors(true));
        })
}

export const createObject = (currentRelationId, data) => (dispatch, getState) => {
    
    switch (data.objectType) {
        case "Квартира": data.objectType = "FLAT"; 
            break;
        case 'Офис "S"': data.objectType = "COMMERCIAL_S";
            break;
        case "Дом": data.objectType = "HOUSE"; 
            break;
        case 'Офис "XL"': data.objectType = "COMMERCIAL_XL"; 
            break;
    }
    data.ownerRelationId = currentRelationId;
    console.log(data);
    return instance.post(`relation/${currentRelationId}/object`, data)
}

export const updateObjectList = (currentOrderAndRelationId, currentRelationId, data) => (dispatch, getState) => {
    switch (data.objectType) {
        case "Квартира": data.objectType = "FLAT"; 
            break;
        case 'Офис "S"': data.objectType = "COMMERCIAL_S";
            break;
        case "Дом": data.objectType = "HOUSE"; 
            break;
        case 'Офис "XL"': data.objectType = "COMMERCIAL_XL"; 
            break;
    }

    data.ownerRelationId = currentRelationId;
    return instance.put(`orderAndRelation/${currentOrderAndRelationId}/object`, data);
}

export const updateObject = (currentOrderAndRelationId, data, editValue, editField, elementId) => (dispatch, getState) => {
    let objectType
    if (editField == 'type') {
        switch (editValue) {
            case "Квартира": editValue = "FLAT"; 
                break;
            case 'Офис "S"': editValue = "COMMERCIAL_S";
                break;
            case "Дом": editValue = "HOUSE"; 
                break;
            case 'Офис "XL"': editValue = "COMMERCIAL_XL"; 
                break;
        }
    } else {
        switch (data.objectType) {
            case "Квартира": editValue = "FLAT"; 
                break;
            case 'Офис "S"': editValue = "COMMERCIAL_S";
                break;
            case "Дом": editValue = "HOUSE"; 
                break;
            case 'Офис "XL"': editValue = "COMMERCIAL_XL"; 
                break;
        };
    }
    instance.put(`orderAndRelation/${currentOrderAndRelationId}/object`, {
        "id": data.id,
        "objectCountry": editField=='country' ? editValue : data.objectCountry,
        "objectCity": editField=='city' ? editValue : data.objectCity,
        "objectAddress": editField=='address' ? editValue : data.objectAddress,
        "objectDescription": editField=='description' ? editValue : data.objectDescription,
        "objectName": editField=='name' ? editValue : data.objectName,
        "objectType": objectType,
        "ownerRelationId": data.ownerRelationId
    })
        .then((result) => {
            dispatch(findObjectById(currentOrderAndRelationId, data.id))
            dispatch(getObjectsByCurrentRelationId());
        })
        .catch(err => console.log(err))
}

export const updateElement = (currentRelationId, data, editValue, editField, elementId) => (dispatch, getState) => {
    instance.put(`orderAndRelation/${currentRelationId}/element`, {
        "id": data.id,
        "elementAddress": data.elementAddress,
        "elementDescription": editField=='description' ? editValue : data.elementDescription,
        "attachmentsIds": data.attachmentsIds,
        "elementName": editField=='elementName' ? editValue : data.elementName,
        "elementParametersType": data.elementParametersType,
        "elementType": data.elementType,
        "finishedTasks": 0,
        "mainElementId": data.mainElementId,
        "objectId": data.objectId,
        "subElementList": data.subElementList
    })
        .then((result) => {
            dispatch(getElementData(currentRelationId, data.id))
        })
        .catch(err => console.log(err))
}

// export const addSubElement = (currentRelationId, data, editValue, editField, elementId) => (dispatch, getState) => {

//     instance.post(`relation/${currentRelationId}/element/sub/add`, {
//         "elementAddress": data.address,
//         "elementDescription": data.description,
//         "elementName": data.name,
//         "elementType": data.type,
//         "objectId": this.props.objectId,
//     })
//         .then((result) => {
//             dispatch(getElementData(currentRelationId, data.id))
//         })
//         .catch(err => alert(err))
// }




export const uploadAttachment = (currentOrderAndRelationId, objectId, formData, options) => (dispatch, getState) => {
    dispatch(setObjectsLoadingAttachments({ 
        isAttachmentLoading: true, 
        key: options.key 
    }));
    return instance.post(`orderAndRelation/${currentOrderAndRelationId}/object/attachment/uploadByObjectId/${objectId}?attachmentName=image`,
        formData,
        options,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    //.then (()=>dispatch(getActData(curId)))
}

export const uploadAvatar = (objectId, formData) => (dispatch, getState) => {
    const currentOrderAndRelationId = getState().objects?.data?.orderAndRelation?.id;
    instance.post(`/orderAndRelation/${currentOrderAndRelationId}/object/${objectId}/avatar`,
        formData,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then(res => {
            !res.data.code &&
                dispatch(setUploadAvatarFlag(true));
        })
        .catch(err => console.log(err))
    //.then (()=>dispatch(getActData(curId)))
}

export const deleteAttachment = (currentOrderAndRelationId, objectId, elementAttachmentId) => (dispatch, getState) => {
    return instance.delete(`orderAndRelation/${currentOrderAndRelationId}/object/attachment/${elementAttachmentId}`,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .catch(err => console.log(err))
    //.then (()=>dispatch(getActData(curId)))
}

export const uploadElementAttachment = (currentOrderAndRelationId, elementId, formData, options) => (dispatch, getState) => {
    dispatch(setElementsLoadingAttachments({ isAttachmentLoading: true }));
    return instance.post(`orderAndRelation/${currentOrderAndRelationId}/element/attachment/uploadByElementId/${elementId}?attachmentName=image`,
        formData,
        options,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .catch(err => console.log(err))
    //.then (()=>dispatch(getActData(curId)))
}

export const deleteElementAttachment = (currentOrderAndRelationId, elementId, elementAttachmentId) => (dispatch, getState) => {
    return instance.delete(`orderAndRelation/${currentOrderAndRelationId}/element/attachment/${elementAttachmentId}`,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .catch(err => console.log(err))
    //.then (()=>dispatch(getActData(curId)))
}

export const getElementData = (currentRelationId, elementId) => (dispatch, getState) => {
    instance.get(`orderAndRelation/${currentRelationId}/element/${elementId}`)
        .then((result) => {
            dispatch(setElementId(result?.data?.elementModel?.id))
            dispatch(setElementData(result))
        })
        .catch(err => dispatch(setErrors(true)));
}

export const listByObjectId = (currentOrderAndRelationId, objectId) => (dispatch, getState) => {
    console.log('objectId:', objectId)
    instance.get(`orderAndRelation/${currentOrderAndRelationId}/object/attachment/listByObjectId/${objectId}`)
        .then((result) => {
        dispatch(setAttachmentData({...result, objectId}));
        })
        .catch(err => dispatch(setErrors(true)));
}

export const findAttachmentsByElementId = (currentRelationId, elementId) => (dispatch, getState) => {
    console.log(currentRelationId, elementId);
    instance.get(`orderAndRelation/${currentRelationId}/element/attachment/listByElementId/${elementId}`)
        .then((result) => {
            dispatch(setElementAttachmentData(result));
        })
        .catch(err => dispatch(setErrors(true)))
}

export const getObjectsByCurrentRelationId = (city, country, name, objectType,createdFrom, createdTo, objectStatus) => (dispatch, getState) => {
    dispatch(togleIsFetching(true));
    const cookies = new Cookies();
    const currentRelationId = getState().curRelationId.currentId || cookies.get('currentRelation');

    instance.get(`relation/${currentRelationId}/object/listByCurrentRelationId`,{
        params: {
            city: city,
            country: country,
            name: name,
            objectType: objectType,
            createdFrom: createdFrom,
            createdTo: createdTo,
            objectStatus: objectStatus,
        }
    })
        .then((result) => {
            dispatch(togleIsFetching(false));
            dispatch(setObjects(result))
        })
        .catch(err => {
            dispatch(togleIsFetching(false));
            dispatch(setErrors(true));
        })
}

export const getObjectsByRelId = () => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    instance.get(`relation/${currentRelationId}/object/listByCurrentRelationId`)
        .then((result) => {
            dispatch(setObjects(result));
        })
        .catch(err => setErrors(true))
}


export const setOpenPublicationForm = (formFlag) => (dispatch, getState) => {
    const curId = getState().curRelationId.currentId;
    
    dispatch({ type: SET_OPEN_PUBLICATION_FORM, formFlag });
    formFlag === true &&
    dispatch(getAllSpecializations(curId))
}

export const getObjectTypes = () => (dispatch, getState) => {
    
    instance.get(`object/type/list`)
    .then((res)=> res.data.code ?
        console.log(`${res.data.code}: ${res.data.mesage}`) :
        dispatch(setObjecTypes(res.data))
    )
    .catch(err => console.log(err))
}

export const inviteExecutorPerObjectByEmail = (executorEmail, orderAndRel, objId, message) => (dispatch, getState) => {
    const objectId = getState().objects?.objectId || objId;
    return instance.put(`/orderAndRelation/${orderAndRel}/object/${objectId}/invite?executorEmail=${executorEmail}&message=${message}`)
}

export const receiveMessageForObjectInvitation = (curId, objectId, login, encodedData) => (dispatch, getState) => {
   return getUnauthInstance(encodedData).put(`relation/${curId}/object/${objectId}/invite/message?invitorLogin=${login}`)
}