import instance from "../../axios/axiosInstance";
import findAllChatsByRelationId from "./chatsReducer";

const SET_VACANCIES_LIST = 'SET_VACANCIES_LIST';
const SET_VACANCIES_BY_MASTER = 'SET_VACANCIES_BY_MASTER';
const DELETE_VACANCY = 'DELETE_VACANCY';
const UPDATE_USER_VACANCY_RELATION = 'UPDATE_USER_VACANCY_RELATION';

let initialState = {
  vacanciesByMaster: [],
  allVacancies: [],
};

const vacancyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VACANCIES_LIST: {
      return {
        ...state, allVacancies: action.data
      }
    }
    case SET_VACANCIES_BY_MASTER: {
      return {
        ...state, vacanciesByMaster: action.data
      }
    }
    case DELETE_VACANCY: {
      return {
        ...state, 
        vacanciesByMaster: state.vacanciesByMaster.filter((v) => {
          return (v.id !== action.vacancyId)
        })
      }
    }
    case UPDATE_USER_VACANCY_RELATION: {
      const { vacancyIndexToUpdate, updatedRelation } = action.payload;
      const updatedAllVacancies = [...state.allVacancies];
      updatedAllVacancies[vacancyIndexToUpdate].respondersRelations[0] = updatedRelation;
      return {
        ...state,
        allVacancies: updatedAllVacancies,
      }
    }
    default: {
      return state

    }
  }
}
export default vacancyReducer;

export const setAllVacancies = (data) => ({ type: SET_VACANCIES_LIST, data });
export const setVacanciesByMaster = (data) => ({ type: SET_VACANCIES_BY_MASTER, data });
export const deleteReduxVacancy = (vacancyId) => ({ type: DELETE_VACANCY, vacancyId });
export const updateUserRelation = (vacancyIndexToUpdate, updatedRelation) => ({ type: UPDATE_USER_VACANCY_RELATION, payload: { vacancyIndexToUpdate, updatedRelation }});


export const deleteVacancy = (vacancyId) => (dispatch, getState) => {
  const curRelId = getState().curRelationId.currentId;
  instance.delete(`relation/${curRelId}/vacancy/${vacancyId}`)
    .then(res => {
      res.data.code ?
        alert(`Error Code:${res.data.code} - ${res.data.message}`) :
        dispatch(deleteReduxVacancy(vacancyId))
    })
    .catch(err => alert(err))
  // .then (()=>dispatch(getActData(curId)))
};


export const createVacancy = (data) => (dispatch, getState) => {

  const curRelId = getState().curRelationId.currentId;
  instance.post(`relation/${curRelId}/vacancy`,
    {
      "name": data.vacancyName,
      "description": data.description,
      "specializations": data.reactSelect?.map(spec => spec.value),
      "contactEmail": data.contactEmail,
      "contactPhone": data.contactPhone,

    })
    .then(res => {
      console.log(res)
      res.data.code &&
        console.log(`Error Code:${res.data.code} - ${res.data.message}`)
    })
    .catch(err => alert(err))
    .then(() => dispatch(getVacanciesByMaster()))
};

export const updateVacancy = (data) => (dispatch, getState) => {
  const curRelId = getState().curRelationId.currentId;

  instance.put(`relation/${curRelId}/vacancy`,
    {
      "id": data.id,
      "name": data.vacancyName,
      "description": data.description,
      "specializations": data.reactSelect?.map(spec => spec.value),
      "contactEmail": data.contactEmail,
      "contactPhone": data.contactPhone,
    })
    .then(res => {
      console.log(res)
      res.data.code &&
        console.log(`Error Code:${res.data.code} - ${res.data.message}`)
    })
    .catch(err => alert(err))
    .then(() => dispatch(getVacanciesByMaster()))
};

export const getAllVacancies = (name, country, city, specialization, sortCriteria, order) => (dispatch, getState) => {
  const currentRelationId = getState().curRelationId?.currentId;

  currentRelationId &&
    instance.get(`relation/${currentRelationId}/vacancy/list`, {
      params: {
        city: city,
        country: country,
        specialization: specialization,
        name: name,
        sortCriteria: sortCriteria,
        sortDirection: order
      }
    })
      .then(res => {
        res.data.code ?
          console.log(`Error Code:${res.data.code} - ${res.data.message}`) :
          dispatch(setAllVacancies(res.data))
      })
      .catch(err => alert(err))
  // .then (()=>dispatch(getActData(curId)))
};


export const getVacanciesByMaster = (name, specialization, sortCriteria, order) => (dispatch, getState) => {
  const curRelId = getState().curRelationId?.currentId;
  if (curRelId) {
    instance.get(`relation/${curRelId}/vacancy/listByMaster`, {
      params: {
        specialization: specialization,
        name: name,
        sortCriteria: sortCriteria,
        sortDirection: order
      }
    })
      .then(res => {
        res.data.code ?
          console.log(`Error Code:${res.data.code} - ${res.data.message}`) :
          console.log(res.data);
          dispatch(setVacanciesByMaster(res.data))
      })
      .catch(err => alert(err))
  }
  // .then (()=>dispatch(getActData(curId)))
};



export const respondToVacancy = (vacancyId, description) => (dispatch, getState) => {
  const currentRelationId = getState().curRelationId.currentId;

  instance.post(`relation/${currentRelationId}/vacancy/${vacancyId}/respond?description=${description}`
  )
    .then(res => {
      console.log(res)
      res.data.code &&
        console.log(`Error Code:${res.data.code} - ${res.data.message}`)
    })
    .catch(err => alert(err))
    .then(() => dispatch(getAllVacancies()))
};

export const updateHiringType = ({currentRelationId, offer, hiringType}) => (dispatch) => {
  return instance.put(`relation/${currentRelationId}`,
  {
    ...offer,
    hiringType
  })
  .then((res) => {
    if(!res.data.code) {
      dispatch(getVacanciesByMaster());
    } 
  })
}

export const updateVacanciesRelation = ({ currentRelationId, offer, mainUserStatus, userStatus, roleId }) => (dispatch, getState) => {
  return instance.put(`relation/${currentRelationId}`,
    {
      ...offer,
      mainUserRelationStatus: mainUserStatus || offer.mainUserRelationStatus,
      userRelationStatus: userStatus || offer.userRelationStatus,
      roleId: roleId,
    })
    .then(res => {
      !res.data.code ?
        dispatch(getVacanciesByMaster()) :
        console.log(res.data.message);
        return res;
    })
    .catch(err => console.log(err))
}

export const updateRelation = (currentRelationId, vacancyId, updatedStatus, mainUserStatus) => (dispatch, getState) => {
  const { allVacancies } = getState().vacancy;
  const vacancyIndexToUpdate = allVacancies.findIndex(item => {
    return item.id === vacancyId;
  });
  const updatedRelation = {
    ...allVacancies[vacancyIndexToUpdate]?.respondersRelations[0],
    userRelationStatus: updatedStatus,
    mainUserRelationStatus: mainUserStatus
  };

  instance.put(`relation/${currentRelationId}`, updatedRelation)
    .then(res => {
      dispatch(updateUserRelation(vacancyIndexToUpdate, updatedRelation))
  })
}