import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends Component {
    state = {
        hasError: false,
    }

    componentDidCatch(error, info) {
        // this.setState({
        //   hasError: true,
        // });
        console.log(error, info);
    }

    render() {
        const { classes } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            this.props.history.push('/error')
            window.location.reload();
        }
        return this.props.children;
    }
}

const mapStateToProps = () => {
    return {

    }
}

export default connect(mapStateToProps, {})(withRouter(ErrorBoundary));
