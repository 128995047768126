

const TOGLE_IS_FETCHING = 'TOGLE_IS_FETCHING';
const SET_INVITE_LINK = 'SET_INVITE_LINK';
const SET_IS_NEW_USER = 'SET_IS_NEW_USER';
const SET_INVITE_LINK_INFO = 'SET_INVITE_LINK_INFO';

let initialState = {
    isFetching: false,
    isInviteLink: '',
    isNewUser: true,
    inviteLinkInfo: {},
};

const preloaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGLE_IS_FETCHING: {
            return {
                ...state, isFetching: action.data
            }
        }
        case SET_INVITE_LINK: {
            return {
                ...state,
                isInviteLink: action.data,
            }
        }
        case SET_IS_NEW_USER: {
            return {
                ...state,
                isNewUser: action.data,
            }
        }
        case SET_INVITE_LINK_INFO: {
            return {
                ...state,
                inviteLinkInfo: action.data,
            }
        }
        default: {
            return state
        }
    }
}
export default preloaderReducer;

export const togleIsFetching = (data) => ({ type: TOGLE_IS_FETCHING, data });
export const setInviteLink = (data) => ({ type: SET_INVITE_LINK, data });
export const setIsNewUser = (data) => ({ type: SET_IS_NEW_USER, data });
export const setInviteLinkInfo = (data) => ({ type: SET_INVITE_LINK_INFO, data });










