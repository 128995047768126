import instance from "../../axios/axiosInstance";
import { getContractorsByObjectId } from "./progressReducer";
import { getUsersWithProposals } from "./usersListReducer"; 
import {
    getEmployeeRelation, 
    findProposalsBetweenTwoUsers
  } from './relationReducer'

let initialState = {
};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state
    }
}
export default ordersReducer;


export const createOrder = (curRelId, objectId, customerId, contractorId) => (dispatch, getState) => {
    const currentRelationId = curRelId || getState().curRelationId.currentId;
    return instance.post(`relation/${currentRelationId}/order`,
        {
            contractorId: contractorId,
            objectId: objectId,
            customerId: customerId,
            contractorOrderStatus: "ACCEPTED",
            customerOrderStatus: "ACCEPTED",
            orderStatus: "ACCEPTED",
            payments: [],
        })
        .catch((err) => { console.log(err) })
}


export const createOrderFromProposal = (proposal, customerId) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    console.log(proposal, customerId);
    return instance.post(`relation/${currentRelationId}/order`,
        {
            contractorId: proposal.contractorId,
            objectId: proposal.objectId,
            customerId: customerId,
            contractorOrderStatus: "ACCEPTED",
            customerOrderStatus: "ACCEPTED",
            orderStatus: "ACCEPTED",
            payments: [],
        })
}



export const updateOrder = (status, order) => (dispatch, getState) => {
    // orderStatuses:
    //  ACCEPTED;
    //  COMPLETED
    const currentRelationId = getState().curRelationId.currentId;
    instance.put(`relation/${currentRelationId}/order`,
        {
            ...order,
            orderStatus: status,
        })
        .then((result) => {
            result.data.code && console.log(result.data.message);
            dispatch(getContractorsByObjectId(currentRelationId, order.objectId))
        })
        .catch((err) => { console.log(err) })
}

export const createProposal = (contractorId, objectId, message) => (dispatch, getState) => {
    // statuses: REVIEW, ACCEPTED, DENIED

    const currentRelationId = getState().curRelationId.currentId;

    instance.post(`relation/${currentRelationId}/proposal`, {
        contractorId: contractorId,
        objectId: objectId,
        contractorStatus: "REVIEW",
        ownerStatus: "REVIEW",
        proposalMessage: message,
    })
        .then((result) => {
            result.data.code ?
                console.log(result.data.message) :
                dispatch(getUsersWithProposals())
        })
        .catch((err) => { console.log(err) })
}


export const updateProposalStatus = (proposalId, proposalStatus) => (dispatch, getState) => {
    // statuses: REVIEW, ACCEPTED, DENIED

    const currentRelationId = getState().curRelationId.currentId;

    return instance.put(`relation/${currentRelationId}/proposal/${proposalId}/status?status=${proposalStatus}`)
}

export const deleteProposal = (proposalId) => (dispatch, getState) => {

    const currentRelationId = getState().curRelationId.currentId;
    const currentChat = getState().chats.selectedChatEmail;

    instance.delete(`relation/${currentRelationId}/proposal/${proposalId}`)
        .then((result) => {
            result.data.code ? console.log(result.data.message) :
                dispatch(getUsersWithProposals())
        })
        .then(() => {
            dispatch(findProposalsBetweenTwoUsers(currentChat))
        })
        .catch((err) => { console.log(err) })
}



