import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect, useSelector, useDispatch } from 'react-redux';

import { sendSupportMessage } from '../../redux/reducers/usersReducer.js';

import EmailInput from './SupportEmailInput.js';
import TelInput from './SupportTelInput.js';
import MessageInput from './SupportMessageInput.js';

import { Grid, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import ButtonWithoutBorder from '../../components/buttons/ButtonWithoutBorder';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { wrapConsumer } from '../../hoc/AlertProvider';

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root:{
                '&:hover': {
                    '&$disabled': {
                        backgroundColor: '#B3C7F0',
                    }
                }
            }
        }
    }
  });

const styles = theme => createStyles({
    root: {
        fontSize: '14px',
        lineHeight: '19px',
        background: '#e6f1fc',
        boxShadow:' 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        width: '100%',
        maxWidth: '860px',
        padding: '30px 30px 30px 30px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
            padding: '25px',
            height: '100%',
            minHeight: 'fit-content'
        },
    },
    title: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#0D9CFC',
        marginBottom: '30px',
    },
    buttonsWrap: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    submitButton: {
        color: '#FFFFFF',
        height: '36px',
        border: 'none',
        cursor: 'pointer',
        padding: '0',
        fontSize: '14px',
        fontStyle: 'normal',
        background: '#004EE5',
        fontFamily: 'Lato',
        fontHeight: 'bold',
        lineeHight: '16px',
        borderRadius: '4px',
        padding: '10px 15px',
    }
});

const EmailForm = props => {
    const dispatch = useDispatch()
    const { classes, handleCancelBtn } = props;
    const { register, control, handleSubmit, setValue, errors, setError, clearError } = useForm({ mode: "onBlur" });

    const [textareaValue, setTextareaValue] = useState('');

    const onSubmit = data => {
        if (data.email || data.tel) {
            props.sendSupportMessage(data.email, data.tel, textareaValue);
            handleCancelBtn()
        } else {
            setError("email", {
                type: "manual",
                message: "Пожалуйста, заполните одно из полей"
            });
            setError("tel", {
                type: "manual2",
                message: "Пожалуйста, заполните одно из полей"
            })
        }
        console.log(errors)
    }

    const handleChange = (e) => {
        setTextareaValue(e.target.value);
    }

    return (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.title}>
                <span>Мы всегда на связи!</span>
            </div>
            <div className={classes.formInputsWrapper}>
                <EmailInput
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Введите ваш e-mail"
                    label="Введите ваш e-mail"
                    errors={errors}
                    register={register({
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    })}
                    setError={setError}
                    clearErrors={clearError}
                />
                <TelInput
                    id="tel"
                    name="tel"
                    type="number"
                    placeholder="Введите номер телефона (только цифры)"
                    label="и / или ваш номер телефона"
                    errors={errors}
                    register={register({
                        pattern: "[0-9]+",
                    })}
                    clearErrors={clearError}
                />
                <MessageInput
                    id="message"
                    name="message"
                    type="text"
                    placeholder="Введите сообщение"
                    label="Задайте нам вопрос"
                    errors={errors}
                    handleChange={handleChange}
                />
            </div>
            <MuiThemeProvider theme={theme}>
                <div className={classes.buttonsWrap}>
                    <ButtonWithoutBorder
                        title={'Отменить'}
                        style={{ marginRight: '6px' }}
                        onClick={handleCancelBtn}
                    />
                    <Button
                        className={classes.submitButton}
                        type='submit'
                    >
                        Отправить
                    </Button>
                </div>
            </MuiThemeProvider>
        </form>
    )
};

  const mapStateToProps = (state) => {
    return {
      curId: state.curRelationId.currentId,
    };
};

export default connect(
    mapStateToProps, 
{ sendSupportMessage })(wrapConsumer(withStyles(styles)(EmailForm)))