import axios from "axios";
import instance from "../../axios/axiosInstance";

const SET_EMPLOYERS_DATA = 'SET_EMPLOYERS_DATA';
const CUR_EMP_FILTER_SPEC = 'CUR_EMP_FILTER_SPEC';
const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
const SET_EMPLOYER_DATA_RELATION = 'SET_EMPLOYER_DATA_RELATION';
const UPDATE_EMPLOYER_DATA_RELATION = 'UPDATE_EMPLOYER_DATA_RELATION';

let initialState = {
  data: [],
  specializations: [],
  currentUserId: '',
}

const employersListReducer = (state = initialState, action) => {

  switch (action.type) {

    case SET_EMPLOYERS_DATA: {
      return {
        ...state, data: action.data.data
      }
    }

    case SET_CURRENT_USER_ID: {
      return {
        ...state, currentUserId: action.currentId
      }
    }

    case CUR_EMP_FILTER_SPEC: {
      return {
        ...state, specializations: action.data
      }
    }

    case UPDATE_EMPLOYER_DATA_RELATION: {
      console.log(action.data, action.userRelation)
      console.log(state.data);
      return {
        ...state,
        data: state.data.map((item, index) => {
          if (item.user.id !== action.data.user.id) {
            return item
          }
          return {
            ...item,
            relation: action.userRelation
              }
            })
          }
      };
    
    default: {
      return state
    }
  }
}
export default employersListReducer;


export const setEmployersData = (data) => ({ type: SET_EMPLOYERS_DATA, data });
export const setFilters = (data) => ({ type: CUR_EMP_FILTER_SPEC, data });

export const setCurrentUserId = (data) => ({ type: SET_CURRENT_USER_ID, data });
export const setUserRelation = (userRelation, data) => ({ type: SET_EMPLOYER_DATA_RELATION, userRelation, data });
export const updateUserRelation = (userRelation, data) => ({ type: UPDATE_EMPLOYER_DATA_RELATION, userRelation, data});

export const getUsers = (currentRelationId, curFilters = '', mainUserStatus = '', subUserStatus = '') => (dispatch, getState) => {
  instance.get(`user/${currentRelationId}/list?mainUserId=&mainUserStatuses=${mainUserStatus}&order=ASC&page=0&perPage=10&searchType=HIRER&spec=${curFilters}&sort=createdTimestamp&subUserStatuses=${subUserStatus}`,
  )
    .then(result => {console.log(result.data)
      dispatch(setEmployersData({ data: result.data }))
    })
    .catch(err => alert(err))
}


export const updateRelation = (currentRelationId, data, subUserStatus) => (dispatch, getState) => {
  console.log(data)
  instance.put(`relation/${currentRelationId}`,
    {
      ...data.relation,
      userRelationStatus:  subUserStatus
      // id: `${data.relation.id}`,
      // mainUserId: `${data.relation.mainUser.id}`,
      // mainUserRelationStatus: `${data.relation.mainUserRelationStatus}`,
      // masterUserId: `${data.relation.id}`,
      // relationType: "PERMANENT",
      // roleId: `${data.relation.roleId}`,
      // userId: `${data.user.id}`,
    })
    .then(res => {
  dispatch(updateUserRelation(res.data, data))
  })
}

export const addMainUser = (currentRelationId, data) => (dispatch, getState) => {
  instance.post( `relation/${currentRelationId}/mainUser/${data.user.id}`
    )
    .then(res=> {
      dispatch(updateUserRelation(res.data, data))
    })
}