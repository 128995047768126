import  React, { useState, useEffect } from 'react';
import Cookies from "universal-cookie";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { setTeamTabIndex } from '../redux/reducers/usersReducer';

const defaultTheme = createMuiTheme({
  xs: '0',
  sm: '600',
  md: '960',
  lg: '1280',
  xl: '1920',
});

const theme = createMuiTheme({
  overrides: {
    MuiTab: {
      label: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '0.875rem',
        },
      } 
    }
  }
});

const AntTabs = withStyles({

  indicator: {
    height: 2,
    backgroundColor: '#0D9CFC',
  },
})(Tabs);

const AntTab = withStyles(() => ({
  root: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    //   textTransform: 'none',
    minWidth: 94,
    height: 64,
    color: '#668BA3',
    '&:hover': {
      color: '#0D9CFC',
      opacity: 1,
    },
    '&$selected': {
      color: '#0D9CFC',
    },
    '&:focus': {
      color: '#0D9CFC',
    },
    '& span': {
      paddingLeft: '7px',
      paddingRight: '7px',
      [defaultTheme.breakpoints.down('sm')]: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    [defaultTheme.breakpoints.down('sm')]: {
      minWidth: 'fit-content',
    },
  },
  selected: {},
}))(props => <Tab  disableRipple {...props}/>);

const styles = theme => ({
  hiddenOnMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
  },
  },
});

const tabletScreenWidth = 1025;

function TeamTabs(props) {
  const { classes } = props;
  const dispatch = useDispatch();

  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    calcWindowSize();
    window.addEventListener('resize', calcWindowSize);
    return () => {
      window.removeEventListener('resize', calcWindowSize);
      cookies.set('activeTeamTab', "", { path: '/' });
    }
  }, []);

  const cookies = new Cookies();

  const calcWindowSize = () => {
    if (document.body.clientWidth < tabletScreenWidth) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  }

  let activeCookieTab = cookies.get('activeTeamTab');
  const tabIndex = useSelector(state => state.users.teamTabIndex);
  const handleChange = (event, newValue) => {
    cookies.set('activeTeamTab', newValue, { path: '/' });
    activeCookieTab = cookies.get('activeTeamTab');
    if (activeCookieTab) {
      dispatch(setTeamTabIndex(+activeCookieTab));
    } else {
      dispatch(setTeamTabIndex(newValue));
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <AntTabs
        value={activeCookieTab ? +activeCookieTab : tabIndex}
        onChange={handleChange}
        aria-label="object tabs"
        className={classes.hiddenOnMobile}
      >
        <AntTab label="МОЯ КОМАНДА"/> 
        <AntTab label={!isTablet ? "ДОСТУП К КОМАНДЕ" : "ДОСТУП"}/> 
        <AntTab label={!isTablet ? "ПОИСК СОТРУДНИКОВ" : "ПОИСК"}/>
        <AntTab label="МОИ ВАКАНСИИ"/>
      </AntTabs>
    </MuiThemeProvider>
  );
}

export default withStyles(styles)(TeamTabs)



// documentation  https://material-ui.com/ru/components/tabs/#customized-tabs