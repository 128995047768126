import React from "react";
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    height: '36px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '4px',
    color: '#FFFFFF',
    background: '#004EE5',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      paddingRight: '19px',
    },
    '& svg':{
      margin:'0 13px 0 11px',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      background: '#0D9CFC',
    },
    '&:disabled': {
      background: '#B3C7F0',
    },
  }
};

function ButtonWithIcon(props) {
  const { classes, icon, title, disabled, onClick, style } = props;

  return (
    <button 
      className={classes.button}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      <span>
      {icon}
      {title}
      </span>
    </button>
    
  );
}

export default withStyles(styles)(ButtonWithIcon);


// принимает props: icon, title, onClick, disabled, style.