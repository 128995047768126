const SET_ERRORS = 'SET_ERRORS';

let initialState = {
    isErrors: false,
}

const errorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERRORS: {
            console.log('work');
            return {
                ...state,
                isErrors: action.isErrors,
            }
        }
        default: return state;
    }
}

export default errorsReducer;

export const setErrors = (isErrors) => ({ type: SET_ERRORS, isErrors });
