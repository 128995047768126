import * as axios from "axios";
import Cookies from 'universal-cookie';
import { environments } from '../constants/constants';

const cookies = new Cookies();
const cookiesId = cookies.get('UserId');
console.log("ENV", process.env.REACT_APP_ENVIRONMENT);

const envInstance = process.env.REACT_APP_ENVIRONMENT === environments.production ? 'https://bilor.pro:' : 'https://bilor.cloud:';
// axios.defaults.withCredentials = true
const instance =
cookiesId ?
axios.create(
  {
    // baseURL: 'http://192.168.71.188:8765/',
    baseURL: `${envInstance}8765/`,
    headers: {
      "Accept": "application/json",
      "Authorization": `Basic ${cookiesId}`,
    },
  }) :
  axios.create(
    {
      // baseURL: 'http://192.168.71.188:8765/'
      baseURL: `${envInstance}8765/`,
    })

export const getUnauthInstance = (encodedData) => axios.create({
  baseURL: `${envInstance}8765/`,
  headers: {
    "Accept": "application/json",
    "Authorization": `Basic ${encodedData}`,
  },
})

export const chatInstance = axios.create({
  baseURL: `${envInstance}8855/`,
  headers: {
    "Accept": "application/json",
    "Authorization": `Basic ${cookiesId}`,
  },
})

export default instance;