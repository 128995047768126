import instance from "../../axios/axiosInstance";
import  { deleteReduxPaymentAttachment, getObjectState } from './progressReducer'

const SET_ATTACHMENTS_LIST = 'SET_ATTACHMENTS_LIST';


let initialState = {

    attachmentsList: [],


};

const paymentAttachmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ATTACHMENTS_LIST: {
            return {
                ...state, attachmentsList: action.data.data
            }
        }
        default: {
            return state
        }
    }
};
export default paymentAttachmentsReducer;

export const setAttachmentsList = (data) => ({ type: SET_ATTACHMENTS_LIST, data });


export const uploadAttachmentByPaymentId = (paymentId, formData, attachmentName) => (dispatch, getState) => {
    const currentOrderAndRelationId = getState().objects.currentOrderAndRelationId;
    instance.post(`orderAndRelation/${currentOrderAndRelationId}/payment/attachment/uploadByPaymentId/${paymentId}?attachmentName=${attachmentName}`,
        formData, 
        { headers: { 'content-type': 'multipart/form-data' } }
    )
        .then((res) => res.data.code && alert(`${res.data.code}: ${res.data.message}`))
        .then((res) => { dispatch(getObjectState()) })
        .catch((error) => console.log(`Upload Attachment ${error}`))
}


export const deletePaymentsAttachment = (attachmentId, paymentId) => (dispatch, getState) => {
    const currentOrderAndRelationId = getState().objects.currentOrderAndRelationId;

    dispatch(deleteReduxPaymentAttachment(paymentId, attachmentId));

    instance.delete(`orderAndRelation/${currentOrderAndRelationId}/payment/attachment/${attachmentId}`,
        
    )
        .then((res) => res.data.code && alert(`${res.data.code}: ${res.data.message}`))
        .then((res) => { dispatch(getObjectState()) })
        .catch((error) => console.log(`Delete Attachment ${error}`))
}







