import React from 'react';
import AlertCustom from './Alert.js';

import { createStyles, withStyles } from '@material-ui/core/styles';

import { wrapConsumer } from '../../hoc/AlertProvider';

const styles = theme => createStyles({
    alertsContainer: {
        display: 'inpline-block',
        zIndex: '100000',
        marginLeft: 'auto',
    },
    alert: {
        boxShadow: '2px 5px 5px rgba(218, 216, 215, 0.6)',
    },
  });

const AlertContainer = (props) => {
    
    const { classes, alerts, dismissAlert } = props;

    return (
        <div 
            key="alert-container" 
            id="alert_container"
            className={classes.alertsContainer}
        >
            {
                !alerts.length ? '' :
                    alerts.map((alert, index) => {
                        return (
                            <AlertCustom
                                key={`alert-${index}-${alert.key}`}
                                status={alert.type}
                                title={alert.title}
                                onClose={dismissAlert}
                                className={classes.alert}
                            />
                        );
                    })
            }
        </div>
    );
}

export default wrapConsumer(withStyles(styles)(AlertContainer)) ;