import React, { useState, useEffect } from 'react'
import { Button, Menu, MenuItem, withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentId } from '../../redux/reducers/userDataReducer';
import Cookies from "universal-cookie";
import clsx from 'clsx';
import { ReactComponent as LogOutIcon } from '../../images/LogOutIcon.svg';
import { ReactComponent as PersonInTheRoundIcon } from '../../images/PersonInTheRoundIcon.svg';
import { setCurrentMainUserId } from '../../redux/reducers/relationReducer';
import AvatarPhoto from '../avatar/AvatarPhoto';
import { setRefreshedTasksStore } from '../../redux/reducers/tasksReducer';
import { setRefreshedObjectsStore } from '../../redux/reducers/objectsReducer';



const styles = theme => ({
  profileButton: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    padding: '0 9px 0 6px',
    width: '211px',
    height: '42px',
    borderRadius: '20px',
    boxShadow: 'none',
    background: '#F5F6F8',
    color: '#8F919B',
    textTransform: 'initial',
    '& svg': {
      '& path': {
        fill: '#A9C1D1'
      }
    },
    '&:hover': {
      color: '#FFFFFF',
      background: '#0D9CFC',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.14)',
      '& svg': {
        '& path': {
          fill: '#FFFFFF'
        }
      },
    },
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
  },
  profileButtonActive: {
    fontWeight: 'bold',
    color: '#FFFFFF',
    background: '#0D9CFC',
    '& svg': {
      '& path': {
        fill: '#FFFFFF'
      }
    },
  },
  expl: {
    fontSize: '11px',
    lineHeight: '13px',
    color: '#A9C1D1',
  },
  explActive: {
    color: '#F5F6F8',
  },
  flexStart: {
    display: 'block',
    overflow: 'hidden',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  logIcon: {
    width: '30px',
    height: '30px',
    marginRight: '9px',
    marginTop: '5px',
  },

  logOut: {
    margin: '8px 0 8px 8px',
    padding: '2px',
    borderRadius: '2px',
    '&:hover': {
      '& g': {
        '& path': {
          fill: '#004EE5'
        }
      }
    }
  },
  nameWrap: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    lineHeight: '1.1',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  }
});

function DefaultRelationButton(props) {
  const dispatch = useDispatch();

  const { classes, active, setActive, logOut } = props;

  const defaultRelation = useSelector(state => state.userData.data.data?.defaultRelation);
  const currentId = useSelector(state => state.userData.currentId);

  const [userName, setUserName] = useState(null);

  const handleChange = () => {
    if (currentId && defaultRelation?.id && currentId !== defaultRelation?.id) {
      dispatch(setRefreshedTasksStore());
      dispatch(setRefreshedObjectsStore());
    }
    const cookies = new Cookies();

    dispatch(setCurrentId(defaultRelation?.id));
    dispatch(setCurrentMainUserId(defaultRelation.mainUser.id));
    cookies.set('currentRelation', defaultRelation.id, { path: '/' });
    cookies.set('currentMainUserRelation', defaultRelation.mainUser.id, { path: '/' });
    cookies.set('isUser', 'user',  { path: '/' });
    setActive('user');
  };

  useEffect(() => {
    defaultRelation?.mainUser.type === 'COMPANY' ?
      setUserName(defaultRelation?.mainUser.companyName) :
      setUserName(`${defaultRelation?.mainUser.firstName}
   ${defaultRelation?.mainUser.lastName} `)
  }, [defaultRelation]);

  const defineRelation = () => {
    let role = '';
    const userKind = defaultRelation?.mainUser?.userKind;
    switch (userKind) {
      case 'COMPANY':
        role = 'Юридическое лицо';
        break;
      case 'EXECUTOR':
        role = 'Исполнитель';
        break;
      case 'OWNER':
        role = 'Владелец';
        break;
      default: return role;
    }
    return role;
  }

  return (
    <Button
      className={
        active === 'user' ?
          clsx(classes.profileButton, classes.profileButtonActive) :
          classes.profileButton
      }
      variant="contained"
      onClick={handleChange}
    >

      <AvatarPhoto
        profile={defaultRelation?.mainUser}
        className={classes.logIcon}
      />
      {/* <PersonInTheRoundIcon className={classes.logIcon} /> */}
      <div className={classes.nameWrap}>
        <div className={classes.flexStart}>
          {
            userName?.includes('undefined') ?
              '' :
              userName
          }
        </div>
        <div className={clsx(classes.expl,
          classes.flexStart,
          active === 'user' && classes.explActive)}>
            {
              defineRelation()
            }
        </div>
      </div>
      < LogOutIcon onClick={logOut} className={classes.logOut} />
    </Button>
  )
}

export default (withStyles(styles)(DefaultRelationButton))

