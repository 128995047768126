import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Cookies from "universal-cookie";
import instance from "../../axios/axiosInstance";
import { useSelector } from 'react-redux';
import { ReactComponent as PersonInTheRoundIcon } from '../../images/PersonInTheRoundIcon.svg';
import { ReactComponent as HouseIcon } from '../../images/house.svg';
import { ReactComponent as CompanyWork } from '../../images/CompanyWork.svg';
import clsx from 'clsx';
import { environments } from '../../constants/constants';

const styles = {
 
  profileAvatar: {
    // position: 'relative',
    borderRadius: '50%',
  },
  objectOwnerHouseIcon: {
    color: '#109CF1',
    width: 13,
    height: 12,
    position: 'absolute',
    '& path': {
        fill: '#109CF1'
    },
    bottom: '2px',
    right: '10px',
  }
};


const AvatarPhotoRelSelectHead = props => {
  const {
    profile,
    classes,
    className,
    style,
    isOwner
  } = props

  const curUserId = useSelector(state => state.curRelationId.mainUserId);
  const curRelationId = useSelector(state => state.curRelationId.currentId);

  const cookies = new Cookies();
  const cookiesId = cookies.get('UserId');

  const [data_url, setData_url] = useState(null);

  var xhr = new XMLHttpRequest();
  useEffect(() => {
    // avaSet()
  }, [curUserId, curRelationId, profile?.avatar])

const avaSet=()=>{


// create img src with axios
  // instance.get(`user/${profile.id}/avatar`,
  // { responseType: 'arraybuffer' })
  // .then(response => {
  //   console.log(response)
  //   if(response.data.code) {
  //   console.log(response.data.message)} else {
  //   const base64 = btoa(
  //     new Uint8Array(response.data).reduce(
  //       (data, byte) => data + String.fromCharCode(byte),
  //       '',
  //     ),
  //   );
  //   setData_url("data:;base64," + base64 );}
  // });

  // xhr.open('GET', `http://192.168.71.188:8765/user/${profile?.id}/avatar`);
  const envInstance = process.env.REACT_APP_ENVIRONMENT === environments.production ? 'https://bilor.pro:' : 'https://bilor.cloud:';
  xhr.open('GET', `${envInstance}8765/user/${profile?.id}/avatar`);

  xhr.setRequestHeader('Authorization', 'Basic ' + cookiesId);
  xhr.responseType = 'blob'; 
  xhr.onreadystatechange = function () {
    
    if (this.readyState === this.DONE && this.status === 200) {
        setData_url(URL.createObjectURL(this.response));
      }};
  xhr.send();


}

  return (
    <span style={{position: 'relative'}}>
      {
        (profile?.avatar) ?
          data_url ?
            <img src={data_url}
              className={clsx(classes.profileAvatar, className)}
              alt=""
              style={{ opacity: 1 }}
            /> :
            <CompanyWork
              className={clsx(classes.profileAvatar, className)}
              style={style}
            /> :
          <CompanyWork
            className={clsx(classes.profileAvatar, className)}
            style={style}
          />
      }
      {
        isOwner &&
        <HouseIcon
          className={classes.objectOwnerHouseIcon}
        />
      }
    </span>



  )
}

export default withStyles(styles)(AvatarPhotoRelSelectHead)
// usage
//   < AvatarPhotoRelSelectHead
//     profile = { profile }
//     className = { classes.profileAvatar }
//     isOwner={ true } // nead add style {position: 'relative'} to wrapComponent
//   />







// create img src with axios

// import React, { Component } from 'react';
// import axios from 'axios';

// class Image extends Component {
//   state = { source: null };

//   componentDidMount() {
//     axios
//       .get(
//         'https://www.example.com/image.png',
//         { responseType: 'arraybuffer' },
//       )
//       .then(response => {
//         const base64 = btoa(
//           new Uint8Array(response.data).reduce(
//             (data, byte) => data + String.fromCharCode(byte),
//             '',
//           ),
//         );
//         this.setState({ source: "data:;base64," + base64 });
//       });
//   }

//   render() {
//     return <img src={this.state.source} />;
//   }
// }

// export default Image;