import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Redirect } from "react-router-dom";
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Rater from 'react-rater';
import clsx from 'clsx';
import { useEffect } from 'react';
import { getProfileData } from '../redux/reducers/profileReducer';
import { getRatingsByUser } from '../redux/reducers/ratingReducer';
import { setInviteChatMail, setInviteCompanyEmail, setSelectedChatEmail } from '../redux/reducers/chatsReducer';
import ButtonWithIcon from '../components/buttons/ButtonWithIcon';
import { defineCorrectSufix } from '../utils.js';
import { ReactComponent as XIcon } from '../images/XIcon.svg';
import { ReactComponent as EmailFillIcon } from '../images/EmailFillIcon.svg';
import { ReactComponent as PhoneIcon } from '../images/PhoneIcon.svg';
import { ReactComponent as ChatIcon } from '../images/ChatIcon.svg';
import { ReactComponent as ReviewIcon } from '../images/ReviewIcon.svg';
import { ReactComponent as LocationIcon } from '../images/LocationIcon.svg';
import AvatarPhoto from '../components/avatar/AvatarPhoto';
import FeedBackCard from './FeedBackCard';
import AddFeedback from '../rating/AddFeedback';

const defaultTheme = createMuiTheme({});

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        [defaultTheme.breakpoints.down('sm')]: {
          margin: '10px',
        },
      } 
    }
  }
});

const styles = theme => createStyles({
  head: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: '#0D9CFC'
    }
  },
  dialogius: {},


  // profile styles
  profileWrap: {
    maxHeight: '792px',
    width: '684px',

    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      // marginTop: '6px',
      // marginBottom: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E3E7ED',
      borderRadius: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      boxSizing: 'border-box',
      padding: '10px 20px',
    },
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#373A44',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0 5px 0',
    },
  },
  profile: {
    background: '#FAFBFC',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'nowrap',
      background: '#FFF',
    },
  },
  avatarProfileWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '40%',
      height: '45%',
    }
  },
  profileAvatar: {
    height: '154px',
    width: '154px',
    margin: ' 32px 40px 6px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      margin: '0',
      textAlign: 'center',
    }
  },
  profileCard: {
    paddingLeft: '32px',
    color: '#8F919B',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      width: '50%',
    },
  },
  userName: {
    padding: '39px 0 28px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0 5px 0',
    },
  },
  ratingPaddings: {
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 12px 0',
    },
  },
  unp: {
    padding: '6px 0 15px 0',
    '& span': {
      color: '#373A44',
      paddingLeft: '9px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '3px 0 3px 0',
    },
  },
  contacts: {
    lineHeight: '23px',
    padding: ' 15px 0 17px 0',
    color: '#373A44',
  },
  contactsIcon: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '12px',
      '& path': {
        fill: '#A9C1D1',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '7px 0 7px 0',
    },
  },
  address: {
    marginBottom: '7px',
  },
  skillsWrap: {
    padding: '0 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  skills: {
    borderTop: '1px solid #EBEFF2',
    padding: '16px 0 28px 0',
    '& span': {
      background: '#EAEDF2',
      color: '#373A44',
      borderRadius: '20px',
      padding: '2px 12px',
      marginRight: '8px',
      marginBottom: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 10px 0',
    },
  },
  profileDescription: {
    color: '#373A44',
    lineHeight: '19px',
    marginBottom: '29px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    color: '#8F919B',
    cursor: 'pointer',
    lineHeight: '19px',
  },
  closeBtn: {
    position: 'absolute',
    top: '29px',
    right: '23px',
  },
  chatButton: {
    margin: '0 0 18px 12px ',
    '& svg': {
      paddingRight: '12px',
      '& path': {
        fill: '#0D9CFC',
      },
    },
    '&:hover': {
      color: '#0D9CFC'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      margin: '0px',
    },
  },
  fullTextOpenBtn: {
    fontSize: '12px',
    lineHeight: '14px',
  },

  // feedback styles
  feedbacksTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '20px',
    padding: '38px 24px 2px 24px',
    color: '#333333',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0px 2px 0px',
    },
  },
  feedbacksList: {
    padding: '0 24px 49px 24px',
    maxHeight: '406px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      // marginTop: '6px',
      // marginBottom: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E3E7ED',
      borderRadius: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  feedbackCard: {
    paddingTop: '24px',
    paddingBottom: '32px',
    borderBottom: '1px solid #EBEFF2',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '10px',
    },
  },

  reviewerAvatar: {
    height: '40px',
    width: '40px',
    marginRight: '16px',
  },
  reviewerName: {
    lineHeight: '19px',
    color: '#373A44',
  },
  reviewerCompany: {
    fontWeight: '500',
    lineHeight: '19px',
    color: '#8F919B',
  },
  rateWrap: {
    padding: '0 0 4px 0'
  },
  rateDate: {
    width: '83px',
    lineHeight: '19px',
    color: '#8F919B',
  },
  rating: {
    display: 'flex',
    marginLeft: 'auto',
    '& .react-rater-star': { // color of star not rated 
      color: '#8FABBD',
    },
    '& .is-active': {  // color of star rated
      color: '#FBBF00',
    },
    '& .will-be-active': {  // color of star on hover 
      color: '#FBBF00',
    },
  },
  ratingDescription: {
    color: '#373A44',
    lineHeight: '19px',
  },
})



export default withStyles(styles)(function ProfilePopUp(props) {
  const dispatch = useDispatch();
  const { classes, userId, isMobile, relationId, orderId } = props;

  const profile = useSelector(state => state.profile?.data);
  const feedbacks = useSelector(state => state.rating.ratingsList);
  const isOwner = useSelector(state => state.objects.data.meOwner);
  const currentUser = useSelector(state => state.userData?.data?.data?.user?.loginEmail);

  const [fullText, setFullText] = useState(false);
  const toggleFullText = () => {
    setFullText(!fullText);
  };

  const [openProfile, setOpenProfile] = useState(false);
  const [isRedirect, setRedirect] = useState(false);

  const handleOpen = () => {
    setOpenProfile(true);
    dispatch(getProfileData(userId));
    dispatch(getRatingsByUser('', userId));
  };

  const handleClose = () => {
    setOpenProfile(false);
  };

  const isCompany = profile.type === "COMPANY"

  const userName = profile.type === "COMPANY" ?
    profile.companyName :
    `${profile.firstName} ${profile.lastName}`

  const userAddress = profile.houseNumber ? `${profile.country}, ${profile.city},
    ${profile.street},  ${profile.houseNumber} - ${profile.roomNumber}` :
    `${profile.country}, ${profile.city}, ${profile.street} `

  const isFullDesk = profile.description?.length > 180;
  const description = isFullDesk ?
    profile.description.slice(0, 175) + '...' : profile.description;

  const redirectToChatHandler = () => {
    dispatch(setInviteChatMail(profile.loginEmail));
    dispatch(setInviteCompanyEmail(currentUser));
    // dispatch(setSelectedChatEmail(profile.loginEmail));
    setRedirect(true);
  }

  return isRedirect ? (<Redirect to='messages' />) : (
    <>
      <div className={classes.head}
        onClick={handleOpen} >
        {props.title}
      </div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          maxWidth={false}
          open={openProfile}
          onClose={handleClose}
          className={classes.dialogius}
        >
          <Grid container className={classes.profileWrap}>
            <Grid item md={12} container className={classes.profile}>
              <Grid item xs container direction="column"
                className={classes.profileCard}
              >
                <Grid item container
                  justify="space-between"
                  className={clsx(classes.userName, classes.title)}>
                  {userName}
                </Grid>

                <Grid container >
                  {!isMobile ? 'Рейтинг' : ''}
                  <span>
                    <Rater
                      interactive={false}  //to create a read-only rater
                      // onRate={onRate}   //onRate: function({ rating }). Callback to retrieve rating value. Called after rated.
                      rating={profile.ratingAvg}
                      total={5}
                      className={clsx(classes.rating, classes.ratingPaddings)} />
                  </span>
                  {`${profile.ratingAvg} (${profile.ratingsNumber} ${defineCorrectSufix(profile?.ratingsNumber, ['отзыв', 'отзыва', 'отзывов'])})`}
                </Grid>

                <Grid container
                  className={classes.unp}>
                  {
                    isCompany &&
                    <>
                      УНП
                    <span>
                        {profile.unp}
                      </span>
                    </>
                  }
                </Grid>

                <Grid container className={classes.contacts}>
                  <Grid item container>
                    {
                      isCompany &&
                      <Grid
                        container
                        direction="row" >
                        <Grid item
                          className={clsx(classes.contactsIcon, classes.address)}
                        >
                          <LocationIcon style={{ width: '11px', height: '14px' }} />
                        </Grid>
                        <Grid item xs >
                          {userAddress}
                        </Grid>
                      </Grid>
                    }
                    <Grid
                      container
                      direction="row"
                    >
                      <Grid item className={classes.contactsIcon}>
                        <PhoneIcon style={{ width: '12px', height: '12px' }} />
                        <Grid item style={{ paddingRight: '23px' }}>
                          {profile.mobilePhone}
                        </Grid>
                      </Grid>
                      <Grid item className={classes.contactsIcon}>
                        <EmailFillIcon style={{ width: '13px', height: '10px' }} />
                        <Grid item >
                          {profile.contactEmail || profile.loginEmail}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className={classes.avatarProfileWrapper}>
                <AvatarPhoto
                  profile={profile}
                  className={classes.profileAvatar}
                />
                <div className={clsx(classes.button, classes.chatButton)}>
                  <ChatIcon style={{ width: '16px', height: '16px' }} />
                  <span onClick={redirectToChatHandler}>Написать в чат</span>               
                </div>
              </Grid>
              <XIcon className={clsx(classes.button, classes.closeBtn)} onClick={handleClose} />
            </Grid>

            <Grid item xs={12}
              container
              className={classes.skillsWrap}
            >
              <Grid container className={classes.skills}>
                {profile?.specializationsNames?.map((spec) => <span key={spec}>{spec}</span>)}
              </Grid>

              {
                profile.description &&
                <>
                  <Grid className={clsx(classes.profileDescription)}>
                    {fullText ? profile.description : description}
                    
                    {
                      isFullDesk &&
                      <Grid container
                        justify="flex-end"
                        className={clsx(classes.button, classes.fullTextOpenBtn)}
                        onClick={toggleFullText}>
                        {fullText ?
                          'Скрыть подробности' :
                          'Прочитать полностью'}
                      </Grid>
                    }

                  </Grid>
                </>
              }
            </Grid>

            <Grid item xs={12} container >
              <Grid item xs={12}
                container
                justify='space-between'
                className={classes.feedbacksTitle}
              >
                Отзывы ({feedbacks.length})
                {isOwner &&
                  <AddFeedback
                    userName={userName}
                    relationId={relationId}
                    orderId={orderId}
                    userId={userId}
                  >
                    <ButtonWithIcon
                      icon={<ReviewIcon />}
                      title={'Написать отзыв'}
                      style={{ marginLeft: 'auto' }}
                    />

                  </AddFeedback>
                }

              </Grid>
              <Grid item xs={12} className={classes.feedbacksList}>
                {feedbacks.length > 0 &&
                  feedbacks.map((feedback) => {
                    return (
                      <FeedBackCard feedback={feedback} key={feedback.id} />
                    )
                  })
                }

              </Grid>

            </Grid>

          </Grid>

        </Dialog>
      </MuiThemeProvider>
    </>
  );
}
);


// // example of use 

//   <ProfilePopUp
//     title = {
//       <>
//         <AvatarPhoto profile={offer.user} className={classes.avatarPhoto} />
//         { offerCompanyName } 
//       </>
//     }
//     userId = { offer.user.id }
//   />