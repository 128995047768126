import axios from "axios";
import { data } from "jquery";
import instance, { chatInstance } from "../../axios/axiosInstance";

const SET_CHATS = 'SET_CHATS';
const SET_SELECTED_CHAT = 'SET_SELECTED_CHAT';
const SET_USER_CHAT_HISTORY = 'SET_USER_CHAT_HISTORY';
const SET_SELECTED_USER = 'SET_SELECTED_USER';
const SET_CHAT_TYPE = 'SET_CHAT_TYPE';
const SET_COMPANY_CHAT_HISTORY = 'SET_COMPANY_CHAT_HISTORY';
const SET_ORDER_CHAT_HISTORY = 'SET_ORDER_CHAT_HISTORY';
const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';
const SET_SUBSCRIBTONS = 'SET_SUBSCRIBTONS';
const SET_SUBS = 'SET_SUBS';
const SET_INVITE_MAIL = 'SET_INVITE_MAIL';
const SET_INVITE_MESSAGE = 'SET_INVITE_MESSAGE';
const CHAT_CLOSE = 'CHAT_CLOSE';
const SET_USER_RELATION = 'SET_USER_RELATION';
const SET_INVITE_COMPANY_MAIL = 'SET_INVITE_COMPANY_MAIL';
const SET_OBJECT_INVITE_DATA = 'SET_OBJECT_INVITE_DATA';
const SET_MESSAGE_TO_CHAT = 'SET_MESSAGE_TO_CHAT';
const SET_CURRENT_STOMP_CLIENT = 'SET_CURRENT_STOMP_CLIENT';
const RESET_UNREAD_MESSAGES_COUNT = 'RESET_UNREAD_MESSAGES_COUNT';
const SET_SELECTED_CHAT_EMAIL = 'SET_SELECTED_CHAT_EMAIL';
const CLEAR_UNREAD_MESSAGES_ARRAY = 'CLEAR_UNREAD_MESSAGES_ARRAY';
const SET_FILTERED_CHATS = 'SET_FILTERED_CHATS';
const SET_DELETE_MESSAGE = 'SET_DELETE_MESSAGE';
const UPDATE_RELATION = 'UPDATE_RELATION';

let initialState = {
    chats: [],
    subscribtionsList: [],
    subsList: [],
    unreadedMessages: [],
    filteredChats: [],
};

const chatsReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case SET_CHATS: { 
            const { selectedChat, chatType } = state;
            const updatedChats = {...action.data};
            if (chatType === 'user' && selectedChat) {
                const updatedChat = updatedChats.data?.userChats.find((el) => el.id === selectedChat);
                if (updatedChat) {
                    updatedChat.unreadMessagesCount = 0;
                }
            }
            const { companyChats, userChats, orderChats } = updatedChats?.data;
            const chatsToFilter = [...companyChats.concat(userChats, orderChats)];
            chatsToFilter.sort((a, b) => {
                // if (b.messages.length && a.messages.length ) {
                    return new Date(b.messages[b.messages.length - 1]?.date) - new Date(a.messages[a.messages.length - 1]?.date);
                // }
            });
            return {
                ...state, 
                chats: updatedChats,
                filteredChats: chatsToFilter,
            }
        }

        case SET_SELECTED_CHAT: {
            return {
                ...state, selectedChat: action.data
            }
        }

        case SET_SELECTED_CHAT_EMAIL: {
            return {
                ...state, selectedChatEmail: action.data,
            }
        }

        case SET_USER_CHAT_HISTORY: { 
            return {
                ...state, userChatHistory: action.data
            }
        }

        case SET_SELECTED_USER: {
            return {
                ...state, selectedUser: action.data
            }
        }

        case SET_CHAT_TYPE: {
            return {
                ...state, chatType: action.data
            }
        }

        case SET_COMPANY_CHAT_HISTORY: {
            const { result, selectedChatId, chatType } = action.data;
            let chatTypeFullName = '';
            let correctIdName = ''
            switch(chatType) {
                case 'user':
                    chatTypeFullName = 'userChats';
                    correctIdName = 'id';
                    break;
                case 'company':
                    chatTypeFullName = 'companyChats';
                    correctIdName = 'companyId';
                    break;
                case 'order':
                    chatTypeFullName = 'orderChats';
                    correctIdName = 'orderId';
                    break;
                default: break;
            }
            const chatsCopy = [...state.chats?.data[chatTypeFullName]];
            const filteredChatsCopy = [...state.filteredChats];
            const chatIndexToUpdate = state.chats?.data[chatTypeFullName]?.findIndex(chat => chat[correctIdName] === selectedChatId);
            const indexOnFilteredChatsCopy = filteredChatsCopy.findIndex(chat => chat[correctIdName] === selectedChatId);
            const chatToUpdate = state.chats?.data[chatTypeFullName]?.find(chat => chat[correctIdName] === selectedChatId);
            const chatToUpdateFiltered = filteredChatsCopy.find(chat => chat[correctIdName] === selectedChatId);
            const messagesHistory = [...result?.data?.messages];
            const newMessagesArr = messagesHistory.concat(chatToUpdate?.messages);
            chatToUpdate.messages = [...newMessagesArr];
            chatToUpdateFiltered.messages = [...newMessagesArr];
            chatsCopy[chatIndexToUpdate] = chatToUpdate;
            chatToUpdateFiltered[indexOnFilteredChatsCopy] = chatToUpdateFiltered;
            return {
                ...state,
                chats: {
                    ...state.chats,
                    data: {
                        ...state.chats.data,
                        [chatTypeFullName]: chatsCopy,
                    }
                },
                filteredChats: filteredChatsCopy,
            }
        }

        case SET_ORDER_CHAT_HISTORY: {
            return {
                ...state, orderChatHistory: action.data
            }
        }

        case SET_SUBSCRIBTONS: {
            return {
                ...state, subscribtionsList: action.data
            }
        }

        case SET_SUBS: {
            return {
                ...state, subsList: action.data
            }
        }

        case SET_UNREAD_MESSAGES: {
            if(action.id){
                return {
                    ...state,
                    unreadMessages: state.unreadMessages.map(unreadMessage => unreadMessage.id === action.id ?
                        { ...unreadMessage, messagesCount: unreadMessage.messagesCount+1 } : 
                        unreadMessage
                    ) 
                } 
            }
            else {
                return {
                    ...state, unreadMessages: action.data
                }
            } 
        }

        case SET_INVITE_MAIL: {
            return {
                ...state, inviteMail: action.data
            }
        }  
        
        case SET_INVITE_MESSAGE: {
            return {
                ...state, inviteMessage: action.data
            }
        } 

        case CHAT_CLOSE: {
            state = []
        }

        case SET_USER_RELATION: {
            return {
                ...state, inviteRelation: action.data
            }
        }

        case SET_INVITE_COMPANY_MAIL: {
            return {
                ...state, inviteCompanyMail: action.data
            }
        }

        case SET_OBJECT_INVITE_DATA: {
            return {
                ...state, objectInviteData: action.data
            }
        }

        case SET_MESSAGE_TO_CHAT: {
            const { msg, id, chatType, chat, currentEmail } = action.data;
            const { recipient, sender } = msg;
            const selectedChat = state.selectedChat;
            const chatToUpdate = [...state.chats.data[chatType]];
            const unreadedMessagesCopy = [...state.unreadedMessages];
            const filteredChatsCopy = [...state.filteredChats];
            let elToUpdate;
            if (chatType === 'userChats') {
                const isCurrentCallbackChat = (chat.user1 === sender || chat.user1 === recipient) && (chat.user2 === recipient || chat.user2 == sender);
                if (!isCurrentCallbackChat) return {...state};
                elToUpdate = chatToUpdate.find(el => {
                    return (el.user1 === sender || el.user1 === recipient) && (el.user2 === recipient || el.user2 == sender);
                });
                elToUpdate && elToUpdate.messages.push(msg);
                if ((!selectedChat || selectedChat !== id) && currentEmail !== msg.sender) {
                    unreadedMessagesCopy.push({ msg, elToUpdate });
                    elToUpdate.unreadMessagesCount += 1;
                }
            } else {
                elToUpdate = chatToUpdate.find((el) => el.orderId === id || el.companyId === id);
                elToUpdate && elToUpdate.messages.push(msg);
                if (!selectedChat || selectedChat !== id) {
                    unreadedMessagesCopy.push({ msg })
                    elToUpdate.unreadMessagesCount += 1;
                }
            }
            filteredChatsCopy.sort((a, b) => {
                // if (b.messages.length && a.messages.length ) {
                    return new Date(b.messages[b.messages.length - 1]?.date) - new Date(a.messages[a.messages.length - 1]?.date);
                // }
            });
            return {
                ...state,
                chats: {
                    ...state.chats,
                    data: {
                        ...state.chats.data,
                        [chatType]: chatToUpdate,
                    }
                },
                unreadedMessages: unreadedMessagesCopy,
                filteredChats: filteredChatsCopy,
            }
        }

        case SET_CURRENT_STOMP_CLIENT: {
            return {
                ...state,
                stompClient: action.data,
            }
        }

        case RESET_UNREAD_MESSAGES_COUNT: {
            const { chatType, chatSelected } = action.data;
            let type = '';
            switch (chatType) {
                case 'user':
                    type = 'userChats';
                    break;
                case 'order':
                    type = 'orderChats';
                    break;
                case 'company':
                    type = 'companyChats';
                    break;
                default: break;
            }
            const chatsCopy = state.chats?.data;
            const filteredChatsCopy = [...state.filteredChats];
            const currentChatType = chatsCopy[type];
            const currentEl = currentChatType.find(el => el.id === chatSelected || el.orderId === chatSelected || el.companyId === chatSelected);
            const currenElInFilteredChats = filteredChatsCopy.find(el => el.id === chatSelected || el.orderId === chatSelected || el.companyId === chatSelected);
            if (currentEl) {
                currentEl.unreadMessagesCount = 0;
            }
            if (currenElInFilteredChats) {
                currenElInFilteredChats.unreadMessagesCount = 0;
            }
            return {
                ...state,
                chats: {
                    ...state.chats,
                    data: {
                        ...state.chats.data,
                        [type]: currentChatType,
                    }
                },
                filteredChats: filteredChatsCopy,
            }
        }

        case CLEAR_UNREAD_MESSAGES_ARRAY: {
            return {
                ...state,
                unreadedMessages: []
            }
        }

        case SET_FILTERED_CHATS: {
            return {
                ...state,
                filteredChats: action.data,
            }
        }

        case SET_DELETE_MESSAGE: {
            const { chatId, messageId } = action.data;
            const filteredChatsCopy = [...state.filteredChats];
            const currentChat = filteredChatsCopy.find(chat => chat.id === chatId || chat.companyId === chat.id || chat.orderId === chat.companyId);
            const msgIndex = currentChat.messages.findIndex(msg => msg.id === messageId);
            currentChat.messages.splice(msgIndex, 1);
            return {
                ...state,
                filteredChats: filteredChatsCopy,
            }
        }

        case UPDATE_RELATION: {
            const {chatId, messageId, userStatus, mainUserStatus} = action.data;
            const filteredChatsCopy = [...state.filteredChats];
            const currentChat = filteredChatsCopy.find(chat => chat.id === chatId || chat.companyId === chat.id || chat.orderId === chat.companyId);
            const currentMessage = currentChat.messages.find(msg => msg.id === messageId);
            const { relation } = currentMessage || {};
            if (relation && userStatus) {
                relation.userRelationStatus = userStatus;
            } else if (relation && mainUserStatus) {
                relation.mainUserRelationStatus = mainUserStatus;
            }

            return {
                ...state,
                filteredChats: filteredChatsCopy
            }
        }

        default: {
            return state
        }
    }
}
export default chatsReducer;

export const setChats = (data) => ({ type: SET_CHATS, data });
export const setSelectedChat = (data) => ({ type: SET_SELECTED_CHAT, data });
export const setSelectedChatEmail = (data) => ({ type: SET_SELECTED_CHAT_EMAIL, data });
export const setSelectedUser = (data) => ({ type: SET_SELECTED_USER, data });
export const setChatType = (data) => ({ type: SET_CHAT_TYPE, data });
export const setUserChatHistory = (data) => ({ type: SET_USER_CHAT_HISTORY, data });
export const setCompanyChatHistory = (data) => ({ type: SET_COMPANY_CHAT_HISTORY, data });
export const setOrderChatHistory = (data) => ({ type: SET_ORDER_CHAT_HISTORY, data });
export const setUnreadMessages = (data, id, count) => ({ type: SET_UNREAD_MESSAGES, data, id, count });
export const setSubscribtionsList = (data) => ({ type: SET_SUBSCRIBTONS, data });
export const setSubs = (data) => ({ type: SET_SUBS, data });
export const setInviteChatMail = (data) => ({ type: SET_INVITE_MAIL, data });
export const setInviteChatMessage = (data) => ({ type: SET_INVITE_MESSAGE, data });
export const setUserRelation = (data) => ({ type: SET_USER_RELATION, data });
export const setInviteCompanyEmail = (data) => ({ type: SET_INVITE_COMPANY_MAIL, data });
export const setObjectInviteData = (data) => ({ type: SET_OBJECT_INVITE_DATA, data });
export const setMessageToChat = (data) => ({ type: SET_MESSAGE_TO_CHAT, data });
export const setCurrentStompClient = (data) => ({ type: SET_CURRENT_STOMP_CLIENT, data });
export const resetUnreadMessagesCount = (data) => ({ type: RESET_UNREAD_MESSAGES_COUNT, data });
export const clearUnreadMessagesArray = () => ({ type: CLEAR_UNREAD_MESSAGES_ARRAY });
export const setFilteredChats = (data) => ({ type: SET_FILTERED_CHATS, data });
export const setDeletedMessage = (data) => ({ type: SET_DELETE_MESSAGE, data });
export const messangerClose = () => ({type: CHAT_CLOSE});
export const updateRelationStatusInMessage = (data) => ({type: UPDATE_RELATION, data});

export const findChatMessagesByOrderId = (currentRelationId, orderId, firstPosition, messagesCount) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    const selectedChatId = getState().chats?.selectedChat;
    const chatType = getState().chats?.chatType;
    if(firstPosition !== undefined && messagesCount !== undefined){
        chatInstance.get(`order/chat/${selectedChatId}/message/list`)
            .then((result) => {
                dispatch(setCompanyChatHistory({ result, selectedChatId, chatType })); 
            })
            .catch(err => console.log(err))
    }
}

export const setUserLastView = (chatId) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    if (currentRelationId) {
        chatInstance.put(`chat/date?chatId=${chatId}`)
        .then((result) => {
        })
        .catch(err => console.log(err))
    }
}

export const findChatMessagesByCompany = (currentRelationId, firstPosition, messagesCount) => (dispatch, getState) => {
    const selectedChatId = getState().chats?.selectedChat;
    const chatType = getState().chats?.chatType;
    if(currentRelationId) {
        if(firstPosition !== undefined && messagesCount !== undefined){
            //messagesCount < 0 ? messagesCount=-messagesCount:messagesCount=messagesCount
            chatInstance.get(`company/chat/${selectedChatId}/message/list`)
                .then((result) => {
                    dispatch(setCompanyChatHistory({ result, selectedChatId, chatType })); 
                })
                .catch(err => console.log(err))
        }
    }
}

export const findChatMessagesBetweenUsers = (currentRelationId, userLoginEmail, firstPosition, messagesCount) => (dispatch, getState) => {
    const selectedChatId = getState().chats?.selectedChat;
    const chatType = getState().chats?.chatType;
    if(currentRelationId){
        if(firstPosition !== undefined && messagesCount !== undefined){
            instance.get(`relation/${currentRelationId}/user/${userLoginEmail}/chat/list?firstPosition=${firstPosition}&messagesCount=${messagesCount}`)
            .then((result) => {
                dispatch(setCompanyChatHistory({ result, selectedChatId, chatType })); 
            })
            .catch(err => console.log(err))
        }
    }
    
}

export const findAllChatsByRelationId = (currentRelationId) => (dispatch, getState) => {
    if(currentRelationId){
    instance.get(`relation/${currentRelationId}/chat/list?messageCount=${-25}`)
        .then((result) => {
            dispatch(setChats(result));
        })
        .catch(err => console.log(err))
    }
}

export const deleteMessage = (currentRelationId, chatId, messageId) => (dispatch, getState) => {
    instance.delete(`relation/${currentRelationId}/user/chat/${chatId}/message/${messageId}`)
        .then((result) => {
            !result.data.code && dispatch(setDeletedMessage({ chatId, messageId }));
        })
}
