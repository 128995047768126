// function to create a right suffix after numeral (ex: 1 - задачA, 2 - задачИ, 11 - задаЧ)
export const defineCorrectSufix = (number, array) => {
    let resultWord;
    let holeNumber = number % 100;
    let lastDigit = number % 10;
    if (holeNumber >= 11 && holeNumber <= 19) {
        resultWord = array[2];
    } else {
        switch(lastDigit) {
            case 0:
            case 5:
                resultWord = array[2];
                break;
            case 1:
                resultWord = array[0];
                break;
            case 2: 
            case 3:
            case 4:
                resultWord = array[1];
                break;
            default: resultWord = array[2];
        }
    }
    return resultWord;
}

//function to generate uniq key for elements
export const generateKey = () => {
    let d = new Date().getTime();
  
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export const transformUrl = (url) => {
    const urlCopy = url;
    let infoObj = {};
    urlCopy.split('&').forEach(el => {
        if (el.includes('objectId') || el.includes('newUser') || el.includes('invitorEmail')) {
            const arrToSet = el.split('=');
            infoObj[arrToSet[0]] = arrToSet[1];
        }
    });
    return infoObj;
}

export const createDate = (value) => {  // date formated to 01.02.19
    let date = new Date(value)
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yy = date.getFullYear() //.toString().substring(2) - cut two first numbers
    return (
        date = dd + '.' + mm + '.' + yy
    )
}


