import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import Cookies from 'universal-cookie';

import { 
    findAllChatsByRelationId, 
    setMessageToChat,
    setCurrentStompClient,
} from '../redux/reducers/chatsReducer.js';

import {
    findProposalsBetweenTwoUsers
} from '../redux/reducers/relationReducer';
import { environments } from '../constants/constants';

const cookies = new Cookies();
const cookiesId = cookies.get('UserId');

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

const WebSocket = ({ curId, chats, findAllChatsByRelationId, setCurrentStompClient, setMessageToChat, userChats = [], findProposalsBetweenTwoUsers, currentEmail }) => {

    const [currentWS, setCurrentWS] = useState({});
    const [channels, setChannels] = useState([]);

    useEffect(() => {
        if (curId) {
            setupWebSocket();
        }
    }, [curId]);

    useEffect(() => {
        if (channels && channels?.length && currentWS?.connected) {
            channels.forEach(channel => {
                currentWS.subscribe(channel.route, channel.callback);
            })
        }
    }, [channels])

    const prevChats = usePrevious(chats);

    useEffect(() => {
        if (!prevChats && chats) {
            successConnectHandler();
        } else if (prevChats && chats) {
            const prevChatsArr = Object.keys(prevChats).reduce((prev, current) => {
                return prev.concat(prevChats[current])
            }, []).map(chat => chat.id || chat.companyId || chat.orderId)
            const currentChatsArr = Object.keys(chats).reduce((prev, current) => {
                return prev.concat(chats[current])
            }, []);
            if (prevChatsArr?.length !== currentChatsArr?.length) {  
                const newChat = currentChatsArr.filter(chat => (chat.id && !prevChatsArr.includes(chat.id)) || 
                (chat.orderId && !prevChatsArr.includes(chat.orderId)) || 
                (chat.companyId && !prevChatsArr.includes(chat.companyId)));
                newChat.forEach(chat => {
                    const newChannel = getChannel(chat.user1 ? 'userChats' : 'orderChats', chat);

                    currentWS.subscribe(newChannel.route, newChannel.callback);
                })
            }
        }
    }, [chats])


    useEffect(() => {
        setCurrentStompClient(currentWS);
    }, [currentWS]);

    const setupWebSocket = () => {
        let socket;
        let webSocket;
        let header;

        if (!!cookiesId) {
            const envInstance = process.env.REACT_APP_ENVIRONMENT === environments.production ? 'https://bilor.pro:' : 'https://bilor.cloud:';
            socket = new SockJS(`${envInstance}8855/ws`, {
                headers: {
                "Accept": "application/json",
                "Authorization": `Basic ${cookiesId}`,
                },
            });
            
            header = {
                "Authorization": `Basic ${cookiesId}`,
            }

            webSocket = Stomp.over(socket);
            setCurrentWS(webSocket);
        }
        if (webSocket) {
            webSocket.connect(header, succesConnectCallback, errorConnectHandler);
        }
    }

    const getChannel = (chatType, chat) => { 
        switch(chatType) {
            case 'userChats':
                return ({
                    route: '/user/queue/private',
                    callback: (msg) => {
                        const message = JSON.parse(msg.body);
                        if (message?.companyInvitation) {
                            findAllChatsByRelationId(curId);
                            setTimeout(() => {
                                findProposalsBetweenTwoUsers(message?.sender);
                                }, 50);
                        }
                        setMessageToChat({ msg: message, id: chat.id, chatType, chat, currentEmail });
                    }
                })
            case 'orderChats':
                return({
                    route: `/topic/${chat.orderId}`,
                    callback: (msg) => {
                        setMessageToChat({ msg: JSON.parse(msg.body), id: chat.orderId, chatType, currentEmail });
                    }
                })
        }
    } 
            
    const succesConnectCallback = () => {
        currentWS.reconnect_delay = 5000;
        if (curId) {
            findAllChatsByRelationId(curId); 
        }
    }

    const successConnectHandler = () => {
        let wsChannels = [];
        if (!chats?.userChats?.length) {
            wsChannels.push({
                route: '/user/queue/private',
                callback: (msg) => {
                    findAllChatsByRelationId(curId)
                }
            })
        }
        if (chats && currentWS?.connected) {
            const chatsArray = Object.keys(chats);
            chatsArray.forEach(chatType => {
                if (chats[chatType].length) {
                    chats[chatType].forEach(chat => {
                        switch(chatType) {
                            case 'userChats':
                                wsChannels.push({
                                    route: '/user/queue/private',
                                    callback: (msg) => {
                                        const message = JSON.parse(msg.body);
                                        if (message?.companyInvitation) {
                                            findAllChatsByRelationId(curId);
                                            setTimeout(() => {
                                                findProposalsBetweenTwoUsers(message?.sender);
                                              }, 50);
                                        }
                                        setMessageToChat({ msg: message, id: chat.id, chatType, chat, currentEmail });
                                    }
                                })
                            break;
                            case 'orderChats':
                                wsChannels.push({
                                    route: `/topic/${chat.orderId}`,
                                    callback: (msg) => {
                                        setMessageToChat({ msg: JSON.parse(msg.body), id: chat.orderId, chatType, currentEmail });
                                    }
                                })
                            break;
                            case 'companyChats':
                                wsChannels.push({
                                    route: `/topic/${chat.companyId}`,
                                    callback: (msg) => {
                                        setMessageToChat({ msg: JSON.parse(msg.body), id: chat.companyId, chatType: 'companyChats', currentEmail });
                                    }
                                })
                            break;
                        }
                    })
                }
            }) 
        }
        setChannels(wsChannels);
    }

    const errorConnectHandler = () => {
        console.log('ws socket did not connect')
    }

    return (
        <span />
    )
}

const mapStateToProps = (state) => {
    return {
        curId: state.curRelationId.currentId,
        chats: state.chats?.chats?.data,
        currentEmail: state.userData?.data?.data?.user?.loginEmail,  
        userChats: state.chats?.chats?.data?.userChats,      
    }
}

export default connect(mapStateToProps, {
    findAllChatsByRelationId,
    setMessageToChat,
    setCurrentStompClient,
    findProposalsBetweenTwoUsers
})(WebSocket);