
import { togleIsFetching } from "./preloaderReducer";
import instance from "../../axios/axiosInstance";
import { getPublicationsListByObjectId } from "./offersListReducer";
import { uploadAttachmentByPaymentId } from "./paymentAttachmentsReducer";
import { setErrors } from './errorsReducer';

const SET_CONTRACTORS_LIST = "SET_CONTRACTORS_LIST";
const SET_OBJECT_STATE_ALL = "SET_OBJECT_STATE_ALL";
const SET_OBJECT_STATE_BY_CONTRACTOR = "SET_OBJECT_STATE_BY_CONTRACTOR";
const CURRENT_SPECIALIZATIONS = "CURRENT_SPECIALIZATIONS";
const CURRENT_ORDER_ID = "CURRENT_ORDER_ID";
const UPDATE_TASCS_DATES = "UPDATE_TASCS_DATES";
const CURRENT_RATING = "CURRENT_RATING";
const SET_RELATION_ID = "SET_RELATION_ID";
const CURRENT_USER_RELATION_ID = "CURRENT_USER_RELATION_ID";
const DELETE_PARTICIPANT = "DELETE_PARTICIPANT";
const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
const DELETE_PAYMENT = 'DELETE_PAYMENT';
const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
const SET_BRIGADIR = 'SET_BRIGADIR';
const SET_CALENDAR_STATE_BY_CONTRACTOR = 'SET_CALENDAR_STATE_BY_CONTRACTOR';
const SET_CALENDAR_ELEMENTS = 'SET_CALENDAR_ELEMENTS';
const SET_GROUP_ID = 'SET_GROUP_ID';
const SET_GROUPS_LIST = 'SET_GROUPS_LIST';

let initialState = {
    contractorsList: [],
    objectStateAll: {},
    objectStateByContractor: {},
    specializations: [],
    curUserId: null,
    curOrderId: null,
    brigadir: false
};

const progressReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_CONTRACTORS_LIST: {
            return {
                ...state, contractorsList: action.list.list
            }
        }
        case SET_OBJECT_STATE_ALL: {
            return {
                ...state,
                objectStateAll: action.data
            }
        }
        case SET_OBJECT_STATE_BY_CONTRACTOR: {
            return {
                ...state, objectStateByContractor: action.data
            }
        }
        case SET_CALENDAR_STATE_BY_CONTRACTOR: {
            return {
                ...state, calendarStateByContractor: action.data
            }
        }
        
        case CURRENT_SPECIALIZATIONS: {
            return {
                ...state, specializations: action.data
            }
        }
        case CURRENT_ORDER_ID: {
            return {
                ...state, curUserId: action.data, curOrderId: action.data
            }
        }

        case CURRENT_RATING: {
            return {
                ...state, rating: action.data
            }
        }

        case SET_RELATION_ID: {
            return {
                ...state, curCompanyRelationId: action.id
            }
        }

        case UPDATE_TASCS_DATES: {
            return {                        // глубокое копирование :
                ...state,                                   // копирую ...state
                objectStateAll: {
                    ...state.objectStateAll,  // в state.objectStateAll записываю копию ...state.objectStateAll
                    data: {
                        ...state.objectStateAll.data,   // в state.objectStateAll.data записываю копию ...state.objectStateAll.data
                        tasksInProcess: state.objectStateAll.data.tasksInProcess.map(task =>   // в tasksInProcess map возвращает новый массив 
                            action.eventId === task.id ?       // при совпадении  task.id с action.eventId заменяет значения planedStartDate и planedFinishDate                                           
                                {                                  // на значения action.start action.end
                                    ...task,
                                    planedStartDate: action.start,
                                    planedFinishDate: action.end
                                } :                                 // иначе  копирует task без изменений
                                task
                        )
                    }
                }
            }
        }

        case CURRENT_USER_RELATION_ID: {
            return {
                ...state, curUserRelationId: action.id
            }
        }

        case DELETE_PARTICIPANT: {
            return {
                ...state,
                objectStateByContractor: {
                    ...state.objectStateByContractor,
                    data: {
                        ...state.objectStateByContractor.data,
                        participants: state.objectStateByContractor.data.participants.filter(worker =>
                            worker.orderAndRelationId !== action.orderAndRelationId
                        )
                    }
                }
            }
        }
        case UPDATE_PAYMENT: {
            return {
                ...state,
                objectStateByContractor: {
                    ...state.objectStateByContractor,
                    data: {
                        ...state.objectStateByContractor.data,
                        payments: state.objectStateByContractor.data.payments.map(payment =>
                            payment.id !== action.data.id ? payment :
                                {
                                    ...payment,
                                    customerCredit: action.data.customerCredit,
                                    customerDebit: action.data.customerDebit,
                                    description: action.data.description,
                                    customerPaymentStatus: action.data.customerPaymentStatus,
                                    contractorPaymentStatus: action.data.contractorPaymentStatus,

                                }
                        )
                    }
                }
            }
        }
        case DELETE_PAYMENT: {
            return {
                ...state,
                objectStateByContractor: {
                    ...state.objectStateByContractor,
                    data: {
                        ...state.objectStateByContractor.data,
                        payments: state.objectStateByContractor.data.payments.filter(payment =>
                            payment.id !== action.paymentId
                        )
                    }
                }
            }
        }
        case DELETE_ATTACHMENT: {
            return {
                ...state,
                objectStateByContractor: {
                    ...state.objectStateByContractor,
                    data: {
                        ...state.objectStateByContractor.data,
                        payments: state.objectStateByContractor.data.payments.map(payment =>
                            payment.id !== action.paymentId ? payment : 
                            {...payment,
                            attachments: payment.attachments.filter((file)=> file.id !== action.attachmentId)}
                        )
                    }
                }
            }
        }
        case SET_BRIGADIR: {
            return {
                ...state, brigadir: action.value
            }
        }

        case SET_CALENDAR_ELEMENTS: {
            return {
                ...state, subElementsList: action.data
            }
        }

        case SET_GROUP_ID: {
            return {
                ...state, curGroupId: action.data
            }
        }

        case SET_GROUPS_LIST: {
            return { 
                ...state, curGroupsList: action.data
            }
        }

        default: {
            return state
        }
    }
}
export default progressReducer;

export const updateReduxTasksDates = (eventId, start, end) => ({ type: UPDATE_TASCS_DATES, eventId, start, end });

export const setContractorsList = (list) => ({ type: SET_CONTRACTORS_LIST, list });
export const setCurrentRelationId = (id) => ({ type: SET_RELATION_ID, id });
export const setObjectStateAll = (data) => ({ type: SET_OBJECT_STATE_ALL, data });
export const setObjectStateByContractorId = (data) => ({ type: SET_OBJECT_STATE_BY_CONTRACTOR, data });
export const setCalendarStateByContractorId = (data) => ({ type: SET_CALENDAR_STATE_BY_CONTRACTOR, data });
export const setFilters = (data) => ({ type: CURRENT_SPECIALIZATIONS, data });
export const setCurOrderId = (data) => ({ type: CURRENT_ORDER_ID, data });
export const setUserRelation = (id) => ({ type: CURRENT_USER_RELATION_ID, id });
export const deleteParticipant = (orderAndRelationId) => ({ type: DELETE_PARTICIPANT, orderAndRelationId });
export const setCalendarElements = (data) => ({ type: SET_CALENDAR_ELEMENTS, data });
export const setBrigadir = (value) => ({ type: SET_BRIGADIR, value });
export const updateReduxPayment = (data) => ({ type: UPDATE_PAYMENT, data });
export const deleteReduxPayment = (paymentId) => ({ type: DELETE_PAYMENT, paymentId });
export const deleteReduxPaymentAttachment = (paymentId, attachmentId) => ({ type: DELETE_ATTACHMENT, paymentId, attachmentId });
export const setCurrentGroup = (data) => ({ type: SET_GROUP_ID, data });
export const setGroupsList = (data) => ({ type: SET_GROUPS_LIST, data });



export const getContractorsByObjectId = (currentOrderAndRelationId, objectId) => (dispatch, getState) => {
    instance.get(`orderAndRelation/${currentOrderAndRelationId}/object/${objectId}/contractor/list`)
        .then((res) => {
            !res.data.code ?
                dispatch(setContractorsList({ list: res.data })) :
                console.log(res.data.message)
        })
        .catch(err => dispatch(setErrors(true)));
}

export const getObjectStateByContractorId = (orderId, brigadeClickFlag) => (dispatch, getState) => {

    const orderAndRelation = getState().objects?.data?.orderAndRelation?.id;

    if (brigadeClickFlag) {
        dispatch(setCurOrderId(orderId));
    } else { dispatch(setCurOrderId(null)); }

    instance.get(`orderAndRelation/${orderAndRelation}/object/order/${orderId}`)
        .then((res) => {
            // dispatch( togleIsFetching(false));
            dispatch(setObjectStateByContractorId({ data: res.data }));
        })
        .catch(err => dispatch(setErrors(true)));
}

export const getCalendarStateByContractorId = (orderId) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    if(orderId !== 'all'){
        instance.get(`relation/${currentRelationId}/object/order/${orderId}`)
        .then((res) => {
            
            // dispatch( togleIsFetching(false));
            dispatch(setCalendarStateByContractorId({ data: res.data }));
        })
        .catch(err => alert(err))
    }
    else {
        dispatch(setCalendarStateByContractorId({ data: 'all' }));
    }
   
}

export const createPublication = (data) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    const objectId = getState().offers.objectData.objectModel.id;

    dispatch(togleIsFetching(true))
    return instance.post(`relation/${currentRelationId}/publication`,
        {
            "name": `${data.publicationName}`,
            "contactEmail": `${data.mail}`,
            "contactPhone": `${data.phone}`,
            "description": `${data.description}`,
            "objectId": `${objectId}`,
            "specialization": data.reactSelect?.map(spec => spec.value)
        }
    )
}

export const updatePublication = (data) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    const objectId = getState().offers.objectData.objectModel.id;
    const publicationId = getState().offers.currentPublication.id;

    dispatch(togleIsFetching(true))
    return instance.put(`relation/${currentRelationId}/publication`,
        {
            "id": `${publicationId}`,
            "name": `${data.publicationName}`,
            "contactEmail": `${data.mail}`,
            "contactPhone": `${data.phone}`,
            "description": `${data.description}`,
            "objectId": `${objectId}`,
            "specialization": data.reactSelect?.map(spec => spec.value)
        }
    )
        .catch(err => console.log(err))
}
export const deletePublication = (currentRelationId, publicationId, objectId) => (dispatch, getState) => {
    dispatch(togleIsFetching(true))
    return instance.delete(`relation/${currentRelationId}/publication/${publicationId}`)
}


export const getObjectState = (currentOrderAndRelationId, objectId) => (dispatch, getState) => {
        const curId =  currentOrderAndRelationId || getState().objects?.data?.orderAndRelation?.id;
        const objId = objectId || getState().objects?.objectId;
    instance.get(`orderAndRelation/${curId}/object/${objId}/state`)
        .then((res) => {
            // dispatch( togleIsFetching(false));
            dispatch(setObjectStateAll({ data: res.data }));
            dispatch(setObjectStateByContractorId({ data: res.data }));
        })
        .catch(err => dispatch(setErrors(true)));
}

export const updateTask = (currentRelationId, eventId, start, end) => (dispatch, getState) => {
    const updatedTask = getState().progress.objectStateAll.data.tasksInProcess.filter(task => task.id === eventId);
    instance.put(`relation/${currentRelationId}/task`, updatedTask[0])
    .then((res) => { dispatch(updateReduxTasksDates(eventId, start, end)) })
}

export const updatePayment = (data) => (dispatch, getState) => {
    dispatch(updateReduxPayment(data))
    // REJECTED
    // PENDING,
    // SUCCESS,
    // ?COMPLETE
    const currentOrderAndRelation = getState().objects?.data?.orderAndRelation?.id;
    const updatedPayment = getState().progress.objectStateByContractor.data.payments
        .filter(payment => payment.id === data.id);
    instance.put(`orderAndRelation/${currentOrderAndRelation}/order/payment`,
        updatedPayment[0])
        // .then((res)=> res.data.code && alert(`${res.data.code}: ${res.data.message}` ))
        .then((res) => data.attachments.map((f) => {
            const attachmentName = f.name
            const formData = new FormData()
            formData.append('file', f)
            return (dispatch(uploadAttachmentByPaymentId(res.data.id, formData, attachmentName)))
        }))
            .then((res) => { dispatch(getObjectState()) })
            .catch((error) => dispatch(setErrors(true)));
}




export const createPayment = (data) => (dispatch, getState) => {
    const currentOrderAndRelation = getState().objects?.data?.orderAndRelation?.id;
    const orderId = getState().progress.curOrderId;

    instance.post(`orderAndRelation/${currentOrderAndRelation}/order/payment`,
        {
            "currency": `${data.currency}`,
            "customerCredit": `${data.customerCredit}`,
            "customerDebit": `${data.customerDebit}`,
            "description": `${data.description}`,
            "orderId": `${orderId}`,
        })
        // .then((res) => res.data.code && alert(`${res.data.code}: ${res.data.message}`))
        .then((res) => data.attachments.map((f) => {

            const attachmentName = f.name
            let formData = new FormData()
            formData.append('file', f)
            return (dispatch(uploadAttachmentByPaymentId(res.data.id, formData, attachmentName)))
        }))

        .then((res) => { dispatch(getObjectState()) })
        .catch((error) => dispatch(setErrors(true)));
}

export const findElementTasksByObjectId = () => (dispatch, getState) => {
    const currentOrderAndRelation = getState().objects?.data?.orderAndRelation?.id;
    const objectId = getState().objects.objectId;
    instance.get(`/orderAndRelation/${currentOrderAndRelation}/object/${objectId}/element/task/list`)
        .then((res) => {
            dispatch(setCalendarElements({ data: res.data }));
        })
        .catch(err => dispatch(setErrors(true)))
}

export const deletePayment = (paimentId) => (dispatch, getState) => {
    dispatch(deleteReduxPayment(paimentId))

    const currentOrderAndRelation = getState().objects?.data?.orderAndRelation?.id;
    // const orderId = getState().progress.curUserId;
    const updatedPayment = getState().progress.objectStateByContractor.data.payments
        .filter(payment => payment.id === paimentId);

    instance.delete(`orderAndRelation/${currentOrderAndRelation}/order/payment/${paimentId}`)
        .then((res) => res.data.code && alert(`${res.data.code}: ${res.data.message}`))
        .then((res) => { dispatch(getObjectState()) })
        .catch((error) => console.log(error))
}