import axios from "axios";
import instance from "../../axios/axiosInstance";

const SET_CURRENT_ID = 'SET_CURRENT_ID';
const SET_CURRENT_MAIN_USER_ID = 'SET_CURRENT_MAIN_USER_ID';
const SET_IS_OWNER = 'SET_IS_OWNER';
const SET_CURRENT_MAIN_USER_PROFILE = 'SET_CURRENT_MAIN_USER_PROFILE';
const SET_CURRENT_MAIN_USER_NAME = 'SET_CURRENT_MAIN_USER_NAME';
const SET_EMPLOYEE_RELATION ='SET_EMPLOYEE_RELATION';
const SET_PROPSAL = 'SET_PROPSAL';

let initialState = {
    currentId: '',
    mainUserId: '',
    isOwner: true,

    relationProfile: {},
    relationCompanyName: 'Работа в компании',
};

const relationReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case SET_CURRENT_ID: {
            return {
                ...state, currentId: action.currentId
            }
        }
        case SET_CURRENT_MAIN_USER_ID: {
            return {
                ...state, mainUserId: action.userId
            }
        }
        case SET_IS_OWNER: {
            return {
                ...state, isOwner: action.boolean
            }
        }

        case SET_CURRENT_MAIN_USER_PROFILE: {
            return {
                ...state, relationProfile: action.profile
            }
        }

        case SET_CURRENT_MAIN_USER_NAME: {
            return {
                ...state, relationCompanyName: action.name
            }
        }

        case SET_EMPLOYEE_RELATION: {
            return {
                ...state, employeeRelation: action.data
            }
        }

        case SET_PROPSAL: {
            return {
                ...state, proposalBetweenUsers: action.data
            }
        }

        default: {
            return state
        }
    }
}
export default relationReducer;

export const setCurrentId = (currentId) => ({ type: SET_CURRENT_ID, currentId });
export const setCurrentMainUserId = (userId) => ({ type: SET_CURRENT_MAIN_USER_ID, userId });
export const setIsOwner = (boolean) => ({ type: SET_IS_OWNER, boolean });

export const setRelationProfile = (profile) => ({ type: SET_CURRENT_MAIN_USER_PROFILE, profile });
export const setRelationMainUserName = (name) => ({ type: SET_CURRENT_MAIN_USER_NAME, name });

export const setEmployeeRelation = (data) => ({ type: SET_EMPLOYEE_RELATION, data });
export const setProposalsBetweenTwoUsers = (data) => ({ type: SET_PROPSAL, data });


export const getEmployeeRelation = (userLogin) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId?.currentId;
    if(currentRelationId){
    instance.get(`relation/${currentRelationId}/user/login/${userLogin}`)
        .then((result) => {
            dispatch(setEmployeeRelation(result)); 
        })
        .catch(err => console.log(err))
    }
}

export const findProposalsBetweenTwoUsers = (userLogin) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId?.currentId;
    if(currentRelationId){
    instance.get(`relation/${currentRelationId}/proposal/listsByUser/${userLogin}`)
        .then((result) => {
            console.log(result);
            dispatch(setProposalsBetweenTwoUsers(result)); 
        })
        .catch(err => console.log(err))
    }
}