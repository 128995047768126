
import { togleIsFetching } from "./preloaderReducer";
import instance from "../../axios/axiosInstance";
import instanceBilling from "../../axios/axiosInstanceBilling";
import { getPublicationsListByObjectId } from "./offersListReducer";
import { uploadAttachmentByPaymentId } from "./paymentAttachmentsReducer";

const SET_PAYER_USER = "SET_PAYER_USER";
const SET_PAYEE_USER = "SET_PAYEE_USER";
const SET_OWNER_USER = "SET_OWNER_USER";

let initialState = {
    payer: {},
    payee: {},
    objectOwner: {},
};

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_PAYER_USER: {
            return {
                ...state, payer: action.user
            }
        }
        case SET_PAYEE_USER: {
            return {
                ...state, payee: action.user
            }
        }
        case SET_OWNER_USER: {
            return {
                ...state, objectOwner: action.user
            }
        }

        default: {
            return state
        }
    }
}
export default paymentReducer;

export const setPayer = (user) => ({ type: SET_PAYER_USER, user });
export const setPayee = (user) => ({ type: SET_PAYEE_USER, user });
export const setOwnerUser = (user) => ({ type: SET_OWNER_USER, user });



export const getPayerById = (userid) => (dispatch, getState) => {
    // dispatch(togleIsFetching(true))
    instance.get(`user/${userid}`)
        .then((res) => {
            // dispatch(togleIsFetching(false));
            !res.data.code ?
                dispatch(setPayer(res.data)) :
                alert(res.data.message)
        })
        .catch(err => alert(err))
}

export const cancelSubscription = (login, productId) => (dispatch, getState) => {
    return instanceBilling.put(`/subscription/user/${login}/product/${productId}`)
}