import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { setUser, isAuth } from '../redux/reducers/userDataReducer';
import { setCurrentId } from '../redux/reducers/relationReducer';
import { setSelectedChat } from '../redux/reducers/chatsReducer.js';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import ObjectTabs from '../objects/ObjectTabs'
import PayButtonChip from '../components/buttons/PayButtonChip';
import RelationSelect from '../components/select/RelationSelect';
import DefaultRelationButton from '../components/buttons/DefaultRelationButton';
import { withRouter } from 'react-router-dom';
import TeamTabs from '../myTeam/TeamTabs';
import WorksTabs from '../works/WorksTabs'
import { setCurSection } from '../redux/reducers/userDataReducer';
import { type } from 'jquery';



const cookies = new Cookies();

const styles = theme => ({
    root: {
        borderBottom: '1px solid #EBEFF2',
        flexGrow: 1,
        '& header': {
            boxShadow: 'unset',
        }
    },
    chatNameBlock: {
        padding: '10px 20px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        color: '#668BA3',
        fontFamily: 'Montserrat',
        fontSize: '20px',
        fontWeight: '700',
        height: '50px',
    },
    chatNameIcon: {
        marginRight: '20px',
    },
    layout: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 1,
        [theme.breakpoints.down('sm')]: {
            height: '65px'
        },
        [theme.breakpoints.down('xs')]: {
            width: '86%',
            marginLeft: 'auto',
            paddingLeft: '0',
        },
    },
    leftBlockWrap: {
        height: '100%',
        width: 'fit-content',
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            top: '87px',
            zIndex: '2',
        },
    },
    rightBlockWrap: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    contractorSelect: {
        margin: '15px 0',
        '& button': {
            color: 'red'
        }
    },
    separator: {
        borderLeft: '1px solid #E3EAEE',
        marginRight: 12,
        height: 42,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    sectionName: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        },
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#668BA3',
        marginRight: 'auto',
        marginLeft: '30px',
    },
    payButtonWrap: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    }
});

const mobileScreenWidth = 500;
class ButtonAppBar extends Component {
    state = {
        active: 'user', // "user, company" 
        isMobile: false,
    };

    setActive = (statuse) => { this.setState(state => ({ active: statuse })) }

    logOut = () => {
        cookies.set('UserId', "", { path: '/' });
        cookies.set('currentRelation', "", { path: '/' });
        this.props.isAuth(false);
        this.props.setUser("");
        window.location.href = '/login';
    }

    setSectionName = () => {
        if(window.location.href.indexOf('objects')>0){
            this.props.setCurSection('Проекты')
            // this.setState(state => ({ sectionName: 'Проекты'}));
        }
        if(window.location.href.indexOf('vacancies')>0){
            this.props.setCurSection('Вакансии')
            // this.setState(state => ({ sectionName: 'Вакансии'}));
        }
        if(window.location.href.indexOf('jobSeekers')>0){
            this.props.setCurSection('Исполнители')
            // this.setState(state => ({ sectionName: 'Исполнители'}));
        }
        if(window.location.href.indexOf('messages')>0){
            this.props.setCurSection('Сообщения')
            // this.setState(state => ({ sectionName: 'Сообщения'}));
        }
        if(window.location.href.indexOf('profile')>0){
            this.props.setCurSection('Профиль')
            // this.setState(state => ({ sectionName: 'Профиль'}));
        }
        if(window.location.href.indexOf('team-page')>0){
            this.props.setCurSection('Команда')
            // this.setState(state => ({ sectionName: 'Команда'}));
        }
        if(window.location.href.indexOf('work/list')>0){
            this.props.setCurSection('Задачи')
            // this.setState(state => ({ sectionName: 'Задачи'}));
        } 
    }

    componentDidMount() {
        const cookies = new Cookies();
        const currentRelationStatus = cookies.get('isUser');
        this.setSectionName();
        this.setState({ active: currentRelationStatus === 'user' ? 'user' : 'company' });

        this.calcWindowSize();
        window.addEventListener('resize', this.calcWindowSize);
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedChat && this.props.selectedChat !== prevProps.selectedChat) {
            let id = '';
            let chatName = '';
            switch (this.props.chatType) {
                case 'user': 
                    id = 'id';
                    break;
                case 'company':
                    id = 'companyId';
                    break;
                case 'order':
                    id = 'companyId';
                    break;
                default: break; 
            }
            const keysArr = Object.keys(this.props.allChats);
            let allChatsArr = []; 
            allChatsArr = keysArr.reduce((prev, current) => {
                return prev.concat(this.props.allChats[current]);
            }, []);
            const currentChat = allChatsArr.find(chat => chat[id] === this.props.selectedChat);
            if (currentChat) {
                if (this.props.chatType === 'user') {
                    chatName = this.props.currentEmail === currentChat?.user1 ? currentChat?.user2Name : currentChat?.user1Name;
                }
                if (this.props.chatType === 'company') {
                    chatName = currentChat?.companyName;
                }
                if (this.props.chatType === 'order') {
                    chatName = currentChat?.orderName;
                }
            }
            this.setState({ chatName });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calcWindowSize);
    }

    calcWindowSize = () => {
        if (document.body.clientWidth < mobileScreenWidth) {
            this.setState({ isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }
    }

    render() {
        const { classes, curSection, paidObjectFlag, relationType, relationSet, selectedChat, setSelectedChat } = this.props;
        const { isMobile, chatName } = this.state;
        const { pathname } = this.props.location;

        return (
            <div className={classes.root}>
                <AppBar position="static" color={'inherit'} variant="dense">
                    {
                        isMobile && selectedChat ?
                        <div className={classes.chatNameBlock} >
                            <KeyboardBackspaceIcon className={classes.chatNameIcon} onClick={() => setSelectedChat(null)}/>
                            <span>{chatName}</span>
                        </div> :
                        <Toolbar className={classes.layout}>

                            <Grid container item justify="flex-start"
                                className={classes.leftBlockWrap} >
                                {(pathname.includes('/object/') || pathname.includes('/element/') || pathname.includes('/task/')) && <ObjectTabs path={pathname} />}
                                {pathname.includes('/team-page') && <TeamTabs />}
                                {pathname.includes('/work/list') && <WorksTabs />}
                            </Grid>

                            <Grid container item justify="flex-end"
                                className={classes.rightBlockWrap} >

                                {
                                    !paidObjectFlag && (pathname.includes('/object/') || pathname.includes('/element/')|| pathname.includes('/task/')) &&
                                    <PayButtonChip
                                        className={classes.payButtonWrap}
                                    >
                                        Оплатить проект
                                    </PayButtonChip>
                                }
                                <Grid className={classes.sectionName}>
                                    {curSection}
                                </Grid>
                                {
                                    relationType !== 'OWNER' && 
                                    !!relationSet?.length && relationSet[0].userRelationStatus === 'APPROVED' && relationSet[0].mainUserRelationStatus === 'APPROVED' &&
                                    <RelationSelect
                                        setActive={this.setActive}
                                        active={this.state.active} 
                                    />
                                }
                                <div className={classes.separator}></div>
                                <DefaultRelationButton setActive={this.setActive}
                                    active={this.state.active}
                                    logOut={this.logOut} />
                            </Grid>

                        </Toolbar>
                    }
                </AppBar>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.userData,
        itemName: state.header.itemName,
        curSection: state.userData.curSection,
        paidObjectFlag: state.objects.data?.objectModel?.paid,
        relationType: state.userData?.data?.data?.user?.userKind,
        relationSet: state.userData?.data?.data?.relationSet,
        selectedChat: state.chats?.selectedChat,
        allChats: state.chats?.chats?.data,
        chatType: state.chats?.chatType,
        currentEmail: state.userData?.data?.data?.user?.loginEmail,
    };
};


export default withRouter(connect(mapStateToProps,
    { 
        setUser, 
        isAuth, 
        setCurrentId, 
        setCurSection,
        setSelectedChat,
    })(withStyles(styles)(ButtonAppBar)));
// const ConnectedApp = withRouter(connect(mapStateToProps, {setUser, setCurrentId, isAuth, setItemName})(withStyles(styles)(App)));