import { Link, NavLink } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Redirect } from 'react-router-dom';
import acts from '../acts';
import works from '../works(old)';
import objects from '../objects';
import { connect } from 'react-redux';
import { Accessibility, PermIdentity, Search, Gavel, Group, FormatPaint, Settings, Help, DoubleArrow } from '@material-ui/icons';
import { getCurrentRelationPriveleges, roleCheck } from '../redux/reducers/rolesReducer';
import { setCurSection } from '../redux/reducers/userDataReducer';
import { setErrors } from '../redux/reducers/errorsReducer';
import SupportForm from '../auth/supportForm/SupportForm.js';

import { wrapConsumer } from '../hoc/AlertProvider'; 

import { Grid, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import ProfilePopUp from '../profile/ProfilePopUp';
import { ReactComponent as PersonIcon } from '../images/PersonIcon.svg';
import { ReactComponent as EmailFillIcon } from '../images/EmailFillIcon.svg';
// import { ReactComponent as ChatIcon } from '../images/ChatIcon.svg';
import { ReactComponent as MainLogoIcon } from '../images/MainLogoIcon.svg';
import { ReactComponent as HandshakeIcon } from '../images/HandshakeIcon.svg';
import { ReactComponent as MegaphoneIcon } from '../images/MegaphoneIcon.svg';
import { ReactComponent as TeamIcon } from '../images/TeamIcon.svg';
import { ReactComponent as JobSeekersIcon } from '../images/JobSeekersIcon.svg';
import { ReactComponent as CaseIcon } from '../images/CaseIcon.svg';
import { ReactComponent as DocsIcon } from '../images/DocsIcon.svg';
import { ReactComponent as DocIcon } from '../images/DocIcon.svg';
import { ReactComponent as ClipboardIcon } from '../images/ClipboardIcon.svg';
import { ReactComponent as ChatIcon } from '../images/chat.svg';
import { ReactComponent as Burger } from '../images/Burger.svg';


const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  rootHidden: {
    width: 100,
  },
  nested: {
    marginLeft: '45px',
  },
  menuWrap: {
    position: 'fixed',
    bottom: '0%',
    background: '#FFFFFF',
    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.04)',
    maxWidth: '236px',
    minWidth: '236px',
    minHeight: '100vh',
    maxHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
     position: 'absolute',
     zIndex: '999',
    }
  },
  mobileHidden: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  menuWrapHidden: {
    position: 'fixed',
    top: '0%',
    minHeight: '100%',
    maxHeight: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.04)',
    width: '69px',
    flexBasis: '3%',
    zIndex: '999',
  },
  navigationBlock: {
    fontFamily: 'Lato',
    fontWeight: 'normal',
    textTransform: 'none',
  },
  appText: {
    display: 'flex',
    alignItems: 'center',
    height: '65px',
    paddingLeft: '11.02%',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    borderBottom: '1px solid #E5ECF1',
    letterSpacing: '-0.03em',
    color: '#668BA3',
    textDecoration: 'unset',
    '& svg': {
      marginRight: '11px',
    },
  },
  listItem: {
    '& div': {
      fontFamily: 'Lato',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.01em',
      color: '#8F919B',
      position: 'relative',
    },
    '& svg': {
      color: '#A9C1D1)',
      '& path': {
        fill: '#A9C1D1',
      },

    },
    '&:hover': {
      borderLeft: '3px solid #0D9CFC',
      // background: '#ECEDF0',
      paddingLeft: '21px',
      '& svg': {
        color: '#0D9CFC',
        '& path': {
          fill: '#0D9CFC'
        },
      },
      '& div': {
        color: '#0D9CFC',
      }
    }
  },
  activeListItem: {
    '&$listItem': {
      borderLeft: '3px solid #0D9CFC',
      // background: '#ECEDF0',
      paddingLeft: '21px',
      '& svg': {
        color: '#0D9CFC',
        '& path': {
          fill: '#0D9CFC'
        },
      },
      '& div': {
        color: '#0D9CFC',
      }
    },
    position: 'relative',
    borderLeft: '3px solid #0D9CFC',
    paddingLeft: '21px',
    fontSize: '14px',
    color: '#0D9CFC',
  },
  unreadedMessagesCount: {
    position: 'absolute',
    left: '140px',
    top: '10px',
    display: 'flex',
    width: '15px',
    height: '15px',
    background: '#0D9CFC',
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    fontSize: '60%',
    fontWeight: 'bold',
  },
  unreadMessagesCountTablet: {
    position: 'absolute',
    left: '40px',
    top: '26px',
    display: 'flex',
    width: '15px',
    height: '15px',
    background: '#0D9CFC',
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    fontSize: '60%',
    fontWeight: 'bold',
  },
  menuSettings: {
    borderTop: '1px solid #EBEFF2'
  },

  menuHelp: {
    width: '100%',
    position: 'absolute',
    bottom: 0
  },
  menuText: {
    width: '160px',
    fontSize: "50px"
  },
  test: {
    fontSize: '50px'
  },
  arrowIcon: {
    transform: 'rotate(180deg)',
  },
  arrowIconRotate: {
    transform: 'rotate(0deg)',
  },
  hideButton: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  supportFormWrapper: {
    position: 'fixed',
    bottom: '27%',
    left: '15%',
    zIndex: '999999',
    width: '400px',
    [theme.breakpoints.up(1152)]: {
        position: 'fixed',
        left: '3%',
        bottom: '1%',
    },
    [theme.breakpoints.up(1280)]: {
        position: 'fixed',
        left: '3%',
        bottom: '1%',
    },
    [theme.breakpoints.up(1360)]: {
        position: 'fixed',
        left: '3%',
        bottom: '1%',
    },
    [theme.breakpoints.up(1440)]: {
        position: 'fixed',
        left: '3%',
        bottom: '1%',
    },
    [theme.breakpoints.up(1600)]: {
        position: 'fixed',
        left: '7%',
        bottom: '2%',
    },
    [theme.breakpoints.up(1680)]: {
        position: 'fixed',
        left: '9%',
        bottom: '2%',
    },
    [theme.breakpoints.up(1920)]: {
        position: 'fixed',
        left: '14%',
        bottom: '2%',
    },
    [theme.breakpoints.up(2048)]: {
        position: 'fixed',
        left: '16%',
        bottom: '2%',
    },
    [theme.breakpoints.up(2560)]: {
        position: 'fixed',
        left: '23%',
        bottom: '2%',
    },
  },
  menuWrapTablet: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      zIndex: '1000',
      top: '0',
      maxHeight: '600px',
      minHeight: '100%',      
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
    }
  },
  menuSubstrate:{
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      width: '76px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  menuName: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  settingsIcon: {
    marginRight: '0px'
  },
  subList: {
    marginLeft: '-33px'
  },
  mobileLogo: {
    paddingLeft: '17px'
  },
  burgerIcon: {
    position: 'absolute',
    top: '30px',
    left: '19px',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
    display: 'none',
  },
  hidden: {
    display: 'none',
  },
  layout: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '999',
  },
});

const mobileSize = 500;
class MenuClear extends React.Component {
  state = {
    open: false,
    openSettings: false,
    openResources: false,
    hidden: " NestedList-menuText-223 ",
    startFlag: false,
    mobileShow: false,
    tabletScreenWidth: 1280,
    mobileScreenWidth: 600,
    unreadedMessages: 0,
    isSupportFormOpened: false,
    isTranformSupportForm: false
  };

  calcWindowSize = () => {
    if (
      document.body.clientWidth < this.state.tabletScreenWidth && 
      document.body.clientWidth > this.state.mobileScreenWidth) {
      this.setState(state => ({ open: !state.open }));
    }

    if (document.body.clientWidth < mobileSize) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  handleHideClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleMobileShow = () => {
    this.setState(state => ({ mobileShow: !state.mobileShow }));
  };

  handleSettingsClick = () => {
    this.setState(state => ({ openSettings: !state.openSettings }));
  };

  handleResourcesClick = () => {
    this.setState(state => ({ openResources: !state.openResources }));
  };

  handleClick = () => {
    let element = document.getElementsByClassName('NestedList-menuText-223');
    let parentElement = document.getElementsByClassName('menuWrap');
    if (parentElement.classList) {
      parentElement.classList.toggle('NestedList-rootHidden-220');
    }
    for (let i = 0; i < element.length; i++) {
      let elemClassList = Object.values(element[i].classList)
      if (elemClassList.includes('ButtonAppBar-hidden-2')) {
        this.setState(state => ({ hidden: 'ButtonAppBar-hidden-2' }));
      }
      else {
        this.setState(state => ({ hidden: 'NestedList-menuText-223' }));
      }
    }
    this.setState(state => ({ open: !state.open }));
  };

  diff(a1, a2) {
    if (a1.length > 0 && a2.length > 0) {
      return a1.filter(i => a2.indexOf(i) < 0)
        .concat(a2.filter(i => a1.indexOf(i) < 0))
    }
    else {
      return []
    }
  }

  setCurSectionHandler = (page) => {
    this.props.setCurSection(page);
    this.props.setErrors(false);
  }

  componentDidMount(){
    this.calcWindowSize();
    if (this.props.userData) {
      if (this.props.userData.relationSet?.length !== 0) {
        this.props.userData.relationSet.map((data) => {
          if (data.id === this.props.currentRelationId) {
            if (data.roleId && this.props.currentRelationId) {
              this.props.getCurrentRelationPriveleges(this.props.currentRelationId, data.roleId)
            }
          }
          else if (this.props.userData.defaultRelation?.id === this.props.currentRelationId) {
            this.props.getCurrentRelationPriveleges(this.props.userData.defaultRelation.id, this.props.userData.defaultRelation.roleId)
          }
        })
      } else {
        this.props.getCurrentRelationPriveleges(this.props.userData.defaultRelation.id, this.props.userData.defaultRelation.roleId)
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.open !== this.state.open || 
      nextState.openSettings !== this.state.openSettings || 
      nextState.openResources !== this.state.openResources ||
      nextProps.currentRelationId !== this.props.currentRelationId ||
      nextProps.curElem !== this.props.curElem ||
      nextProps.chats !== this.props.chats ||
      nextState.unreadedMessages !== this.state.unreadedMessages ||
      nextProps.selectedChat !== this.props.selectedChat ||
      nextState.isSupportFormOpened !== this.state.isSupportFormOpened
    ) {
      return true
    }
    if (nextState.mobileShow !== this.state.mobileShow) {
      return true
    }
    
    if (nextProps.curRelationRoles.length !== 0 && this.props.curRelationRoles !== 0) {
      let diff = this.diff(nextProps.curRelationRoles, this.props.curRelationRoles)
      if (diff) {
        if (diff.length !== 0) {
          return true
        }
        else {
          if (this.state.startFlag == false) {
            this.setState(state => ({ startFlag: true }));
            return true
          }
          else {
            return false
          }
        }
      }
      else {
        return true
      }
    }
    else {
      return true
    }
  }

  componentDidUpdate(prevProps) {
    const { chats } = this.props;
    console.log(this.props.userData);
    if (chats && prevProps.chats !== this.props.chats) {
      const arr = Object.keys(chats);
      let newUnreadedMessagesCount = 0;
      arr.forEach(el => {
        chats[el].forEach(chat => {
          console.log(chat.unreadMessagesCount);
          newUnreadedMessagesCount += chat.unreadMessagesCount;
        })
      })
      this.setState({ unreadedMessages: newUnreadedMessagesCount });
    }
    
    if (this.props.userData && this.props.userData !== prevProps.userData || 
      this.props.currentRelationId && this.props.currentRelationId !== prevProps.currentRelationId) {
      if (this.props.userData.relationSet?.length !== 0) {
        this.props.userData.relationSet.map((data) => {
          console.log('data.id:', data.id, ' this.props.currentRelationId:', this.props.currentRelationId);
          if (data.id === this.props.currentRelationId) {
            console.log('data.id:', data.id, ' this.props.currentRelationId:', this.props.currentRelationId);
            if (data.roleId && this.props.currentRelationId) {
              this.props.getCurrentRelationPriveleges(this.props.currentRelationId, data.roleId)
            }
          }
          else if (this.props.userData.defaultRelation?.id === this.props.currentRelationId) {
            this.props.getCurrentRelationPriveleges(this.props.userData.defaultRelation.id, this.props.userData.defaultRelation.roleId)
          }
        })
      } else {
        this.props.getCurrentRelationPriveleges(this.props.userData.defaultRelation.id, this.props.userData.defaultRelation.roleId)
      }
    }
  }

  handleHelpButtonClick = () => {
    this.setState({ isSupportFormOpened: true });
  }

  handleCancelBtn = () => {
    this.setState({ isSupportFormOpened: false });
  }

  checkScrollPosition = () => {
    this.setState({ isTranformSupportForm: window.scrollY > 10});
  }

  render() {
    const { unreadedMessages, isMobile, open, isSupportFormOpened, isTranformSupportForm } = this.state;
    const { selectedChat } = this.props;
console.log('isSupportFormOpened', isSupportFormOpened);
    const { 
      classes, 
      userData, 
      currentRelationId, 
      curRelationRoles,
      setCurSection,
      curElem,
      relationType,
      chats,
    } = this.props;

    const isCompany = this.props.currentRelationId !==
      this.props.userData?.defaultRelation.id

    return (
      <>
      {!this.state.mobileShow && <Burger onClick={this.handleMobileShow} className={clsx(classes.burgerIcon, isMobile && selectedChat && classes.hidden)}/>}
      {this.state.mobileShow && <Grid className={classes.layout} onClick={this.handleMobileShow}></Grid>}
      {/* <Grid className={classes.menuSubstrate}></Grid> */}
      <Grid 
        item xs={2} 
        className={clsx(
          !this.state.open ? classes.menuWrap : classes.menuWrapHidden, 
          !this.state.mobileShow && classes.mobileHidden,
          !this.state.open && classes.menuWrapTablet)
        }
      >
       
        <Link to='/objects' className={classes.appText}>
          {/* <Grid item xs={12} className={classes.appText} > */}

          {this.state.open ? <MainLogoIcon className={classes.mobileLogo} /> : <MainLogoIcon />}
          {!this.state.open && 'Bilor'}
        {/* </Grid> */}
        </Link>

        <List component="nav" className={classes.navigationBlock}>

          <ListItem 
            component={NavLink} 
            
            to={'/objects'} 
            button 
            className={
              curElem == 'element'||
              curElem == 'object'||
              curElem == 'relation' ? 
              classes.activeListItem : 
              classes.listItem
            } 
            activeClassName={classes.activeListItem}
            onClick={() => this.setCurSectionHandler('Проекты')}
            >
            <ListItemIcon >
              <CaseIcon />
            </ListItemIcon>
            {isCompany || relationType !== 'OWNER'?
              <div
                className={this.state.open && classes.menuName}
              >Проекты </div> :
              <div
                className={this.state.open && classes.menuName}
              >Моя недвижимость </div>
            }
            {/* <ListItemText className={classes.menuText}  primary="Объекты" onClick={() => this.props.setItemName('Objeсts')} /> */}
          </ListItem>

          {!isCompany && relationType !== 'EXECUTOR' &&
              <ListItem component={NavLink} to='/jobSeekers' button className={classes.listItem} activeClassName={classes.activeListItem}>
                <ListItemIcon>
                  <JobSeekersIcon />
                </ListItemIcon>
                <div
                  className={this.state.open && classes.menuName}
                >Поиск исполнителей</div>
                {/* <ListItemText className={classes.menuText}  primary="Поиск работы" onClick={() => this.props.setItemName('Employers')} /> */}
              </ListItem>

          }
              {/* <ListItem component={NavLink} to='/jobSeekers' button className={classes.listItem} activeClassName={classes.activeListItem}>
                <ListItemIcon>
                  <JobSeekersIcon />
                </ListItemIcon>
                <div>Поиск исполнителей</div>
                <ListItemText className={classes.menuText}  primary="Поиск работы" onClick={() => this.props.setItemName('Employers')} />
              </ListItem> */}
          {isCompany || relationType !== 'OWNER' &&

            <ListItem 
              component={NavLink} 
              to='/team-page' 
              button 
              className={classes.listItem} 
              activeClassName={classes.activeListItem}
              onClick={() => this.setCurSectionHandler('Команда')}
            >
              <ListItemIcon>
                <TeamIcon />
              </ListItemIcon>
              <div
                className={this.state.open && classes.menuName}
              >Команда</div>
              {/* <ListItemText className={classes.menuText}  primary="Люди" onClick={() => this.props.setItemName('People')} /> */}
            </ListItem>
          }
          {/* {roleCheck(<ListItem component={NavLink} to='/roles' button className={classes.listItem} activeClassName={classes.activeListItem}>
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <div>Роли</div>
          </ListItem>,curRelationRoles, 'ROLE_READ', 'roles')} */}
          {isCompany || relationType !== 'OWNER' &&
            <>
              {
                roleCheck(
                  <>
                    <ListItem button onClick={this.handleResourcesClick} className={classes.listItem}>
                      <ListItemIcon>
                        <DocsIcon  className={this.state.open && classes.settingsIcon} />
                      </ListItemIcon>
                      <div 
                        className={clsx(classes.menuText, this.state.open && classes.menuName)} 
                        onClick={() => this.props.setItemName('Works')} 
                      >
                        Ресурсы
                      </div>
                      {/* <ListItemText className={classes.menuText}  primary="Работы" onClick={() => this.props.setItemName('Works')} /> */}
                      {this.state.openResources ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse className={classes.subList} in={this.state.openResources} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem 
                          className={clsx(classes.nested, classes.listItem)} 
                          component={NavLink} 
                          to='/work/list' 
                          button 
                          activeClassName={classes.activeListItem}
                          onClick={() => this.setCurSectionHandler('Шаблоны')}
                        >
                          <ListItemIcon>
                            <ClipboardIcon/>
                          </ListItemIcon>
                          <div
                            className={this.state.open && classes.menuName}
                          >Шаблоны задач</div>
                          {/* <ListItemText className={this.state.hidden}  primary="Типы работ" onClick={() => this.props.setItemName('Works List')} /> */}
                        </ListItem>
                        {/* {roleCheck(<ListItem className={clsx(classes.listItem, classes.nested)} component={NavLink} to='/workGroup/list' button activeClassName={classes.activeListItem}>
                <ListItemIcon>
                  <DocIcon/>
                </ListItemIcon>
                <div>Документы</div> */}
                        {/* <ListItemText className={this.state.hidden}  primary="Группы работ" onClick={() => this.props.setItemName('Works Groups')} /> */}
                        {/* </ListItem>, curRelationRoles, 'WORK_GROUP_READ', 'workGroup')} */}
                      </List>
                    </Collapse>
                  </>,
                  curRelationRoles, 'TEMPLATE_READ', 'work'
                )
              }
              <ListItem 
                component={NavLink} 
                to='/offers' 
                button 
                className={classes.listItem} 
                activeClassName={classes.activeListItem}
                onClick={() => this.setCurSectionHandler('Заказы')}
              >
                <ListItemIcon>
                  <HandshakeIcon />
                </ListItemIcon>
                <div
                  className={this.state.open && classes.menuName}
                >Поиск заказов</div>
                {/* <ListItemText className={classes.menuText}  primary="Заказы" onClick={() => this.props.setItemName('Publications')} /> */}
              </ListItem>
            </>
          }
          <ListItem 
            component={NavLink} 
            to='/messages' 
            button 
            className={classes.listItem} 
            activeClassName={classes.activeListItem}
            onClick={() => this.setCurSectionHandler('Сообщения')}
          >
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            {
              unreadedMessages ? <span className={!open ? classes.unreadedMessagesCount : classes.unreadMessagesCountTablet}>{unreadedMessages}</span> : '' 
            }
            <div
              className={this.state.open && classes.menuName}
            >Сообщения</div>
          </ListItem>
          
          {/* <ListItem component={NavLink} to='/acts' button className={classes.listItem} activeClassName={classes.activeListItem} >
            <ListItemIcon>
              <acts.icon />
            </ListItemIcon>
            <div>Документы</div>
          </ListItem> */}
          {/* <ListItem component={Link} to='/reports' button>
            <ListItemIcon>
              <reports.icon/>
            </ListItemIcon>
            <ListItemText className={classes.menuText}  primary="Отчеты" />
          </ListItem> */}
          {/* {roleCheck(<ListItem component={NavLink} to='/usersList' button className={classes.listItem} activeClassName={classes.activeListItem}>
            <ListItemIcon>
              <Accessibility />
            </ListItemIcon>
            <div>Поиск сотрудников</div>
          </ListItem>, curRelationRoles, 'RELATION_CREATE', 'usersList')} */}

          <ListItem 
            className={clsx(classes.listItem)}
            component={NavLink} 
            to={`/profile`}
            button 
            activeClassName={classes.activeListItem}
            onClick={() => this.setCurSectionHandler('Профиль')}
          >
            <ListItemIcon>
              <PersonIcon style={{ width: '19px', height: '19px' }} />
            </ListItemIcon>
            {isCompany || relationType === 'COMPANY' ?
              <div
                className={this.state.open && classes.menuName}
              >
                Профиль компании
              </div> :
              <div
                className={this.state.open && classes.menuName}
              >
                Мой профиль
              </div>
            }
            {/* <ListItemText className={this.state.hidden}  primary="Типы работ" onClick={() => this.props.setItemName('Works List')} /> */}
          </ListItem>
          {!isCompany && relationType !== 'OWNER' &&
            <>
              <ListItem 
                component={NavLink} 
                to='/vacancies' 
                button 
                className={classes.listItem} 
                activeClassName={classes.activeListItem}
                onClick={() => setCurSection('Поиск')}
              >
                <ListItemIcon>
                  <MegaphoneIcon />
                </ListItemIcon>
                <div
                  className={this.state.open && classes.menuName}
                >Поиск вакансий</div>
                {/* <ListItemText className={classes.menuText}  primary="Поиск работы" onClick={() => this.props.setItemName('Employers')} /> */}
              </ListItem>
            </>
          }
          <ListItem button onClick={this.handleSettingsClick}
            className={clsx(classes.menuSettings, classes.listItem)}
            // activeClassName={classes.activeListItem}
            button>
            <ListItemIcon>
              <Settings className={this.state.open && classes.settingsIcon}/>
            </ListItemIcon>
            <div className={clsx(classes.menuText, this.state.open && classes.menuName)}>Настройки</div>
            {/* <ListItemText className={classes.menuText}  primary="Настройки" onClick={() => alert('Настройки ?')} /> */}
            {this.state.openSettings ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse className={classes.subList} in={this.state.openSettings} timeout="auto" unmountOnExit>
            <List  component="div" disablePadding>
              <ListItem className={clsx(classes.listItem, classes.nested)}
                component={NavLink}
                to={`/email/${userData?.user?.id}`}
                button activeClassName={classes.activeListItem}>
                <ListItemIcon>
                  <EmailFillIcon style={{ width: '19px', height: '19px' }} />
                </ListItemIcon>
                <div className={clsx(classes.menuText, this.state.open && classes.menuName)}>E-mail и пароль</div>
                {/* <ListItemText className={this.state.hidden}  primary="Группы работ" onClick={() => this.props.setItemName('Works Groups')} /> */}
              </ListItem>
              {/* временно спрятано*/}
              {/* <ListItem className={clsx(classes.listItem, classes.nested)}
                component={NavLink}
                to='/chat'
                button activeClassName={classes.activeListItem}>
                <ListItemIcon>
                  <ChatIcon style={{ width: '19px', height: '19px' }} />
                </ListItemIcon>
                <div className={clsx(classes.menuText, this.state.open && classes.menuName)}>Уведомления</div>
                <ListItemText className={this.state.hidden}  primary="Группы работ" onClick={() => this.props.setItemName('Works Groups')} />
              </ListItem>
              */}
            </List>
          </Collapse>





        </List>
        <List component="nav" className={classes.menuHelp}>
          {
            isSupportFormOpened ? (
              <div className={clsx(classes.supportFormWrapper)}>
                  <div className={clsx(classes.supportForm)}>
                      <SupportForm handleCancelBtn={this.handleCancelBtn}/>
                  </div>
              </div>
            ) : (
              <div onClick={this.handleHelpButtonClick}>
              <ListItem button component={NavLink} to='#' className={clsx(classes.menuSettings, classes.listItem)} >
                <ListItemIcon>
                  <Help />
                </ListItemIcon>
                <div
                  className={this.state.open && classes.menuName}
                >
                  Помощь
                </div>
                {/* <ListItemText className={classes.menuText}  primary="Помощь" onClick={() => alert('Помощь ?')} /> */}
              </ListItem>
              </div>
            )
          }
          <ListItem 
            button 
            component={NavLink} 
            to='#' 
            className={clsx(classes.menuSettings, classes.listItem, classes.hideButton)} 
            onClick={this.handleHideClick}
          >
            <ListItemIcon>
              <DoubleArrow className={this.state.open ? classes.arrowIconRotate : classes.arrowIcon} />
            </ListItemIcon>
            <div
              className={this.state.open && classes.menuName}
            >
              Свернуть
            </div>
            {/* <ListItemText className={classes.menuText}  primary="Помощь" onClick={() => alert('Помощь ?')} /> */}
          </ListItem>
        </List>
      </Grid>
    </>
    );
  }
}

MenuClear.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    currentRelationId: state.curRelationId.currentId,
    userData: state.userData.data.data,
    curRelationRoles: state.roles.currentRelationPriveleges,
    curElem: state.header.sectionName,
    relationType: state.userData?.data?.data?.user?.userKind,
    chats: state.chats?.chats?.data,
    selectedChat: state.chats?.selectedChat,
  };
}
export default connect(
  mapStateToProps,
  {
    setCurSection,
    getCurrentRelationPriveleges,
    setErrors,
  }
)(wrapConsumer(withStyles(styles)(MenuClear)));