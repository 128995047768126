import axios from "axios";
import instance from "../../axios/axiosInstance";
import { environments } from '../../constants/constants';

const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const TOGLE_DISABLE = 'TOGLE_DISABLE';
const SET_STEP = 'SET_STEP';
const SET_HAVE_A_COMPANY = 'SET_HAVE_A_COMPANY';
const SET_REG_DATA = 'SET_REG_DATA';
const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
const TOGGLE_PRIVACY_POLICY = 'TOGGLE_PRIVACY_POLICY';

let initialState = {
    isDisable: true,
    data: {},
    step: 1,
    haveACompany: false,
    privacyPolicy: false,
    regUserData: null,
    regCompanyData: null,
};

const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE_DATA: {
            return {
                ...state, data: action.data
            }
        }
        case TOGLE_DISABLE: {
            return {
                ...state, isDisable: action.isDisable
            }
        }
        case SET_STEP: {
            return {
                ...state, step: action.step
            }
        }
        case SET_HAVE_A_COMPANY: {
            return {
                ...state, haveACompany: action.haveACompany
            }
        }
        case SET_REG_DATA: {
            return {
                ...state,
                regUserData: { ...state.regUserData, ...action.data }
            }
        }
        case SET_COMPANY_DATA: {
            return {
                ...state,
                regCompanyData: { ...state.regCompanyData, ...action.data }
            }
        }
        case TOGGLE_PRIVACY_POLICY: {
            return {
                ...state,
                privacyPolicy: !state.privacyPolicy
            }
        }

        default: {
            return state

        }
    }
}
export default registrationReducer;

export const setProfileData = (data) => ({ type: SET_PROFILE_DATA, data });
export const togleDisable = (isDisable) => ({ type: TOGLE_DISABLE, isDisable });
export const setStep = (step) => ({ type: SET_STEP, step });
export const setHaveACompany = (haveACompany) => ({ type: SET_HAVE_A_COMPANY, haveACompany });
export const setRegData = (data) => ({ type: SET_REG_DATA, data });
export const setCompanyData = (data) => ({ type: SET_COMPANY_DATA, data });
export const togglePrivactPolicy = () => ({ type: TOGGLE_PRIVACY_POLICY });

export const getProfileData = () => (dispatch, getState) => {
    const envInstance = process.env.REACT_APP_ENVIRONMENT === environments.production ? 'https://bilor.pro:' : 'https://bilor.cloud:';
    axios.get(`${envInstance}8765/user`)
        .then((res) => {
            dispatch(setProfileData(res.data))
        })
}

export const putProfileData = (data) => (dispatch, getState) => {
    instance.post('user', data)
        .catch(err => console.log(err))
}

export const checkUserData = () => (dispatch, getState) => {
    const userData = getState().registration.regUserData;
    return instance.post('user', userData);
}

export const createUser = () => (dispatch, getState) => {
    const userData = getState().registration.regUserData;
    userData.country = userData.country.label;
    userData.city = userData.city.label;
    instance.post('user', userData)
        .then(dispatch(setStep(6)))
        .catch(err => console.log(err))
};




