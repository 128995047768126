import axios from "axios";
import instance from "../../axios/axiosInstance";
import Cookies from "universal-cookie";

const SET_SPECIALIZATIONS_FLAG = 'SET_SPECIALIZATIONS_FLAG'
const SET_SPECIALIZATIONS = 'SET_SPECIALIZATIONS';

let initialState = {
    flag: false,
    specializations:[]

};

const specializationsReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case SET_SPECIALIZATIONS_FLAG: {
            return {
                ...state, flag: action.data
            }
        } 
        case SET_SPECIALIZATIONS: {
            return {
                ...state, specializations: action.specializations
            }
        }
      
        default: {
            return state

        }
    }
}
export default specializationsReducer;

export const isSpecialisations = (data) => ({ type: SET_SPECIALIZATIONS_FLAG, data });
export const setSpecialisations = (specializations) => ({ type: SET_SPECIALIZATIONS, specializations });


export const getAllSpecializations = (curId) => (dispatch, getState) => {

    const curId = getState().curRelationId.currentId;
    
    instance.get(`specialization/list`, 
    {
        pagination: { page: 1, perPage: 999 } 
    })
        .then((res) => {

            res.data.code ?
            console.log(`Error Code:${res.data.code} - ${res.data.message}`) :
            dispatch(setSpecialisations(res.data)) ;
        })
        .catch(err => console.log(err))
}

