import axios from "axios";

const SET_MATERIALS = 'SET_MATERIALS';
const SET_CURRENT_ID = 'SET_CURRENT_ID';

let initialState = {
    data: [],
    currentId:'',
};

const materialsReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case SET_MATERIALS: { 
          
            return {
                ...state, data: action.data
            }
        }
        case SET_CURRENT_ID: {
            return {
                ...state, currentId: action.currentId
            }
        }

        default: {
            return state
        }
    }
}
export default materialsReducer;



export const setMaterials = (data) => ({ type: SET_MATERIALS, data });
export const setCurrentId = (currentId) => ({ type: SET_CURRENT_ID, currentId });

export const getMaterialsData = (id) => (dispatch, getState) => {
    
    axios.get(`http://192.168.71.188:8765/relation/${id}/material/listByRelationId/${id}`, {
                pagination: { page: 1, perPage: 20 },
             })
        .then((res) => {
            dispatch(setMaterials(res.data))
        })
}