import axios from "axios";
import instance from "../../axios/axiosInstance";
import { setRelationMainUserName, setRelationProfile } from "./relationReducer";
import { updateCompanyUser, updateDefaultRelationUser, userLogin } from "./userDataReducer";

const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const TOGLE_DISABLE = 'TOGLE_DISABLE'

let initialState = {

    isDisable: true,
    data: {}

};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE_DATA: {
            return {
                ...state, data: action.data
            }
        }
        case TOGLE_DISABLE: {
            return {
                ...state, isDisable: action.isDisable
            }
        }

        default: {
            return state

        }
    }
}
export default profileReducer;

export const setProfileData = (data) => ({ type: SET_PROFILE_DATA, data });
export const togleDisable = (isDisable) => ({ type: TOGLE_DISABLE, isDisable });

export const getProfileData = (userId) => (dispatch, getState) => {
    instance.get(`user/${userId}`)
        .then((res) => {
            dispatch(setProfileData(res.data))
        })
}

export const updateUser = (f) => (dispatch, getState) => {
    instance.put('user/', f)
}

export const createMyCompany = ( data) => (dispatch, getState) => {
const userId = getState().userData.data.data.user.id

    instance.post(`user/${userId}/company`,
    {
        "city": data.city,
        "street": data.street,
        "company": true,
        "type": "COMPANY",
        "country": data.country,
        "description": data.description,
        "lookingEmployee": false,
        "lookingWork": false,
        "mobilePhone": data.phone,
        "contactEmail": data.email,
        "owner": true,
        "phone": data.phone,
        "specializationsNames": data.specializations?.map(spec => spec.value),
        "companyName": data.companyName,
        "unp": data.unp,
      }
    )
        .then((res) => res.data.code ?
            alert(`Error code: ${res.data.code} ${res.data.message}`): 
            dispatch(userLogin()))
}


export const setLookingWork = (data, lookingWork) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    instance.put(`user/${currentRelationId}`, 
    {...data,
        lookingWork: lookingWork
    }) .then(res=> res.data.code ? 
        alert(`Error code: ${res.data.code} ${res.data.message}`):
        
    dispatch(getProfileData(data.id)))
}

export const updateUserProfile = (data) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    const profile = getState().profile.data;
    
    const success = (res) =>{
        return(
            dispatch(getProfileData(profile.id)),
            dispatch(updateDefaultRelationUser(res.data))
        )}

    instance.put(`user/${currentRelationId}`, 
    {...profile, 
        firstName: `${data.userName.split(' ' )[0]}`,
        lastName: `${data.userName.split(' ' )[1]}`,
        specializationsNames: data.specializations,
        mobilePhone: data.phone,
        contactEmail: data.mail,
        description: data.description,
        country: data.country,
        city: data.city,
        street: data.address,

    }) 
        .then(res=> res.data.code ? 
        alert(`Error code: ${res.data.code} ${res.data.message}`):
        success(res)
    )
};


export const updateCompanyProfile = (data) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    const profile = getState().profile.data;

    const success = (res) =>{
        const userName = res.data.type === "COMPANY" ?
            res.data.companyName :
            `${res.data.firstName} ${res.data.lastName}`;
        return (
            dispatch(getProfileData(profile.id)),
            dispatch(updateCompanyUser(res.data, currentRelationId)),

            dispatch(setRelationProfile(res.data)), // задает обновленные данные mainUser выбранного relation
            dispatch(setRelationMainUserName(userName)) // задает обновленное имя mainUser 
        )
    }

    instance.put(`user/${currentRelationId}`, 
    {...profile, 
        companyName: data.userName,
        unp: data.unp,
        specializationsNames: data.specializations,
        mobilePhone: data.phone,
        contactEmail: data.email,
        city: data.city,
        country: data.country,
        street: data.address,
        description: data.description,

    }) 
        .then(res=> res.data.code ? 
        alert(`Error code: ${res.data.code} ${res.data.message}`):
        success(res)
        )
}