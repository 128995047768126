import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as CreditCardIcon } from '../../images/CreditCardIcon.svg';
import { Redirect } from "react-router-dom";

const styles = theme => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    height: '35px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    marginRight: '14px',
    padding: 0,
    borderRadius: '4px',
    color: '#F1CA00',
    background: '#FFFFFF',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    border: 'none',
    cursor: 'pointer',
    '& span': {
      height: '100%',
      paddingRight: '14px',
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      margin: '0 14px ',
    },
    '&:focus': {
      outline: 'none'
    },
  }
});

function PayButtonChip(props) {
  const { classes, children } = props;

  const [redirect, setRedirect] = useState(false);


  const handleClick = () => {
    setRedirect(true)
  };

  return (
    <button
      className={classes.button}
      onClick={handleClick}
    >
      <span>
        {
          redirect &&
          <Redirect to="/pay-for-the-project" />
        }
        <CreditCardIcon />
        {children}
      </span>
    </button>

  );
}

export default withStyles(styles)(PayButtonChip);
