import React from "react";
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";

const styles = {
  button: {
    height: '36px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '4px',
    color: '#004EE5',
    background: 'transparent',
    border: 'none',
    padding: '10px 19px',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      color: '#0D9CFC',
    },
    '&:disabled': {
      color: '#B3C7F0',
    },
  }
};

function ButtonWithoutBorder(props) {
  const { classes, title, disabled, onClick, style, type, className } = props;

  return (
    <button 
      className={clsx(classes.button, className)}
      disabled={disabled}
      onClick={onClick}
      style={style}
      type={type}
    >
      {title}
    </button>
    
  );
}

export default withStyles(styles)(ButtonWithoutBorder);


// принимает props:  type, style, title, onClick, disabled.