import axios from "axios";
import instance from "../../axios/axiosInstance";
import {
  getEmployeeRelation, 
  findProposalsBetweenTwoUsers
} from './relationReducer'

const SET_USERS_DATA = 'SET_USERS_DATA';
const SET_USERS_LIST = 'SET_USERS_LIST';
const CUR_FILTER_SPEC = 'CUR_FILTER_SPEC';
const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
const SET_RELATION_LIST = 'SET_RELATION_LIST';
const UPDATE_USER_DATA_RELATION_LIST = 'UPDATE_USER_DATA_RELATION_LIST';
const SET_WORKS_GROUP_WORKS = 'SET_WORKS_GROUP_WORKS';
const SET_HISTORY_BY_RELATION = 'SET_HISTORY_BY_RELATION';

let initialState = {
  data: [],
  usersMapData: [],
  specializatons: [],
  currentUserId: '',
  workGroupWorks: [],
  historyByRelation: [],
}

const usersListReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_USERS_DATA: {
      return {
        ...state, data: action.data.data
      }
    }
    case SET_CURRENT_USER_ID: {
      return {
        ...state, currentUserId: action.currentId
      }
    }
    case SET_USERS_LIST: {
      return {
        ...state, usersMapData: action.data.data.map(index => { return index.user })
      }
    }
    case CUR_FILTER_SPEC: {
      return {
        ...state, specializatons: action.data
      }
    }
    case SET_WORKS_GROUP_WORKS: {
      return {
        ...state, workGroupWorks: action.data
      }
    }
    case UPDATE_USER_DATA_RELATION_LIST: {
      return {
        ...state,
        data: state.data.map((item, index) => {
          if (item.user.id !== action.data.user.id) {
            return item
          }
          return {
            ...item,
            relation: action.userRelationList
          }
        })
      }
    }
    case SET_HISTORY_BY_RELATION: {
      return {
        ...state, historyByRelation: action.data
      }
    }

    default: {
      return state
    }
  }
}
export default usersListReducer;


export const setUsersData = (data) => ({ type: SET_USERS_DATA, data });
export const setUsersList = (data) => ({ type: SET_USERS_LIST, data });
export const setFilters = (data) => ({ type: CUR_FILTER_SPEC, data });
export const setCurrentUserId = (data) => ({ type: SET_CURRENT_USER_ID, data });
export const setRelationList = (relList) => ({ type: SET_RELATION_LIST, relList });
export const updateUserRelationList = (userRelationList, data) => ({ type: UPDATE_USER_DATA_RELATION_LIST, userRelationList, data, });
export const setWorkGroupWorks = (data) => ({ type: SET_WORKS_GROUP_WORKS, data });
export const setHistoryByRelation = (data) => ({ type: SET_HISTORY_BY_RELATION, data });

export const getUsers = (name, country, city, specialization, sortCriteria, sortOrder) => (dispatch, getState) => {
  const curRelId = getState().curRelationId.currentId;

  instance.get(`user/${curRelId}/list`, {
    params: {
      page: 0,
      perPage: 100,
      searchType: "EMPLOYEE",
      city: city,
      country: country,
      name: name,
      sortCriteria: sortCriteria,
      sortDirection: sortOrder,
      specialization: specialization,
    }
  })
    .then(result => {
      console.log(result);
      dispatch(setUsersData({ data: result.data }))
    })
    .catch(err => alert(err))
}

export const getUsersWithProposals = (name, country, city, specialization, sortCriteria, sortOrder) => (dispatch, getState) => {
  const curRelId = getState().curRelationId.currentId;

  instance.get(`user/${curRelId}/list`, {
    params: {
      page: 0,
      perPage: 100,
      searchType: "CONTRACTOR",
      city: city,
      country: country,
      name: name,
      sortCriteria: sortCriteria,
      sortDirection: sortOrder,
      specialization: specialization,
    }
  })
    .then(result => {
      dispatch(setUsersData({ data: result.data }))
    })
    .catch(err => alert(err))
}


export const updateRelation = (currentRelationId, data, mainUserStatus, roleId, userRelationStatus) => (dispatch, getState) => {
  const currentChat = getState().chats.selectedChat;
  data.relation ?
  instance.put(`relation/${currentRelationId}`,
    {
      ...data.relation,
      mainUserRelationStatus: mainUserStatus,
      roleId: roleId,
    })
    .then(res => {
      !res.data.code ?
        dispatch(updateUserRelationList(res.data, data)) :
        console.log(res.data.message)
    }) : 
    instance.put(`relation/${currentRelationId}`,
    {
      ...data,
      mainUserRelationStatus: mainUserStatus,
      userRelationStatus: userRelationStatus,
      //roleId: data.roleId,
    })
    .then(res => {
      !res.data.code ?
        dispatch(updateUserRelationList(res.data, data)) :
        console.log(res.data.message)
    })
    .then(res => {
        dispatch(getEmployeeRelation(currentChat))
    })
    
  // .catch(err => console.log(err))
}

export const addSubUser = (currentRelationId, data) => (dispatch, getState) => {

  instance.post(`relation/${currentRelationId}/subUser/${data.user.id}`)
    .then(res => { dispatch(updateUserRelationList(res.data, data)) })
    .catch(err => alert(err))
}



export const getHistoryByRelationId = (currentRelationId, relationId) => (dispatch, getState) => {

  instance.get(`relation/${currentRelationId}/historyListByRelation/${relationId}?order=DESC&sort=dateUpdated`,
  )
    .then(result => {
      console.log(result.data)
      dispatch(setHistoryByRelation({ data: result.data }))
    })
    .catch(err => alert(err))
}