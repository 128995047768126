
const SET_HEADER = 'SET_HEADER';
const SET_SECTION_NAME = 'SET_SECTION_NAME';

let initialState = {
    itemName:'Bilor app',
};

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case SET_HEADER: {
            return {
                ...state, itemName: action.itemName
            }
        }

        case SET_SECTION_NAME: {
            return {
                ...state, sectionName: action.sectionName
            }
        }

        default: {
            return state
        }
    }
}
export default headerReducer;

export const setItemName = (itemName) => ({ type: SET_HEADER, itemName });

export const setSectionName = (sectionName) => ({ type: SET_SECTION_NAME, sectionName });
