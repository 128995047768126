import userAvatar from '../images/landing/userAvatar.png';
import userAvatar2 from '../images/landing/userAvatar2.png';
import userAvatar3 from '../images/landing/userAvatar3.png';
import userAvatar4 from '../images/landing/userAvatar4.png';
import userAvatar5 from '../images/landing/userAvatar5.png';

//constants messages
export const confirmModalMessage = 'Вы ввели информацию в форме. Если вы не сохраните изменения, они буду утеряны. Все равно закрыть форму?';

export const rolesNamesConverter = {
    'administrator': 'Администратор',
    'worker': 'Работник',
    'viewer': 'Наблюдатель',
};

export const rolesConverter = {
    'ANY_INFO_DELETE': 'удаление комментариев',
    'COMPANY_PROFILE_WRITE': 'редактирование профиля компании',
    'ELEMENT_COMMENT_READ': 'просмотр комментариев по элементу',
    'ELEMENT_COMMENT_WRITE': 'добавление комментариев к элементу',
    'ELEMENT_COST_READ': 'просмотр стоимости задач',
    'ELEMENT_DELETE': 'удаление элементов на объекте',
    // 'ELEMENT_WRITE': 'создавать элемент',
    'OBJECT_CREATE': 'создание объекта',
    'OBJECT_DELETE': 'удаление объекта',
    'OBJECT_ORDERS_READ': 'просматривать заказы по объекту',
    'OBJECT_TASK_READ': 'просматривать задачи по объекту',
    'OBJECT_WRITE': 'редактирование информации об объекте',
    'OFFER_DELETE': 'удаление приглашений на объект',
    'OFFER_READ': 'просмотр приглашений работников на объект',
    'OFFER_WRITE': 'приглашение работника на объект',
    // 'ORDER_AND_RELATION_READ': 'просматривать закрепленных к заказу работниклв',
    'ORDER_AND_RELATION_WRITE': 'добавление своего работника на объект',
    // 'ORDER_READ': 'просматривать заказы',
    // 'ORDER_WRITE': 'создавать / редактировать заказы',
    'PAYMENT_CREATE': 'контроль расходов по проекту',
    'PAYMENT_READ': 'просмотр расходов по проекту',
    'PAYMENT_WRITE': 'редактирование расходов по проекту',
    'RATING_READ': 'просматривать отзывы',
    'RATING_WRITE': 'оставлять отзывы',
    // 'REGULATORY_DOCS_READ': 'просматривать фото и документы',
    // 'REGULATORY_DOCS_WRITE': 'создавать / редактировать фото и докуменгты',
    // 'RELATION_HISTORY_READ': 'просматривать историю',
    'RELATION_WRITE': 'найм на работу',
    'ROLE_DELETE': 'удаление ролей',
    'ROLE_WRITE': 'создание / редактирование ролей',
    'SPECIALIZATION_READ': 'просматривать спеицальности',
    // 'SPECIALIZATION_WRITE': 'создавать / редактировать специальности',
    'TASK_ATTACHMENT_READ': 'скачивание документов по задаче',
    'TASK_ATTACHMENT_WRITE': 'редактирование документов по задаче',
    'TASK_COMMENT_READ': 'просмотр комментариев по задаче',
    'TASK_COMMENT_WRITE': 'добавление комментариев по задаче',
    'TASK_DELETE': 'удаление задач',
    'TASK_GROUP_DELETE': 'удаление группы задач',
    'TASK_GROUP_READ': 'просматривать группы задач',
    'TASK_GROUP_WRITE': 'создание / изменение группы задач',
    'TASK_WRITE': 'создание / редактирование задач',
    'TEMPLATE_DELETE': 'удаление шаблонов',
    'TEMPLATE_READ': 'просмотр шаблонов задач',
    'TEMPLATE_WRITE': 'создание/редактирование шаблонов',
    'VACANCY_DELETE': 'удалений вакансий',
    'VACANCY_READ': 'просмотр списка вакансий',
    'VACANCY_WRITE': 'редактирование вакансий',
    // 'GLOBAL_INFO_READ': 'просматривать информацию по всем объектам',
};

export const units = [
    {
        label: 'литры',
        value: 'LITERS',
    },
    {
        label: 'метры',
        value: 'METERS',
    },
    {
        label: 'штуки',
        value: 'ITEMS',
    },
    {
        label: 'килограммы',
        value: 'KILOGRAMS',
    },
    {
        label: 'тонны',
        value: 'TONS',
    },
    {
        label: 'м²',
        value: 'SQUARE_METERS',
    },
    {
        label: 'м³',
        value: 'CUBIC_METERS',
    },
];

export const ownerTooltipMsg = 'Создавайте свои проекты, ищите исполнителей и контролируйте весь процесс стройки';
export const executorTooltipMsg = 'Создавайте свои проекты, ищите работу в компаниях, нанимайте работников к себе в команду';

export const executors = [
    'Найдите работу из предложенных вакансий',
    'Опубликуйте свое предложение',
    'Создавайте свою команду под индивидуальные проекты',
    'Ведите все расчеты прозрачно',
    'Контролируйте все этапы работы',
    'Распределяйте работу в своей команде эффективно',
    'Общайтесь с заказчиком на единой платформе',
    'Получайте доступ со всех устройств',
];

export const developers = [
    'Используйте единую платформу для организации работы всей компании',
    'Контролируйте сотрудников и сроки выполнения работ',
    'Храните документы в одном месте',
    'Храните всю информацию долгие годы',
    'Общайтесь с сотрудниками в одном месте',
    'Распределяйте роли и права доступа в задачах',
    'Общайтесь с заказчиком на единой платформе',
    'Создавайте шаблоны для регулярных задач',
];

export const customers = [
    'Находите специалистов которым можно доверять',
    'Создавайте проект своего ремонта',
    'Контролируйте ход выполнения работ',
    'Отмечайте дедлайны в календаре',
    'Добавляйте фотографии своих проектов',
    'Добавляйте комментарии к задачам',
    'Ведите общение в одном месте',
    'Храните историю вашего ремонта долгие годы',
];

export const bilorCoasts = [
    {
        coast: '$7,99',
        type: 'Квартира',
        value: '/~100м²',
        desc: 'Этот тариф подходит для небольших помещений не более 100м². Вам будет предоставлен объем хранилища достаточный для проекта вашего типа.',
    },
    {
        coast: '$16,99',
        type: 'Офис "S"',
        value: '/~200м²',
        desc: 'Этот тариф подходит для помещений не более 200м². Вам будет предоставлен объем хранилища достаточный для проекта вашего типа.',
    },
    {
        coast: '$24,99',
        type: 'Дом',
        value: '/~300м²',
        desc: 'Этот тариф подходит для помещений не более 300м². Вам будет предоставлен объем хранилища достаточный для проекта вашего типа.',
    },
    {
        coast: '$44,99',
        type: 'Офис "XL"',
        value: '/~500м²',
        desc: 'Этот тариф подходит для больших помещений не более 500м². Вам будет предоставлен объем хранилища достаточный для проекта вашего типа.',
    },
];

export const userFeedbacks = [
    {
        avatar: userAvatar,
        firstName: 'Павел',
        secondName: 'Алейников',
        desc: 'На данный момент лучшая программа с которой мы сталкивались. Максимально простой интерфейс, не возникло никаких проблем с внедрением. Очень радует функция добавления нескольких пользователей и распределения ролей. Документы или расписки больше не теряются потому что сразу все загружаем в Bilor. В общем хочется сказать спасибо за то, что на нашем рынке наконец появился такой продукт!',
    },
    {
        avatar: userAvatar2,
        firstName: 'Игорь',
        secondName: 'Иеркуль',
        desc: 'Хочу поблагодарить команду Bilor. Благодаря вам ремонт не был такой головной болью как обычно. В одном месте нашел строителей, тут же у нас были все коммуникации и проект всего ремонта. С помощью календаря было удобно отслеживать все этапы работ и дедлайны. Спасибо!',
    },
    {
        avatar: userAvatar3,
        firstName: 'Петр',
        secondName: 'Дорожко',
        desc: 'Я, как строитель, выражаю отдельную благодарность за возможность публиковать свое предложение о работе. На Bilore нахожу клиентов тут же с ними общаемся, ведем всю смету и фиксируем все этапы работы. Очень помогают оценки и комментарии, потому что сразу повышает доверие к нам и помогает выбрать лучших.',
    },
    {
        avatar: userAvatar4,
        firstName: 'Иван',
        secondName: 'Мулярчик',
        desc: 'Мой строитель убедил меня сделать ремонт через эту платформу, очень удобно следить за работой и сразу замечать проблемные моменты. Поэтому рекомендую от чистого сердца.',
    },
    {
        avatar: userAvatar5,
        firstName: 'Юлия',
        secondName: 'Березневич',
        desc: 'Легко, просто, удобно! Использовала эту платформу, когда строили дом. Не надо было пользоваться кучей мессенджеров при общении с каждой бригадой строителей, не надо было проводить какие-то расчеты на бумажке и искать фотки по всему телефону. В общем, очень упростил себе жизнь. Еще пользовались приложением “Калькулятор ремонта” от этих же разработчиков, что тоже очень упростило все расчеты. Могу по своему опыту сказать, что оно стоит своих денег.',
    },
];

export const rolesDesc = {
    administrator: 'Выбирайте эту роль, если хотите наделить сотрудника максимальными полномочиями. Сотрудник сможет удалять/создавать/редактировать объекты, элементы, задачи и вакансии, заниматься наймом персонала, получит полный доступ к документации.',
    worker: 'Выбирайте эту роль, если хотите ограничить вашему сотруднику ряд важного функционала. Сотрудник не получит доступа к редактированию профиля компании, удалить объект, элементы, вакансии, не получит возможности нанимать сотрудников на ваш проект.',
    viewer: 'Выбирайте эту роль, если хотите наделить сотрудника минимальным набором прав, достаточным для получения справочной информации о проекте и задачах. Сотрудник сможет просматривать важные функциональные составляющие, но не сможет вносить изменения.'
}

export const tarifId = {
    FLAT: {
        MONTH_SUBSCRIPTION: {
            tarifId: '191735e0-0607-450c-a3f1-4d13c80afca8',
        },
        YEAR_SUBSCRIPTION: {
            tarifId: '370cc6e5-0e43-429c-a1a7-658bffae37a2',
        },
    },
    COMMERCIAL_S: {
        MONTH_SUBSCRIPTION: {
            tarifId: '8b97de1d-f685-4359-acad-3c077c1b810b',
        },
        YEAR_SUBSCRIPTION: {
            tarifId: 'f133765d-fa1d-4714-8050-716eee423fec',
        },
    },
    HOUSE: {
        MONTH_SUBSCRIPTION: {
            tarifId: '35d72043-01af-4ce3-b8bc-cefa4366c8fc',
        },
        YEAR_SUBSCRIPTION: {
            tarifId: '2171fb8c-8663-49c9-a2d8-b0e8c5763f0c',
        },
    },
    COMMERCIAL_XL: {
        MONTH_SUBSCRIPTION: {
            tarifId: 'fa3d7f4f-ba7d-4f6a-9b3b-519d307d2648',
        },
        YEAR_SUBSCRIPTION: {
            tarifId: 'b8d51a29-c986-42ea-bd6d-3212f6e193ff',
        },
    }
}

export const hiringTypes = {
    vacancyHiring: 'VACANCY_HIRING',
    searchHiring: 'SEARCH_EMPLOYEE_HIRING'
};

export const environments = {
    production: 'production',
    development: 'development'
}