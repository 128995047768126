import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { MenuItem } from '@material-ui/core';
import MyBrigadButton from '../buttons/MyBrigadButton';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentId, setCurrentMainUserId, setIsOwner, setRelationMainUserName, setRelationProfile } from '../../redux/reducers/relationReducer';
import clsx from 'clsx';
import Cookies from "universal-cookie";
import { ReactComponent as CompanyWork } from '../../images/CompanyWork.svg';
import { ReactComponent as PersonInTheRoundIcon } from '../../images/PersonInTheRoundIcon.svg';
import { ReactComponent as TriangleIcon } from '../../images/TriangleIcon.svg';
import { ReactComponent as OwnerPersonIcon } from '../../images/OwnerPersonIcon.svg';
import { ReactComponent as OwnerCompanyWork } from '../../images/OwnerCompanyWork.svg';
import AvatarPhoto from '../avatar/AvatarPhoto';
import { setObjectTabIndex } from '../../redux/reducers/objectsReducer';
import AvatarPhotoRelSelectHead from '../avatar/AvatarPhotoRelSelectHead';
import CompanyCreatePopup from '../popups/CompanyCreatePopup';
import { setRefreshedTasksStore } from '../../redux/reducers/tasksReducer';
import { setRefreshedObjectsStore } from '../../redux/reducers/objectsReducer';

const styles = theme => ({
  root: {
    position: 'relative',
    width: '211px',
    height: 42,
    marginRight: 12,
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      width: '76px'
    },
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginRight: 12,
    width: '100%',
    height: '100%',

    '& button': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '19px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      background: '#F5F6F8',
      color: '#8F919B',
      outline: 'none',
      border: 'none',
      padding: 0,
      '&:hover': {
        cursor: 'pointer',
        background: '#FFFFFF',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.14)',
      }
    },
  },
  wrapperActive: {
    '& button': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '19px',
      color: '#FFFFFF',
      padding: 0,
      background: '#0D9CFC',
      '& svg': {
        '& path': {
          fill: '#FFFFFF',
        }
      },
      '&:hover': {
        outline: 'none',
        cursor: 'pointer',
        color: '#0D9CFC',
        background: '#FFFFFF',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.14)',
        '& $explActive':{
          color: '#0D9CFC',
        },
        '& svg': {
          '& path': {
            fill: '#0D9CFC',
          }
        },
      }
    },
  },
  openWraper: {
    position: 'absolute',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    borderRadius: 20,
    background: '#FFFFFF',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.14)',
    '& $triangleIcon': {
      transform: 'rotate(-180deg)',
    },
    [theme.breakpoints.down('md')]: {
      width: '257px',
      left: '-172px',
      padding: '11px 7px',
      boxSizing: 'border-box',
      zIndex: '998',
    },
  },
  expl: {
    fontSize: '11px',
    lineHeight: '13px',
    color: '#A9C1D1',
  },
  explActive: {
    color: '#F5F6F8',
  },
  flexStart: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
  openButtonWrap: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& button': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '19px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 42,
      borderRadius: 20,
      background: '#FFFFFF',
      color: '#8F919B',
      borderRadius: '20px 20px 0 0 ',
      outline: 'none',
      border: 'none',
      padding: 0,
      '&:hover': {
        cursor: 'pointer',
      }
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0px',
      textAlign: 'left',
    },
  },
  list: {
    width: '100%',
    padding: '7px',
    margin: 0,
    '& li': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      padding: '6px 4px',
      display: 'inline-block',
      alignItems: 'center',
      color: '#373A44',
      fontSize: '14px',
      width: '185px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '0px',
        marginBottom: '10px',
      },
      '&:hover': {
        background: '#FFFFFF',
      }
    }
  },
  avatarPhoto: {
    width: '21px',
    height: '21px',
    marginRight: '13px',
    '& path': {
      fill: ' #A9C1D1 ',
    },
  },
  headIcon: {
    width: '30px',
    height: '30px',
    margin: ' 9px 9px 6px 6px',
    '& path': {
      fill: ' #A9C1D1 ',
    },
  },
  triangleIcon: {
    width: '10px',
    height: '5px',
    marginLeft: 'auto',
    marginRight: '19px',
    '& path': {
      fill: '#A9C1D1',
    },
  },
  nameWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  }
});


function RelationSelect(props) {
  const dispatch = useDispatch();

  const { classes, active, setActive } = props;

  const userData = useSelector(state => state.userData.data.data); //mapStateToProps Hook

  const isOwner = useSelector(state => state.curRelationId.isOwner);

  const relationProfile = useSelector(state => state.curRelationId.relationProfile);
  const relationCompanyName = useSelector(state => state.curRelationId.relationCompanyName);

  const [open, setOpen] = useState(false);


  const handleChange = (event, mainUserId, isOwner, curRel) => {
    dispatch(setRefreshedTasksStore());
    dispatch(setRefreshedObjectsStore());
    const cookies = new Cookies();

    dispatch(setCurrentMainUserId(mainUserId));
    dispatch(setCurrentId(event.target.attributes[3].nodeValue));
    cookies.set('currentRelation', event.target.attributes[3].nodeValue, { path: '/' });
    cookies.set('currentMainUserRelation', mainUserId, { path: '/' });
    cookies.set('isUser', 'company',  { path: '/' });
    dispatch(setIsOwner(isOwner));

    dispatch(setRelationProfile(curRel)); 
    dispatch(setRelationMainUserName(event.target.innerText));

    togleOpenSelect();
    setActive('company');
    setObjectTabIndex(0);
  };

  const togleOpenSelect = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    // <ClickAwayListener onClickAway={handleClose}>

    <div className={classes.root}>
      {open ? (
        <div className={classes.openWraper}>
          <div className={classes.openButtonWrap}>
            <button type="button" onClick={togleOpenSelect} >
              {/* <CompanyWork className={classes.headIcon} /> */}
              <AvatarPhotoRelSelectHead
                profile={relationProfile}
                className={classes.headIcon}
              />
              <div>
                <div className={classes.flexStart}>
                  {relationCompanyName}
                </div>
                <div
                  className={clsx(
                    classes.expl,
                    classes.flexStart,
                    active === 'company' && classes.explActive
                  )}
                >
                  Работа в компании
                </div>
              </div>

              <TriangleIcon className={classes.triangleIcon} />
            </button>
          </div>
          <ul className={classes.list}>
            {userData?.relationSet.map((curRel, index) =>
              curRel.mainUserRelationStatus === 'APPROVED' &&
              curRel.userRelationStatus === 'APPROVED' &&
              (curRel.mainUser.type === 'COMPANY' ?
                <MenuItem
                  key={curRel.id}
                  onClick={(e)=>handleChange( e, curRel.mainUser.id, curRel.contractType === "FOUNDER", curRel.mainUser)}
                  value={curRel.id}
                // className={classes.menuItem}
                >
                    <AvatarPhoto
                      profile={curRel.mainUser}
                      className={classes.avatarPhoto}
                      isOwner={curRel.contractType === "FOUNDER"}
                    />
                  
                  {curRel.mainUser.companyName}
                </MenuItem>
                :
                <MenuItem
                  key={curRel.id}
                  onClick={(e)=>handleChange( e, curRel.mainUser.id, curRel.contractType === "FOUNDER", curRel.mainUser)}
                  value={curRel.id}
                // className={classes.menuItem}
                >
                  <AvatarPhoto
                      profile={curRel.mainUser}
                      className={classes.avatarPhoto}
                    />
                  {curRel.mainUser.firstName} {curRel.mainUser.lastName}
                </MenuItem>)
            )}
          </ul>
          {/* <CompanyCreatePopup>
            <MyBrigadButton />
          </CompanyCreatePopup> */}

        </div>
      ) : (
          <div className={
              clsx(classes.wrapper, active === 'company' && classes.wrapperActive)}>
            <button type="button" onClick={togleOpenSelect}>
              <AvatarPhotoRelSelectHead
                profile={relationProfile}
                className={classes.headIcon}
              />
              <div  className={classes.nameWrap}>
                <div className={classes.flexStart}>
                  {relationCompanyName}
                </div>
                <div className={clsx(classes.expl,
                  classes.flexStart,
                  active === 'company' && classes.explActive)}>
                  Работа в компании
                </div>
              </div>
              <TriangleIcon className={classes.triangleIcon} />
            </button>
          </div>
        )}
    </div>

    // </ClickAwayListener>
  )
}

export default (withStyles(styles)(RelationSelect))