import React from 'react'
import { createStyles, withStyles } from '@material-ui/core/styles';
import { ReactComponent as PlusIcon } from '../../images/PlusIcon.svg';

const styles = theme => createStyles({
  div: {
    borderTop: '1px solid #A9C1D1',
    width: '100%',
    padding: '11px 0 14px 10px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      boxSizing: 'border-box',
      margin: '0px',
      padding: '11px 0 3px 10px',
    },
  },
  btn: {
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#0D9CFC',
    background: '#FFFFFF',
    border: 'none',
    borderRadius: 20,
    width: '100%',
    outline: 'none',
    cursor: 'pointer',
    padding: 0,
    // '& :active, & :focus, & :hover': {
    //   outline: 'none',
    // }

  },
  icon: {
    margin: '0 11px 0 1px',

  }
});


const MyBrigadButton = (props) =>{

const { classes } = props;

return (
  <div className={classes.div} onClick={()=>console.log('Собрать свою бригаду')}>
    <button className={classes.btn}>
      <PlusIcon className={classes.icon} />
      Собрать команду
    </button>
  </div>
  )}

export default (withStyles(styles)(MyBrigadButton))