import instance from "../../axios/axiosInstance";
import { getProfileData } from "./profileReducer";
import { getUsers } from './usersListReducer';

const CURRENT_RATING = "CURRENT_RATING";
const SET_RATINGS_LIST = "SET_RATINGS_LIST";
const SET_UPDATED_RATING = "SET_UPDATED_RATING";

let initialState = {
    ratingsList: [],
    refreshableRating: null,
};

const ratingReducer = (state = initialState, action) => {
    switch (action.type) {

        case CURRENT_RATING: {
            return {
                ...state, rating: action.data
            }
        }

        case SET_RATINGS_LIST: {
            return {
                ...state, ratingsList: action.data
            }
        }

        case SET_UPDATED_RATING: {
            return {
                ...state, 
                refreshableRating: action.data.filter(feedback=> feedback.estimatorId === action.mainUserId)[0] || null
            }
        }
        
        default: {
            return state
        }
    }
}
export default ratingReducer;

export const setRating = (data) => ({ type: CURRENT_RATING, data });
export const setRatingsList = (data) => ({ type: SET_RATINGS_LIST, data });
export const setRefreshableRating = (data, mainUserId) => ({ type: SET_UPDATED_RATING, data, mainUserId });

export const addRating = (score, description, orderId, relationId ) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;

    instance.post(`relation/${currentRelationId}/rating`,
        {
            "description": description,
            "orderId": orderId,
            "relationId": relationId,
            "score": score,
        }
    )
    .then(res=>{res.data.code && alert(`${res.data.code}: ${res.data.message}`)})
        .catch(err => alert(err))
};

export const addRatingProfilePopup = (score, description, orderId, relationId, userId ) => (dispatch, getState) => {
    const currentRelationId = getState().curRelationId.currentId;
    return instance.post(`relation/${currentRelationId}/rating`,
        {
            "estimatorId": userId,
            "description": description,
            "orderId": orderId,
            "relationId": relationId,
            "score": score,
        }
    )
    .catch(err => alert(err))
};


export const updateRatingProfilePopup = (updatedFeedback, score, description, userId ) => (dispatch, getState) => {
    
    const currentRelationId = getState().curRelationId.currentId;
    const data = {
        ...updatedFeedback,
        description: description,
        score: score
    }

    return instance.put(`relation/${currentRelationId}/rating`, data )
            .catch(err => alert(err))
}

export const getRatingsByUser = (currentRelationId, userId) => (dispatch, getState) => {
    const mainUserId = getState().curRelationId.mainUserId;
    const currentRelationId = getState().curRelationId.currentId;

    if(currentRelationId){
    instance.get(`relation/${currentRelationId}/rating/listByUserId/${userId}`)
        .then((res) => {
            dispatch(setRatingsList(res.data));
            dispatch(setRefreshableRating(res.data, mainUserId));
        })
        .catch(err => alert(err))
    }
}