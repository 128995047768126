import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { setWorksTabIndex } from '../redux/reducers/workListReducer';
import { withStyles } from '@material-ui/core';

const AntTabs = withStyles({

  indicator: {
    height: 2,
    backgroundColor: '#0D9CFC',
  },
})(Tabs);

const AntTab = withStyles(() => ({
  root: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    //   textTransform: 'none',
    minWidth: 94,
    height: 64,
    color: '#668BA3',
    '&:hover': {
      color: '#0D9CFC',
      opacity: 1,
    },
    '&$selected': {
      color: '#0D9CFC',
    },
    '&:focus': {
      color: '#0D9CFC',
    },
    '& span': {
      paddingLeft: '7px',
      paddingRight: '7px',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const styles = () => ({

});

export default function WorksTabs(props) {
  const classes = styles();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const tabIndex = useSelector(state => state.works.tabIndex);
  let activeCookieTab = cookies.get('activeWorkTab');

  const handleChange = (event, newValue) => {
    cookies.set('activeWorkTab', newValue, { path: '/' });
    activeCookieTab = cookies.get('activeWorkTab');
    if (activeCookieTab) {
      dispatch(setWorksTabIndex(+activeCookieTab)); 
    } else {
      dispatch(setWorksTabIndex(newValue));
    }
  };


  return (
    <AntTabs
      value={activeCookieTab ? +activeCookieTab : tabIndex}
      onChange={handleChange}
      aria-label="works tabs"

    >
      <AntTab label="ОТДЕЛЬНЫЕ ЗАДАЧИ" />
      <AntTab label="ГРУППЫ ЗАДАЧ" />
    </AntTabs>
  );
}





// documentation  https://material-ui.com/ru/components/tabs/#customized-tabs