import instance from "../../axios/axiosInstance";

const SET_ACT_DATA = 'SET_ACT_DATA';
const SET_UPLOAD_FILE_OPEN = 'SET_UPLOAD_FILE_OPEN';
const SET_UPLOAD_FILE_DATA = 'SET_UPLOAD_FILE_DATA';
const CHANGE_FILE_NAME_VALUE = 'CHANGE_FILE_NAME_VALUE';
const TOGLE_IS_FETCHING = 'TOGLE_IS_FETCHING';
const SET_REGULATIONS_BY_WORK_ID = 'SET_REGULATIONS_BY_WORK_ID'

let initialState = {

    data: {},
    open: false,
    file: null,
    fileName: null,
    isFetchingActs: false,
    regulationsByWorkId:[],


};

const actDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACT_DATA: {
            return {
                ...state, data: action.data
            }
        }
        case SET_UPLOAD_FILE_OPEN: {
            return {
                ...state, open: action.data
            }
        }
        case SET_UPLOAD_FILE_DATA: {
            return {
                ...state, file: action.data, fileName: action.data.name
            }
        }
        case CHANGE_FILE_NAME_VALUE: {
            return {
                ...state, fileName: action.data
            }
        }
        case TOGLE_IS_FETCHING: {
            return {
                ...state, isFetchingActs: action.data
            }
        }
        case SET_REGULATIONS_BY_WORK_ID: {
            return {
                ...state, regulationsByWorkId: action.data
            }
        }
        default: {
            return state

        }
    }
}
export default actDataReducer;

export const setActData = (data) => ({ type: SET_ACT_DATA, data });
export const setUploadFileOpen = (data) => ({ type: SET_UPLOAD_FILE_OPEN, data });
export const setUploadFileData = (data) => ({ type: SET_UPLOAD_FILE_DATA, data });
export const changeFileNameValue = (data) => ({ type: CHANGE_FILE_NAME_VALUE, data });
export const togleIsFetching = (data) => ({ type: TOGLE_IS_FETCHING, data });
export const setRegulationsByWorkId = (data) => ({ type: SET_REGULATIONS_BY_WORK_ID, data });



export const getActData = (curId) => (dispatch, getState) => {
    dispatch( togleIsFetching(true) )
    instance.get(`relation/${curId}/regulation/listByRelationId?page=0&perPage=999`, 
   )
        .then((res) => {
            dispatch( togleIsFetching(false) );
            !res.data.code ?
            dispatch(setActData({ data: res.data })) :
            console.log(`Error Code:${res.data.code} - ${res.data.message}`);
            // alert(`Error Code:${res.data.code} - ${res.data.message}`) ;

        })
}

export const uploadRegulation = (curId, formData, regulationName, data) => (dispatch, getState) => {
    dispatch( togleIsFetching(true) )
    instance.post(`relation/${curId}/regulation?regulationName=${regulationName}`,
        formData,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then(res => {
            dispatch( togleIsFetching(false) );

        !res.data.code?
        alert(`File: "${res.data.regulationName}" uploaded`):
        alert(`Error Code:${res.data.code} - ${res.data.message}`) ;
            
        })
        .catch(err => alert(err))
    .then (()=>dispatch(getActData(curId)))
}

export const renameRegulation = (curId, regulationId, fileName ) => (dispatch) => {
    dispatch( togleIsFetching(true) )
    instance.put(`relation/${curId}/regulation/${regulationId}/rename?name=${fileName}`)
    .then(response => {
      if (response.data.resultCode === 0) {
        dispatch( togleIsFetching(false) );
        dispatch(changeFileNameValue(fileName));
      }
    })
    .catch(err => alert(err))
    .then (()=>dispatch(getActData(curId)))
  }

export const deleteRegulation = (curId, regulationId, fileName ) => (dispatch) => {
    dispatch( togleIsFetching(true) )
    instance.delete(`relation/${curId}/regulation/${regulationId}`)
    .then(response => {
        dispatch( togleIsFetching(false) );
        console.log(response)
      })
    .catch(err => alert(err))
    .then (()=>dispatch(getActData(curId)))
  }




  

  export const getRegulationsByWorkId = (curId, workId ) => (dispatch) => {
    instance.get(`relation/${curId}/regulation/findByWorkId/${workId}`)
    .then(res => {
        console.log(res)
        !res.data.code?
        dispatch(setRegulationsByWorkId({ data: res.data })) :
        console.log(`Error Code:${res.data.code} - ${res.data.message}`) ;
      })
    .catch(err => alert(err))
    .then (()=>dispatch(getActData(curId)))
  }
