import { togleIsFetching } from "./preloaderReducer";
import instance from "../../axios/axiosInstance";

const SET_TASK = 'SET_TASK';
const SET_TASK_LIST = 'SET_TASK_LIST';
const SET_CURRENT_TASK = 'SET_CURRENT_TASK';
const SET_TASKGROUPS_LIST = 'SET_TASKGROUPS_LIST';
const SET_ATTACHMENT_TASK_DATA = 'SET_ATTACHMENT_TASK_DATA';
const SET_TASK_TAB_INDEX = 'SET_TASK_TAB_INDEX';
const SET_REFRESHED_TASKS_STORE = 'SET_REFRESHED_TASKS_STORE';
const SET_TASK_ATTACHMENT_URL = 'SET_TASK_ATTACHMENT_URL';
const SET_PREVENTED_REQUEST_TASK_LIST = 'SET_PREVENTED_REQUEST_TASK_LIST';
const SET_TASKS_DELETED_ATTACHMENTS = 'SET_TASKS_DELETED_ATTACHMENTS';
const SET_TASKS_LOADING_ATTACHMENTS = 'SET_TASKS_LOADING_ATTACHMENTS';

let initialState = {
    data: {},
    tasksGroupList: {},
    currentTaskId:'',
    taskTabIndex: 0,
};

const tasksReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_TASK: {
            return {
                ...state, data: action.data
            }
        }

        case SET_TASK_LIST: {
            return {
                ...state, tasksList: action.data
            }
        }
        
        case SET_TASKGROUPS_LIST: {
            return {
                ...state, tasksGroupsList: action.data
            }
        }
        

        case SET_CURRENT_TASK: {
            return state.currentTaskId !== action.data ? {
                ...state, 
                currentTaskId: action.data,
                taskAttachments: []
            } : {...state}
        }

        case SET_ATTACHMENT_TASK_DATA: {
            let existAttachments;
            let attachmentsCopy;
            if (state.taskAttachments?.data) {
                existAttachments = [...state.taskAttachments.data];
                attachmentsCopy = action.data?.data;
                const isSameAttachments = existAttachments.some(existAttachment => {
                    return attachmentsCopy.find(attachment => {
                        return existAttachment.id === attachment.id
                    })
                });
                if (!isSameAttachments) {
                    return {
                        ...state,
                        taskAttachments: action.data,
                    }
                }
                attachmentsCopy.forEach(attachment => {
                    const sameAttachment = existAttachments.find(existAttachment => existAttachment.id === attachment.id);
                    if (sameAttachment && sameAttachment.attachmentSrc) {
                        attachment.attachmentSrc = sameAttachment.attachmentSrc;
                    }
                })
            }
            const equals = state?.taskAttachments?.data?.length - action.data?.data?.length;
            if (!equals || equals <= 0) {
                return {
                    ...state, taskAttachments: action.data
                }
            } else {
                const loadedAttachments = attachmentsCopy.concat(Array(equals).fill({ isAttachmentLoading: true }));
                return {
                    ...state,
                    taskAttachments: {
                        ...state.taskAttachments,
                        data: loadedAttachments,
                    }
                }
            }
        }

        case SET_TASK_TAB_INDEX: {
            return {
                ...state, taskTabIndex: action.index
            }
        }

        case SET_REFRESHED_TASKS_STORE: {
            return initialState
        }

        case SET_TASKS_LOADING_ATTACHMENTS: {
            let attachmentsData = [];
            const attachmentsLoading = [action.data];
            if (state.taskAttachments?.data) {
                attachmentsData = [...state.taskAttachments.data].concat(attachmentsLoading);
            }

            return {
                ...state,
                taskAttachments: {
                    ...state.taskAttachments,
                    data: attachmentsData,
                }
            }
        }

        case SET_TASK_ATTACHMENT_URL: {
            const { data, id } = action;
            const attachmentsCopy = [...state.taskAttachments.data];
            const attachmentPositionToSet = attachmentsCopy.findIndex(attachment => attachment.id === id);
            if (attachmentPositionToSet !== -1) {
                attachmentsCopy[attachmentPositionToSet].attachmentSrc = data;
            }

            return {
                ...state,
                taskAttachments: {
                    ...state.taskAttachments,
                    data: attachmentsCopy,
                },
            }
        }

        case SET_TASKS_DELETED_ATTACHMENTS: {
            const attachmentsCopy = [...state.taskAttachments.data];
            const indexToDelete = attachmentsCopy.findIndex(attachment => attachment.id === action.data);
            if (indexToDelete !== -1) {
                attachmentsCopy.splice(indexToDelete, 1);
            }
            return {
                ...state,
                taskAttachments: {
                    ...state.taskAttachments,
                    data: attachmentsCopy,
                }
            }
        }

        case SET_PREVENTED_REQUEST_TASK_LIST: {
            const attachmentsCopy = [...state.taskAttachments.data];
            console.log(attachmentsCopy);
            console.log(action.index);
            if (attachmentsCopy) {
                attachmentsCopy.splice(action.index, 1);
            }
            return {
                ...state,
                taskAttachments: {
                    ...state.taskAttachments,
                    data: attachmentsCopy,
                }
            }
        }

        default: {
            return state
        }
    }
}
export default tasksReducer;

export const setTaskData = (data) => ({ type: SET_TASK, data });

export const setTaskList = (data) => ({ type: SET_TASK_LIST, data });

export const setTaskGroupsList = (data) => ({ type: SET_TASKGROUPS_LIST, data });

export const setAttachmentTaskData = (data) => ({ type: SET_ATTACHMENT_TASK_DATA, data });

export const setCurrentTask = (data) => ({ type: SET_CURRENT_TASK, data });

export const setTaskTabIndex = (index) => ({ type: SET_TASK_TAB_INDEX, index });

export const setRefreshedTasksStore = (data) => ({ type: SET_REFRESHED_TASKS_STORE, data });

export const setTasksLoadingAttachments = (data) => ({ type: SET_TASKS_LOADING_ATTACHMENTS, data });

export const setTaskAttachmentURL = (data, id) => ({ type: SET_TASK_ATTACHMENT_URL, data, id });

export const setPreventRequestTaskLoading = (index) => ({ type: SET_PREVENTED_REQUEST_TASK_LIST, index });

export const setTasksDeletedAttachments = (data) => ({ type: SET_TASKS_DELETED_ATTACHMENTS, data });

export const getTaskData = (currentOrderAndRelationId, taskId) => (dispatch, getState) => {
    instance.get(`orderAndRelation/${currentOrderAndRelationId}/task/${taskId}`)
        .then((result) => {
            dispatch(setTaskData(result)); 
        })
        .catch(err => console.log(err))
}

export const removeOrderAndRelation = (currentOrderAndRelationId, taskId, orderAndRelationId) => (dispatch, getState) => {
    instance.delete(`orderAndRelation/${currentOrderAndRelationId}/task/${taskId}/removeOrderAndRelation/${orderAndRelationId}`)
        .then(() => {
            dispatch(getTaskData(currentOrderAndRelationId, taskId)); 
        })
        .catch(err => console.log(err))
}

export const changeUsersAmountInTask = (currentOrderAndRelationId, taskId, userIds) => (dispatch, getState) => {
    instance.put(`orderAndRelation/${currentOrderAndRelationId}/task/${taskId}/changeUsers?userIds=${userIds}`)
        .then(() => {
            dispatch(getTaskData(currentOrderAndRelationId, taskId)); 
        })
        .catch(err => console.log(err))
}

export const getTaskList = (currentOrderAndRelationId, elementId) => (dispatch, getState) => {
    instance.get(`orderAndRelation/${currentOrderAndRelationId}/task/listByElementId/${elementId}`, {

    })
        .then((result) => {
            console.log(result);
            dispatch(setTaskList(result)); 
        })
        .catch(err => console.log(err))
}

export const findTaskGroupsByElementId = (currentOrderAndRelationId, elementId) => (dispatch, getState) => {
    instance.get(`orderAndRelation/${currentOrderAndRelationId}/task/group/listByElementId/${elementId}`)
        .then((result) => {
            dispatch(setTaskGroupsList(result)); 
        })
        .catch(err => console.log(err))
}

export const uploadTaskAttachment = (currentOrderAndRelationId, taskId, formData, options) => (dispatch, getState) => {
    dispatch(setTasksLoadingAttachments({ isAttachmentLoading: true }));
    return instance.post(`orderAndRelation/${currentOrderAndRelationId}/task/attachment/uploadByTaskId/${taskId}?attachmentName=image`,
        formData,
        options,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
}

export const deleteTaskAttachment = (currentOrderAndRelationId, taskId, AttachmentId) => (dispatch, getState) => {
    return instance.delete(`orderAndRelation/${currentOrderAndRelationId}/task/attachment/${AttachmentId}`,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .catch(err => alert(err))
    //.then (()=>dispatch(getActData(curId)))
}

export const findAttachmentsByTaskId = (currentOrderAndRelationId, taskId) => (dispatch, getState) => {
    instance.get(`orderAndRelation/${currentOrderAndRelationId}/task/attachment/listByTaskId/${taskId}`)
        .then((result) => {
            dispatch(setAttachmentTaskData(result));
        })
        .catch(err => console.log(err))
}

export const createTask = (currentOrderAndRelationId, data, elementId, orderId, startDateToISO, endDateToISO, coast, quantity, value, description) => (dispatch, getState) => {
    const workersIdsList = [];
    console.log(data?.workersSelect)
    const workersIds = data?.workersSelect?.map((worker) => {
        workersIdsList.push(worker.id)
    })
    instance.post(`orderAndRelation/${currentOrderAndRelationId}/task?orderId=${orderId}&elementId=${elementId}`, {
        "participantsIds": workersIdsList,
        "task": {
            "groupId": data.taskGroup?.id,
            "taskName": data.name,
            "status": "IN_QUEUE",
            "planedStartDate": startDateToISO,
            "planedFinishDate": endDateToISO,
            "measurementUnit": value || null,
            "taskPrice": coast,
            "taskVolume": quantity,
            "taskDescription": description,
        }
    })
        .then (()=> {
            dispatch(getTaskList(currentOrderAndRelationId, elementId))
            dispatch(findTaskGroupsByElementId(currentOrderAndRelationId, elementId))
        })
        .catch(err => { alert(err) })
}

export const updateTask = (currentOrderAndRelationId, data, elementId, changedFields) => (dispatch, getState) => {
    return instance.put(`orderAndRelation/${currentOrderAndRelationId}/task`, { 
            ...data,
            ...changedFields,
            // "id": data.id,
            // "taskName" : data.taskName,
            // "taskType" : data.taskType,
            // "taskDescription" : editField=='description' ? editValue : data.taskDescription,
            // "planedStartDate" : data.planedStartDate,
            // "planedFinishDate" : data.planedFinishDate,
            // "realStartDate" : data.realStartDate,
            // "realFinishDate" : data.realFinishDate,
            // "taskPrice" : editField=='price' ? editValue : data.taskPrice,
            // "measurementUnit" : data.measurementUnit,
            // "taskVolume" : data.taskVolume,
            // "currencyCode" : data.currencyCode,
            // "status" : status,
            // "groupId": data.groupId,
    })
        .then((result)=>{
            dispatch(getTaskData(currentOrderAndRelationId, data.id))
            dispatch(getTaskList(currentOrderAndRelationId, elementId))
            dispatch(findTaskGroupsByElementId(currentOrderAndRelationId, elementId))
        })
        .catch(err => { alert(err) })
}



export const createTaskGroup = (
    currentOrderAndRelationId, 
    elementId, 
    orderId, 
    groupName, 
    taskIds,
    isRecursive
    ) => (dispatch, getState) => {
    instance.post(`orderAndRelation/${currentOrderAndRelationId}/task/group?taskIds=${taskIds}`, {
        "elementId": elementId,
        "groupName": groupName,
        "orderId": orderId,
    })
        .then (()=> {
            dispatch(getTaskList(currentOrderAndRelationId, elementId))
            dispatch(findTaskGroupsByElementId(currentOrderAndRelationId, elementId))
        })
        .catch(err => { alert(err) })
}

export const createTaskGroupByTemplate = (
    currentOrderAndRelationId, 
    elementId, 
    orderId, 
    workGroupId, 
    worksIds,
    isRecursive
) => (dispatch, getState) => {
    instance.post(`orderAndRelation/${currentOrderAndRelationId}/element/${elementId}/order/${orderId}/task/group/createFromTemplate?workGroupId=${workGroupId}&isRecursive=${isRecursive}&worksIds=${worksIds}`)
        .then (()=> {
            dispatch(getTaskList(currentOrderAndRelationId, elementId))
            dispatch(findTaskGroupsByElementId(currentOrderAndRelationId, elementId))
        })
        .catch(err => { alert(err) })
}

export const deleteTask = (currentRelationId, id, elementId) => (dispatch, getState) => {
    instance.delete(`orderAndRelation/${currentRelationId}/task/${id}`)
        .then(()=>dispatch(getTaskList(currentRelationId, elementId)))
        .catch(err => { alert(err) })
}

export const deleteTaskGroup = (currentOrderAndRelationId, groupId, elementId) => (dispatch, getState) => {
    instance.delete(`orderAndRelation/${currentOrderAndRelationId}/task/group/${groupId}`)
        .then (() => dispatch(getTaskList(currentOrderAndRelationId, elementId)))
        .catch(err => { alert(err) })
}